import gql from 'graphql-tag';
import * as fragments from '../fragments';

export default gql`
  query UnitDetails($unitId: ID!) {
    unitDetails(unitId: $unitId) {
      ...UnitDetails
    }
  }
  ${fragments.unitDetails}
`;