import gql from "graphql-tag";
import { address, contactDetails } from ".";

export default gql`
  fragment UserDetails on UserDetails {
    id
    community
    firstName
    lastName
    contactDetails {
      ...ContactDetails
    }
    address {
      ...Address
    }
    unitsOwned {
      id
      name
      occupancyType
    }
    roles
    isActive
    loginEnabled
  }
  ${contactDetails}
  ${address}
`;
