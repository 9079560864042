import { ApolloClient } from "@apollo/client";
import { startCase, camelCase, isEmpty, filter, some, find } from "lodash";
import { tenantUnit } from "./data/queries";
import jwt from "jsonwebtoken";

import {
  Address,
  Unit,
  UnitDetails,
  UnitsDocument,
  UnitsQuery,
  UnitsQueryVariables,
  User,
  UsersDocument,
  UsersQuery,
  UsersQueryVariables,
} from "./data/__generated__/client-graphql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { navigate } from "@reach/router";
import { IUserContext } from "./UserContext";
var dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};


export const signoutAndClearContext = async (userContext: IUserContext, client: ApolloClient<object>,
  setReload: React.Dispatch<React.SetStateAction<boolean>>, reload: boolean) => {
  window.localStorage.removeItem("token");
  userContext.currentUser = undefined;
  userContext.currentCommunity = undefined;
  userContext.currentCommunityId = undefined;
  await client.clearStore();
  await navigate("/");
  setReload(!reload);
}

export const getTenantUnit = (
  client: ApolloClient<object>,
  communityId: string,
  tenantId: string
): Unit | undefined | null => {
  const result = client.readQuery<Unit>({
    query: tenantUnit,
    variables: {
      communityId,
      userId: tenantId,
    },
  });
  return result;
};

export const isTenantOccupancy = (unitDetails: UnitDetails) =>
  unitDetails.occupancyType === "TENANT";

export const getFormattedName = (
  firstName?: string | null,
  lastName?: string | null
) => {
  return !!lastName
    ? startCase(camelCase(`${firstName} ${lastName}`))
    : startCase(camelCase(`${firstName}`));
};

export const getFormattedDate = (date: Date) => {
  return date.toLocaleDateString("en-US", dateOptions);
};

export const getFormattedAddress = (address?: Address) => {
  return !!address
    ? address.addressLine2
      ? `${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.state} - ${address.zipCode}`
      : `${address.addressLine1}, ${address.city}, ${address.state} - ${address.zipCode}`
    : "";
};

export const isValidEmail = (email?: string): boolean => {
  if (isEmpty(email)) return false;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidZipCode = (zipCode?: string): boolean => {
  if (isEmpty(zipCode)) return false;
  const re = /^\d{5}(?:[-\s]\d{4})?$/;
  return re.test(String(zipCode).toLowerCase());
};

/**
 * Array of all the state in United States
 */
export const allStates = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

/**
 * Array of all the state in United States
 */
export const allTaskLabels = [
  "Routine Maintenance",
  "Repair",
  "Complaints",
  "Safety",
  "Security",
  "Driveway",
  "Roof",
  "Gutters",
  "Lawn",
  "Exterior",
  "Interior",
  "Termites",
  "Pest and Rodents",
  "Pool",
  "High",
  "Medium",
  "Low",
  "Urgent",
];

export const getUnits = (
  client: ApolloClient<object>,
  currentCommunityId: string | undefined
) => {
  const unitsData = client.readQuery<UnitsQuery, UnitsQueryVariables>({
    query: UnitsDocument,
    variables: {
      communityId: currentCommunityId ?? "",
    },
  });
  const units: Array<Unit> = unitsData?.communityUnits as Array<Unit>;
  return units;
};

export const getUsers = (
  client: ApolloClient<object>,
  currentCommunityId: string | undefined
) => {
  const usersData = client.readQuery<UsersQuery, UsersQueryVariables>({
    query: UsersDocument,
    variables: {
      communityId: currentCommunityId ?? "",
    },
  });
  const users: Array<User> = usersData?.communityUsers as Array<User>;
  return users;
};

export const getUser = (
  client: ApolloClient<object>,
  currentCommunityId: string | undefined,
  userId: string
) => {
  const users: Array<User> = getUsers(client, currentCommunityId);
  return find(users, { id: userId });
};

export const getUnitNamesForUnitIds = (unitIds: string[], units: Unit[]) => {
  return filter(units, (unit) =>
    some(unitIds, (unitId) => unitId === unit.id)
  ).map((unit) => unit.name);
};

export const getUnitIdsForUnitNames = (unitNames: string[], units: Unit[]) => {
  return filter(units, (unit) =>
    some(unitNames, (unitName) => unitName === unit.name)
  ).map((unit) => unit.id);
};

export const getUserFromToken = (accessToken: string) => {
  var decoded = jwt.decode(accessToken) as any;
  const {
    firstName,
    lastName,
    loginId, //use this
    roles,
    id,
    community,
  } = decoded;

  return {
    firstName,
    lastName,
    id,
    community,
    roles,
  } as User;
};

export const isValidTaskStatus = (status: Maybe<string> | null) => {
  return status && (status === "open" || status === "closed");
};
