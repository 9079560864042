import { useApolloClient } from "@apollo/client";
import { Link, RouteComponentProps } from "@reach/router";
import React from "react";
import {
  Community,
  useCommunitiesQuery,
} from "../data/__generated__/client-graphql-types";
import * as queries from "../data/queries";
import { useUserContext } from "../UserContext";

// cache.writeQuery({
//   data: {
//     isLoggedIn: !!localStorage.getItem('token')
//   },
// });
const CommunitiesContainer = (props: RouteComponentProps) => {
  const client = useApolloClient();
  const { data, loading, error } = useCommunitiesQuery();
  const userContext = useUserContext();

  const onSelect = (selectedCommunity: Community) => {
    client.writeQuery({
      query: queries.currentCommunity,
      data: { currentCommunity: selectedCommunity },
    });
    userContext.currentCommunityId = selectedCommunity.id;
  };

  // return a table of links to Community page
  // upon click write to cache the community id
  return (
    <>
      <h2
        style={{
          width: 500,
        }}
      >
        Select a community
      </h2>
      <div
        style={{
          fontSize: 20,
          backgroundColor: "lightgrey",
          borderRadius: 5,
          padding: 20,
          height: 150,
          borderBottom: "3px solid black",
        }}
      >
        <ol>
          {data &&
            data.communities &&
            data.communities.map((community, index) => {
              return (
                <li key={`${community?.id}`}>
                  <Link
                    to="/units"
                    onClick={() => onSelect(community as Community)}
                    style={{}}
                  >
                    {community?.name}
                  </Link>
                  <br />
                </li>
              );
            })}{" "}
        </ol>
      </div>
    </>
  );
};

export const Communities = CommunitiesContainer;
