import {
  ComponentSlotStylesPrepared,
  TeamsThemeStylesProps,
} from "@fluentui/react-northstar";

const focusActiveStyles = {
  // background: FOCUS_BACKGROUND_COLOR,
  // color: HOVER_COLOR,
  // boxShadow: "none",
  // selectors: {
  //   ".icons-default-fill": {
  //     fill: HOVER_COLOR
  //   },
  //   ".ms-Persona-primaryText": {
  //     color: HOVER_COLOR
  //   },
  //   ".ms-Persona-secondaryText": {
  //     color: HOVER_COLOR
  //   },
  //   ".ms-Button-label": {
  //     color: HOVER_COLOR
  //   }
};
export const TextStyles: ComponentSlotStylesPrepared<
  NonNullable<TeamsThemeStylesProps["Text"]>,
  any
> = {
  root: ({
    props: {},
    variables: { isTaskTitleInTaskPreview, isUnitName },
    theme: {
      siteVariables: { colors, borderWidth },
    },
  }) => ({
    ...(isTaskTitleInTaskPreview && {
      padding: 8,
      textDecoration: "none",

      selectors: {
        ":hover": {
          textDecoration: "underline",
        },
      },
    }),
  }),
};
