import gql from "graphql-tag";
import * as fragments from "../fragments";

export default gql`
  query taskComments($taskId: ID!) {
    taskComments(taskId: $taskId) {
      ...TaskComment
    }
  }
  ${fragments.taskComment}
`;
