import gql from "graphql-tag";
import * as fragments from "../fragments";

export default gql`
  query Tasks($communityId: ID!) {
    communityTasks(communityId: $communityId) {
      ...Task
    }
  }
  ${fragments.task}
`;
