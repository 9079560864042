import gql from "graphql-tag";
import { address } from ".";

export default gql`
  fragment UnitDetails on UnitDetails {
    id
    name
    community
    address {
      ...Address
    }
    owners {
      ...UserBasicDetails
    }
    tenants {
      ...UserBasicDetails
    }
    occupancyType
    isActive
  }
  ${address}
`;
