import gql from "graphql-tag";
import { address, contactDetails } from ".";

export default gql`
  fragment UserBasicDetails on UserBasicDetails {
    id
    firstName
    lastName
    contactDetails {
      ...ContactDetails
    }
    address {
      ...Address
    }
    boardMemberDetails{
      title
    }
    loginEnabled
  }
  ${contactDetails}
  ${address}
`;
