import { RouteComponentProps } from "@reach/router";
import React from "react";
import { PageBanner } from "./components/PageBanner";
import { TextArea, Flex, Button, Text, QnaIcon } from "@fluentui/react-northstar";
import { useSubmitFeedbackMutation } from "./data/__generated__/client-graphql-types";
import { useUserContext } from "./UserContext";

export const Feedback = (props: RouteComponentProps) => {
  const [
    submitFeedback,
    { loading: mutationLoading },
  ] = useSubmitFeedbackMutation();
  const { currentUser } = useUserContext();
  const [showThankYouMessage, setShowThankYouMessage] = React.useState(false);
  const [notes, setNotes] = React.useState<string>();
  const [
    actionButtonGroupVisibility,
    setActionButtonGroupVisibility,
  ] = React.useState<boolean>(false);

  const onInputChange = React.useCallback(
    (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setNotes(input.value);
      if (notes !== input.value) {
        setActionButtonGroupVisibility(true);
      } else {
        setActionButtonGroupVisibility(false);
      }
    },
    [setNotes, setActionButtonGroupVisibility]
  );
  const onSubmit = React.useCallback(async () => {
    await submitFeedback({
      variables: {
        input: {
          notes: notes ?? "",
          reportedUser: currentUser?.id ?? "",
        },
      },
    });
    setActionButtonGroupVisibility(false);
    setShowThankYouMessage(true);
  }, [notes, setShowThankYouMessage]);

  const onClear = React.useCallback(() => {
    setNotes("");
  }, [notes]);

  return (
    <>
      <PageBanner title={"Feedback"}
        icon={<QnaIcon size="large" style={{ padding: 10 }} />} />
      <br />
      <br />
      <br />
      {!showThankYouMessage && (
        <Flex vAlign="center" column style={{ width: "80%", marginLeft: "5%" }}>
          <TextArea
            placeholder="Enter feedback here..."
            fluid
            resize="vertical"
            style={{
              height: 150,
              marginBottom: 20,
            }}
            value={notes}
            onChange={onInputChange}
          />
          <Flex hAlign="start" style={{ marginLeft: 10 }}>
            <ActionButtons
              onSubmit={onSubmit}
              onClear={onClear}
              isSubmitting={mutationLoading}
            />
          </Flex>
        </Flex>
      )}
      {showThankYouMessage && (
        <Text
          content="Thank you for submitting your feedback!"
          temporary
          size="large"
        />
      )}
    </>
  );
};

const ActionButtons = ({
  onSubmit,
  isSubmitting,
  onClear,
}: {
  onSubmit: () => void;
  onClear: () => void;
  isSubmitting: boolean;
}) => (
  <Button.Group
    buttons={[
      {
        key: "submit",
        primary: true,
        title: isSubmitting ? "Submitting" : "Submit",
        content: isSubmitting ? "Submitting" : "Submit",
        disabled: isSubmitting,
        onClick: onSubmit,
      },
      {
        key: "clear",
        title: "Clear",
        content: "Clear",
        onClick: onClear,
      },
    ]}
  />
);
