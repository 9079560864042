import gql from "graphql-tag";
import * as fragments from "../fragments";

export default gql`
  query CurrentCommunity {
    currentCommunity @client {
        ...Community
    }
  }
  ${fragments.community}
`;
