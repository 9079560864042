import { Flex, Header, Image, Button } from "@fluentui/react-northstar";
import React from "react";

import SomethingWentWrongImage from "./images/illustration-something-went-wrong.svg";
export interface IApplicationErrorProps {
  retryHandler?: () => void;
  errorMessage?: string;
}
export const ApplicationError: React.FunctionComponent<IApplicationErrorProps> = React.memo<IApplicationErrorProps>(
  ({ retryHandler, errorMessage }) => {
    const imageProps = {
      src: SomethingWentWrongImage,
      id: "illustration",
    };
    return (
      <Flex
        column
        fill
        hAlign="center"
        vAlign="center"
        style={{
          width: "24.5rem",
          maxHeight: "24.5rem",
          marginBottom: "3.2rem",
        }}
      >
        <Image {...imageProps} />
        <Header
          as="h2"
          content={errorMessage ? errorMessage : "Oops! Something went wrong"}
          variables={{
            isLargeBoldHeader: true,
            isCentered: true,
            isToolbarPopupH2: true,
          }}
        />
        {retryHandler && (
          <Button primary content={"Retry"} onClick={retryHandler} />
        )}
      </Flex>
    );
  }
);
