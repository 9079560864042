import { useApolloClient } from "@apollo/client";
import { Form, Button, FormInput, FormButton, FormField, FormDropdown, Flex, FlexItem } from "@fluentui/react-northstar";
import { FormikErrors, FormikTouched } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import {
  allTaskLabels,
  getUnits,
} from "../app-helper";
import { useUserContext } from "../UserContext";
import { IFormTask } from "./modals-helper";

interface INewTaskFormProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  touched: FormikTouched<IFormTask>;
  errors: FormikErrors<IFormTask>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };

  setTouched: (touched: FormikTouched<IFormTask>) => void;
  setFieldValue: (
    field: keyof IFormTask,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;

  values: IFormTask;
  cancelDialog: () => void;
}

export const NewTaskForm = ({
  handleSubmit,
  handleChange,
  errors,
  handleBlur,
  touched,
  values,
  cancelDialog,
  setTouched,
  setFieldValue,
}: INewTaskFormProps) => {
  const client = useApolloClient();
  const { currentCommunityId } = useUserContext();
  const unitNames = React.useMemo(() => {
    return getUnits(client, currentCommunityId).map((unit) => unit.name);
  }, []);
  return (
    <Form onSubmit={handleSubmit as any}>
      <FormInput
        errorMessage={touched.title && errors.title}
        label="Title"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(
          values.title && values.title?.length >= 5
        )}
        name="title"
        id="title"
        required
        fluid
      />

      <FormDropdown
        multiple
        onBlur={(e) => {
          setTouched({ ...touched, labels: true });
        }}
        onChange={(e, props) => {
          setFieldValue("forUnits", props.value);
        }}
        label="For Units"
        id="forUnits"
        fluid
        errorMessage={touched.forUnits && errors.forUnits}
        items={unitNames}
      />
      <FormInput
        errorMessage={touched.description && errors.description}
        label="Description"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(
          values.description && values.description?.length >= 1
        )}
        name="description"
        id="description"
        fluid
      />

      <FormDropdown
        multiple
        onBlur={(e) => {
          setTouched({ ...touched, labels: true });
        }}
        onChange={(e, props) => {
          setFieldValue("labels", props.value);
        }}
        label="Labels"
        id="labels"
        fluid
        errorMessage={touched.labels && errors.labels}
        items={allTaskLabels}
      />

      <Flex gap="gap.small">
        <FlexItem push>
          <FormButton content="Submit" disabled={!isEmpty(errors)} />
        </FlexItem>
        <FormField
          control={{
            as: Button,
            content: "Cancel",
            onClick: () => cancelDialog(),
          }}
        />
      </Flex>
    </Form>
  );
};
