import { RouteComponentProps } from "@reach/router";
import React from "react";

export default (props: { children: React.ReactElement }) => (
  <main
    style={{
      paddingRight: 10,
      flex: 1,
      // marginTop: 60,
      marginLeft: 210,
      overflow: "auto",
      position: "relative",
      // height: "calc(100vh - 60px)",
      height: "100vh",
      width: "calc(100vw - 210px)",
      boxSizing: "border-box",
    }}
  >
    {props.children}
  </main>
);
