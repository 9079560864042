import React from "react";
import { useRemoveAllTenantsMutation } from "../data/__generated__/client-graphql-types";
import { CloseIcon, Dialog } from "@fluentui/react-northstar";
import { units as unitsQuery, users as usersQuery } from "../data/queries";

interface IRemoveAllTenantsConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  unitId: string;
  communityId: string;
}

export const RemoveAllTenantsConfirmDialog = ({
  open,
  onClose,
  unitId,
  communityId,
}: IRemoveAllTenantsConfirmDialogProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const [removeAllTenants] = useRemoveAllTenantsMutation();
  const onCancel = React.useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const onConfirm = React.useCallback(async () => {
    // TODO: use loading and error
    // disable users who don't occupy any units or tenants anymore
    await removeAllTenants({
      variables: {
        unitId,
      },
      refetchQueries: [
        {
          query: unitsQuery,
          variables: {
            communityId,
          },
        },
        {
          query: usersQuery,
          variables: {
            communityId,
          },
        },
      ],
    });
    setIsOpen(false);
    onClose();
  }, [setIsOpen, removeAllTenants]);
  return (
    <Dialog
      closeOnOutsideClick={false}
      cancelButton="Cancel"
      confirmButton="Confirm"
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={isOpen}
      header="Would you like to remove all the tenants?"
    />
  );
};
