import {
  ComponentSlotStylesPrepared,
  TeamsThemeStylesProps,
} from "@fluentui/react-northstar";

export const FlexStyles: ComponentSlotStylesPrepared<
  NonNullable<TeamsThemeStylesProps["Flex"]>,
  any
> = {
  root: ({
    props: {},
    variables: { isEntityDetailsField, isTaskPreviewCardRenderer },
    theme: {
      siteVariables: { colors, borderWidth },
    },
  }) => ({
    ...(isEntityDetailsField && {
      margin: 10,
      // ":active": {
      //   color: "black !important",
      // },
      // ":hover": {
      //   color: "black !important",
      // },
    }),

    ...(isTaskPreviewCardRenderer && {
      flexShrink: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
    }),
  }),
};
