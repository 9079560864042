import { mergeThemes, Provider, teamsTheme } from "@fluentui/react-northstar";
// @ts-ignore
import * as React from "react";
import themeOverrides from "./theme";

export const FluentUIStyleProvider: React.FunctionComponent = (props) => {
  const { children } = props;

  return (
    <Provider theme={mergeThemes(teamsTheme, themeOverrides)}>
      {/* TODO: Load it in more React way */}
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/prismjs@1.16.0/themes/prism-tomorrow.min.css"
      />
      {children}
    </Provider>
  );
};
