import gql from "graphql-tag";
import * as fragments from "../fragments";

export default gql`
  query taskDetailsByCommunityTaskId($communityId: ID!, $communityTaskId: Int!) {
    taskDetailsByCommunityTaskId(
      communityId: $communityId
      communityTaskId: $communityTaskId
    ) {
      ...Task
    }
  }
  ${fragments.task}
`;
