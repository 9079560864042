export function is<T>(left: T, right: T): boolean;
export function is(left: number, right: number): boolean;
export function is<T>(left: T | number, right: T | number): boolean {
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
  if (left === right) {
    // If `left === right`, then differentiate `-0` and `0` via division.
    return left !== 0 || 1 / left === 1 / <number>right;
  } else {
    // If `left !== right`, then return false unless both `left` and `right` are `NaN`.
    // `NaN` can be detected via `self !== self`.
    return left !== left && right !== right;
  }
}

/**
 * Checks equality of 2 objs by iterating through their immmediate keys.
 * The contents of each object for each key are compared under strict equality mode.
 * @param source The src object
 * @param target The tar object
 * @returns {boolean} Returns true if the objects are equal
 */
export const shallowComparator = <TItem extends object>(
  source: TItem | undefined | null,
  target: TItem | undefined | null
): boolean => {
  // Check if the objects are equal, if so then return
  if (is(source, target)) {
    return true;
  }

  // Given that the `is` comparision has failed, if either of the
  // inputs are null/undefined we have to assume they are different
  if (source == null || target == null) {
    return false;
  }

  // If the items are arrays then shallowCompare them with a different algo
  if (Array.isArray(source) && Array.isArray(target)) {
    return areEqualArrays(source, target);
  }

  if (source instanceof Set && target instanceof Set) {
    return areEquivalentSets(source, target);
  }

  // Assume they are objects and shallowCompare them
  return areEqualObjects(source, target);
};

const areEquivalentSets = <T>(source: Set<T>, target: Set<T>) => {
  if (source.size !== target.size) {
    return false;
  }

  if (source.size === 0) {
    return true;
  }

  const valuesIter = source.values();
  let sourceVal = valuesIter.next();
  do {
    if (!target.has(sourceVal.value)) {
      return false;
    }
    sourceVal = valuesIter.next();
  } while (!sourceVal.done);

  return true;
};

/**
 * Checks if 2 arrays are same
 * @param source src array
 * @param target tar array
 */
const areEqualArrays = <TArray extends object[]>(
  source: TArray,
  target: TArray
): boolean => {
  if (source.length !== target.length) {
    return false;
  }

  const length = source.length;
  for (let key = length - 1; key >= 0; key--) {
    if (!is(source[key], target[key])) {
      return false;
    }
  }

  return true;
};

/**
 * Compares two objects and returns true if they are same
 * @param source source object
 * @param target target object
 */
const areEqualObjects = <TObject extends object>(
  source: TObject,
  target: TObject
): boolean => {
  const sourceKeys = Object.keys(source) as (keyof TObject)[];
  const targetKeys = Object.keys(target) as (keyof TObject)[];
  if (sourceKeys.length !== targetKeys.length) {
    return false;
  }

  for (const key of sourceKeys) {
    if (!is(source[key], target[key])) {
      return false;
    }
  }

  return true;
};
