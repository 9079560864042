import gql from "graphql-tag";
import * as fragments from "../fragments";

export default gql`
  query TenantUnit($communityId: String!, $userId: String!) {
    tenantUnit(communityId: $communityId, userId: $userId) @client {
      ...Unit
    }
  }
  ${fragments.unit}
`;
