import React from "react";
import { Link, LinkProps } from "@reach/router";

// TODO: Drive Link prop types
export default (props: any) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        style: {
          color: isCurrent ? "black !important" : "white",
          textDecoration: "none"
        },
      };
    }}
  />
);
