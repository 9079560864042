import { Form, FormInput, FormField, FormButton, FormDropdown, Button, Flex, FlexItem } from "@fluentui/react-northstar";
import { FormikErrors, FormikTouched } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { allStates, isValidEmail, isValidZipCode } from "../app-helper";
import { IFormUnit } from "./modals-helper";

interface INewUnitFormProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  touched: FormikTouched<IFormUnit>;
  errors: FormikErrors<IFormUnit>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };

  setTouched: (touched: FormikTouched<IFormUnit>) => void;
  setFieldValue: (
    field: keyof IFormUnit,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;

  values: IFormUnit;
  cancelDialog: () => void;
}

export const NewUnitForm = ({
  handleSubmit,
  handleChange,
  errors,
  handleBlur,
  touched,
  values,
  cancelDialog,
  setTouched,
  setFieldValue,
}: INewUnitFormProps) => {
  return (
    <Form onSubmit={handleSubmit as any}>
      <FormInput
        errorMessage={touched.name && errors.name}
        label="Unit Name"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(values.name && values.name?.length >= 3)}
        name="name"
        id="name"
        required
        fluid
      />
      <FormInput
        errorMessage={touched.addressLine1 && errors.addressLine1}
        label="Address Line 1"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(
          values.addressLine1 && values.addressLine1?.length >= 3
        )}
        name="addressLine1"
        id="addressLine1"
        required
        fluid
      />

      <FormInput
        errorMessage={touched.addressLine2 && errors.addressLine2}
        label="Address Line 2"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(
          values.addressLine2 && values.addressLine2?.length >= 3
        )}
        name="addressLine2"
        id="addressLine2"
        required
        fluid
      />

      <FormInput
        errorMessage={touched.city && errors.city}
        label="City"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(values.city && values.city.length >= 3)}
        name="city"
        id="city"
        required
        fluid
      />

      <FormDropdown
        onBlur={(e) => {
          setTouched({ ...touched, state: true });
        }}
        onChange={(e, props) => {
          setFieldValue("state", props.value);
        }}
        label="State *"
        id="state"
        fluid
        errorMessage={touched.state && errors.state}
        items={allStates}
      />

      <FormInput
        errorMessage={touched.zipCode && errors.zipCode}
        label="Zip Code"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={isValidZipCode(values.zipCode)}
        name="zipCode"
        id="zipCode"
        required
        placeholder="98008 or 98008-1234"
        fluid
      />

      <Flex gap="gap.small">
        <FlexItem push>
          <FormButton content="Submit" disabled={!isEmpty(errors)} />
        </FlexItem>
        <FormField
          control={{
            as: Button,
            content: "Cancel",
            onClick: () => cancelDialog(),
          }}
        />
      </Flex>
    </Form>
  );
};
