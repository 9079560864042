
import { ApolloClient, useApolloClient } from "@apollo/client";
import { isEmpty } from "lodash";
import React from "react";
import { useMemoizedValue } from "./common-utilities/use-memoized-value";
import { Community, User } from "./data/__generated__/client-graphql-types";
import { getUserFromToken } from "./app-helper";

const getCurrentUser = (client: ApolloClient<object>): User | undefined => {
  if (!window.localStorage.token) return undefined;

  // client.query({
  //   query:
  // })
  //TODO: use the token to query for the user
  return getUserFromToken(window.localStorage.token ?? "");
};

const getCurrentCommunity = (
  client: ApolloClient<object>
): string | undefined => {
  if (!window.localStorage.token) return undefined;

  // client.query({
  //   query:
  // })
  //TODO: use the token to query for the user
  return getUserFromToken(window.localStorage.token ?? "").community;
};
export interface IUserContext {
  currentCommunityId?: string;
  currentCommunity?: Community;
  currentUser?: User;
  appSettings: IAppSettings;
}

/**
 * This interface should capture all the app settings.
 * TODO: these app settings should come to the backend, instead of coming from env file
 */
export interface IAppSettings {
  showCommunityName: boolean;
}
const UserContext = React.createContext<IUserContext>(undefined as any);
UserContext.displayName = "UserContext";

export interface IUserContextProviderProps {
  userId?: string;
  children?: React.ReactNode;
}

export const UserContextProvider: React.FunctionComponent<IUserContextProviderProps> = ({
  userId,
  children,
}) => {
  const client = useApolloClient();
  //TODO: these app settings should come to the backend, instead of coming from env file
  let showCommunityName =
    !isEmpty(process.env.REACT_APP_SHOW_COMMUNITY_NAME) &&
    process.env.REACT_APP_SHOW_COMMUNITY_NAME?.toLowerCase() === "true"
      ? true
      : false;
  const defaultContextWithoutAppAndClientState: IUserContext = {
    currentCommunityId: getCurrentCommunity(client),
    appSettings: {
      showCommunityName,
    },
    currentCommunity: undefined,
    currentUser: getCurrentUser(client),
  };
  const userContextValue = useMemoizedValue(
    defaultContextWithoutAppAndClientState
  );
  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): IUserContext =>
  React.useContext<IUserContext>(UserContext);
