import { Maybe } from "graphql/jsutils/Maybe";
import {
  camelCase,
  filter,
  includes,
  isEmpty,
  orderBy,
  some,
  startCase,
} from "lodash";
import React from "react";
import { Row, Column, usePagination, useTable } from "react-table";

import {
  Box,
  Flex,
  Header,
  Label,
  Loader,
  Table,
} from "@fluentui/react-northstar";

import { getFormattedName } from "../app-helper";
import {
  Unit,
  User,
  useUnitsQuery,
  useUsersQuery,
} from "../data/__generated__/client-graphql-types";
import { TablePaginationRenderer } from "../components/TablePaginationRenderer";
import { Tabs } from "../components/Tabs";
import { useUserContext } from "../UserContext";

const tableHooks = [usePagination];
const pageSizeOptions = [5, 10, 25];
export const SelectableUsersContainer = ({
  onUserSelect,
  noShowUsersList,
  activeTabIndex,
  onTabSwitch,
}: {
  onUserSelect: (selectedOwnerRow: User) => void;
  noShowUsersList: User[];
  activeTabIndex: number;
  onTabSwitch: (tabIndex: number) => void;
}) => {
  const { currentCommunityId: communityId = "" } = useUserContext();
  const { data: units, loading: unitsLoading } = useUnitsQuery({
    variables: {
      communityId,
    },
  });
  const { data: users, loading: usersLoading } = useUsersQuery({
    variables: {
      communityId,
    },
  });

  return usersLoading || unitsLoading ? (
    <Loader />
  ) : communityId ? (
    <SelectableUsersListRenderer
      units={units?.communityUnits as Unit[]}
      users={users?.communityUsers as User[]}
      onSelect={onUserSelect}
      noShowUsersList={noShowUsersList}
      activeTabIndex={activeTabIndex}
      onTabSwitch={onTabSwitch}
    />
  ) : null;
};

const SelectableUsersListRenderer = ({
  units,
  users,
  noShowUsersList,
  onSelect,
  activeTabIndex,
  onTabSwitch,
}: {
  units: Unit[];
  users: User[];
  noShowUsersList: User[];
  onSelect: (selectedOwnerRow: User) => void;
  activeTabIndex: number;
  onTabSwitch: (tabIndex: number) => void;
}) => {
  const allUsersTableData = React.useMemo(() => {
    return {
      columns: [
        {
          Header: "Name",
          id: "name",
          accessor: (user: User) =>
            getFormattedName(user?.firstName, user?.lastName),
        },
      ],
      users: filter(
        users,
        ({ id: userId }) => !some(noShowUsersList, ({ id }) => userId === id)
      ),
    };
  }, []);

  const ownersTableData = React.useMemo(() => {
    return {
      columns: [
        {
          Header: "Name",
          id: "name",
          accessor: (user: User) =>
            getFormattedName(user?.firstName, user?.lastName),
        },
        {
          Header: "Units Owners",
          id: "units",
          accessor: (user: User) => {
            const units: Maybe<Unit>[] = [];
            //units.push(...user.unitsOwned);
            return units;
          },
          Cell: ({ cell: { value } }: any) => (
            <Box>
              {value?.map((unit: Unit) => (
                <Label content={startCase(camelCase(unit.name))} />
              ))}
            </Box>
          ),
        },
      ],
      users: filter(
        users,
        ({ roles, id: userId }) =>
          includes(roles, "OWNER") &&
          !some(noShowUsersList, ({ id }) => userId === id)
      ),
    };
  }, []);

  let { columns, users: selectableUsers } =
    activeTabIndex === 0 ? allUsersTableData : ownersTableData;

  if (!units) return null;
  return isEmpty(users) ? (
    <Header
      as="small"
      content="Currently there are no users in the community!"
    />
  ) : (
    <Flex column>
      <Tabs
        tabTitles={["All", "Owners"]}
        onTabSwitch={onTabSwitch}
        defaultActiveIndex={1}
        pointing={false}
      />
      <FluentReactSelectableUsersList
        columns={columns}
        users={selectableUsers}
        onSelect={onSelect}
      />
    </Flex>
  );
};

const FluentReactSelectableUsersList = ({
  columns,
  users,
  onSelect,
}: {
  columns: Column<User>[]; // TODO: try to avoid this!!
  users: User[];
  onSelect: (selectedOwnerRow: User) => void;
}) => {
  const tableOptions = React.useMemo(
    () => ({
      columns: columns,
      data: orderBy(users, "firstName"),
      initialState: { pageIndex: 0, pageSize: pageSizeOptions[0] },
    }),
    [columns]
  );
  const tableInstance = useTable(tableOptions, ...tableHooks);

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  const getRowCells = (
    row: Row<User>,
    onCellClick: (selectedOwnerRow: User) => void
  ) => {
    return row.cells.map((cell, index) => {
      let content = cell.render("Cell");
      return {
        key: index,
        content,
        onClick: () => onCellClick(row.original),
      };
    });
  };
  const headers = {
    items: headerGroups[0].headers.map((column) => (
      <Header as="h3">{column.render("Header")}</Header>
    )),
  };

  const tableRows = page.map((row, i) => {
    prepareRow(row);
    return {
      key: i,
      items: getRowCells(row, onSelect),
    };
  });

  // Render the UI for your table
  return (
    <Flex column>
      <Table header={headers} rows={tableRows} aria-label="Users table" />
      <TablePaginationRenderer<User>
        {...{
          page,
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          pageSizeOptions,
        }}
      />
    </Flex>
  );
};

// ts-ignore
FluentReactSelectableUsersList.whyDidYouRender = true;
