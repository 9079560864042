import gql from 'graphql-tag';
import * as fragments from '../fragments';

export default gql`
  query userDetails($userId: ID!) {
    userDetails(userId: $userId) {
      ...UserDetails
    }
  }
  ${fragments.userDetails}
`;