import gql from "graphql-tag";

export default gql`
fragment UserLoginDetails on UserLoginDetails {
    id
    userId
    recoveryEmail
    setup {
      verificationCode
      expiresAt
      notes
    } 
}
`;