import {
  Button,
  Flex,
  Label,
  TagIcon,
  TenantPersonalIcon,
  Text,
  Tooltip,
  ExclamationCircleIcon,
  ApprovalsAppbarIcon,
  CloseIcon,
} from "@fluentui/react-northstar";
import { navigate } from "@reach/router";
import { isEmpty } from "lodash";
import React from "react";
import { getUnitNamesForUnitIds } from "../app-helper";
import {
  Maybe,
  Task,
  Unit,
  User,
} from "../data/__generated__/client-graphql-types";
import { TaskActivityDetails } from "./TaskActivityDetails";
import { TaskLabels } from "./TaskLabels";

export const taskPreviewCardRendererVariables = {
  isTaskPreviewCardRenderer: true,
};

export const OpenStatusIcon = React.memo(({ color }: { color?: any }) => {
  return (
    <Tooltip
      content="Open Task"
      trigger={
        <ExclamationCircleIcon
          outline
          size="medium"
          styles={{
            color: color ?? "green",
          }}
        />
      }
    />
  );
});

export const ClosedStatusIcon = React.memo(({ color }: { color?: any }) => {
  return (
    <Tooltip
      content="Closed Task"
      trigger={
        <ApprovalsAppbarIcon
          outline
          size="medium"
          styles={{
            color: color ?? "green",
          }}
        />
      }
    />
  );
});

const RemoveTaskIcon = React.memo(() => {
  return (
    <Tooltip
      content="Delete Task"
      trigger={
        <CloseIcon
          size="medium"
          styles={{
            color: "#F1684D",
          }}
        />
      }
    />
  );
});

export const TaskPreviewCardRenderer = ({
  task,
  units,
  user,
}: {
  task: Maybe<Task>;
  units: Unit[];
  user: User;
}) => {
  const unitNames =
    task && !isEmpty(task.forUnits)
      ? getUnitNamesForUnitIds(task?.forUnits as string[], units)
      : [];
  if (!task) return null;
  const variables = { isUnitName: true };

  return (
    <Flex variables={taskPreviewCardRendererVariables} column>
      <Button
        variables={{ isFullRowCell: true }}
        icon={
          task.status === "open" ? (
            <OpenStatusIcon color="#D64B33" />
          ) : (
            <ClosedStatusIcon />
          )
        }
        content={
          <Text
            color="brand"
            content={task.title}
            size="medium"
            weight="semilight"
            variables={{ isTaskTitleInTaskPreview: true }}
          />
        }
        onClick={async () => await navigate("/tasks/" + task.communityTaskId)}
        text
        style={{
          cursor: "pointer",
          justifyContent: "left",
        }}
      />
      <TaskActivityDetails task={task} user={user} />
      <Flex
        style={{
          alignItems: "right",
          justifyContent: "right",
          marginLeft: 20,
          marginTop: 8,
        }}
      >
        {unitNames &&
          unitNames.map((unitName) => (
            <Button
              as="a"
              iconOnly
              content={
                <Label
                  content={
                    <Text
                      content={unitName}
                      size="smallest"
                      style={{ fontSize: 10 }}
                    />
                  }
                  variables={variables}
                  icon={<TenantPersonalIcon style={{ height: 10 }} />}
                  iconPosition="start"
                  style={{
                    padding: 6,
                  }}
                />
              }
              size="smallest"
              onClick={async () =>
                await navigate("/units/" + task.forUnits?.[0])
              }
              text
              style={{
                cursor: "pointer",
                paddingBottom: 10,
                paddingRight: 6,
              }}
            />
          ))}
        <TaskLabels
          content={task.labels}
          icon={
            <TagIcon
              outline
              rotate={-110}
              size="smallest"
              style={{ height: 10 }}
            />
          }
        />
      </Flex>
    </Flex>
  );
};
