import { useApolloClient } from "@apollo/client";
import { Box, Button, Label, EmailIcon, Flex, Header, InfoIcon, MenuButton, MoreIcon, SpeakerPersonIcon, Table, Text, Tooltip, Portal, EditIcon, Checkbox, Divider, Loader, BanIcon, CloseIcon, AcceptIcon, PollIcon, RedbangIcon, SyncIcon, ApprovalsAppbarIcon } from "@fluentui/react-northstar";
import { navigate, RouteComponentProps } from "@reach/router";
import { camelCase, filter, includes, isEmpty, isEqual, keyBy, pull, pullAllBy, startCase } from "lodash";
import React from "react";
import { Column, Row, useTable } from "react-table";
import { getFormattedName } from "../app-helper";
import { FlexItemStyleProp } from "../app.interface";
import {
    Maybe,
    Unit,
    useCommunityUsersLoginDetailsQuery,
    useDisableLoginMutation,
    useEnableLoginMutation,
    User,
    useResetLoginSetupMutation,
    UserLoginDetails,
    useUnitsQuery,
    useUpdateUserMutation,
    useUsersQuery
} from "../data/__generated__/client-graphql-types";
import { useUserContext } from "../UserContext";
import { Email } from "./Email";
import { PageBanner } from "./PageBanner";
import { users as usersQuery } from "../data/queries";
import communityUsersLoginDetailsQuery from "../data/queries/communityUsersLoginDetails.query";


enum LoginAccountStatusEnum {
    NotSetup,
    Enabled,
    Disabled,
    SetupInProgress,
    SetupExpired
}

export const Admin = (props: RouteComponentProps) => {
    const { currentCommunityId: communityId = "" } = useUserContext();

    const {
        data: units,
        loading: unitsLoading,
        error: unitsError,
    } = useUnitsQuery({
        variables: {
            communityId: communityId,
        },
    });
    const {
        data: users,
        loading: usersLoading,
        error: usersError,
    } = useUsersQuery({
        variables: {
            communityId: communityId,
        },
    });

    const {
        data: usersLoginDetails,
        loading: usersLoginDetailsLoading,
        error: usersLoginDetailsError,
    } = useCommunityUsersLoginDetailsQuery({
        variables: {
            communityId: communityId,
        },
    });
    return <>
        <PageBanner
            title="Admin"
            icon={<SpeakerPersonIcon size="large" style={{ padding: 10 }} />}
        />
        {!isEmpty(communityId) ? (
            <AdminTableRenderer
                units={units?.communityUnits as Unit[]} // TODO: Remove forecasting if possible
                users={filter(users?.communityUsers as User[], (user: User) => {
                    if (!user.isActive) return false;
                    return !user.roles.includes("TENANT")
                })}
                usersLoginDetails={usersLoginDetails?.communityUsersLoginDetails as UserLoginDetails[]}
            />
        ) : null}
    </>
}


export const AdminTableRenderer = ({
    units,
    users,
    usersLoginDetails
}: {
    units: Unit[];
    users: User[];
    usersLoginDetails: UserLoginDetails[]
}) => {
    const [updateUserMutation] = useUpdateUserMutation();
    const usersLoginDetailsDirectoryByUserId = keyBy(usersLoginDetails, "userId")
    const { currentCommunityId = "" } = useUserContext();
    //TODO: scope to optimize. Write to cache instead of always computing this userIds map
    const client = useApolloClient();
    const columns = React.useMemo(
        (): Column<User>[] => [
            {
                Header: "Name",
                id: "name",
                accessor: (user: User, rowIndex) =>
                    getFormattedName(user?.firstName, user?.lastName),
                Cell: ({ cell }) => (
                    <Button
                        primary
                        fluid
                        onClick={async (event: any) => {
                            //TODO: optimiize
                            event.stopPropagation();
                            await navigate(`/residents/${cell.row.original.id}`);
                        }}
                    >
                        {startCase(camelCase(cell.value))}
                    </Button>
                ),
            },
            {
                Header: "Email",
                id: "email",
                Cell: ({ cell }) => (
                    <Flex gap="gap.small">
                        <EmailIcon outline />
                        <Email address={cell.row.original.contactDetails.email} isLinked={true} />
                    </Flex>
                ),
            },
            {
                Header: "Units",
                id: "units",
                accessor: (user: User, rowIndex) => {
                    const units: Maybe<Unit>[] = [];
                    const { roles, unitsOwned } = user;
                    if (includes(roles, "OWNER")) {
                        //units.push(...user.unitsOwned);
                    }
                    return units;
                },
                Cell: ({ cell: { value } }: any) => (
                    <Box>
                        {value?.map((unit: Unit) => (
                            <Button
                                size="small"
                                content={startCase(camelCase(unit.name))}
                            ></Button>
                        ))}
                    </Box>
                ),
            },
            {
                Header: "Login Account Status",
                Cell: ({ cell }: any) => {
                    const loginDetails = usersLoginDetailsDirectoryByUserId[cell.row.original.id];
                    const status = getLoginAccountStatus(cell.row.original, loginDetails);
                    return loginDetails?.setup?.notes ? <Tooltip trigger={<Label icon={getAccountStatusIcon(status)} iconPosition="start" content={status} />} content={loginDetails.setup.notes} /> : <Label icon={getAccountStatusIcon(status)} iconPosition="start" content={status} />
                }
            },
            {
                Header: "Details",
                Cell: ({ cell }: any) => {
                    const loginDetails = usersLoginDetailsDirectoryByUserId[cell.row.original.id];
                    const loginAccountStatus = getLoginAccountStatus(cell.row.original, loginDetails)
                    return <Tooltip trigger={<InfoIcon outline />} content={<>
                        {loginDetails?.recoveryEmail && <Text content={`Recovery Email:${loginDetails.recoveryEmail}`} />}
                        {loginAccountStatus === "SetupExpired" && <><Text content={`EXPIRED SETUP`} /><br /></>}
                        {loginDetails?.setup?.verificationCode && <Text content={`1. Setup Code: ${loginDetails.setup.verificationCode}`} />}
                        <br />
                        {loginDetails?.setup?.expiresAt && <Text content={`2. Expires At: ${new Date(loginDetails.setup.expiresAt).toString()}`} />}
                        {/* TODO: MAke Date Time more user Friendly or create a util for project date and date time */}
                    </>} />
                },
            },
            {
                Header: "Actions",
                Cell: ({ cell }) => {
                    const loginDetails = usersLoginDetailsDirectoryByUserId[cell.row.original.id];
                    return <ActionMenu user={cell.row.original} loginAccountStatus={getLoginAccountStatus(cell.row.original, loginDetails)} />
                }
            },
            {
                Header: "Roles",
                accessor: "roles",
                Cell: ({ cell }) => {
                    const user = cell.row.original;
                    const [updatedRoles, setUpdatedRoles] = React.useState(user.roles)
                    const onInputChange = React.useCallback(
                        (_, data) => {
                            // if(input.value)
                            // setUpdatedRoles(input.value);
                            console.log("VAMSHI DEBUG", data)
                            const role = data["data-id"];
                            const isChecked = data.checked;
                            let temp = [...updatedRoles];
                            pull(temp, role)
                            if (isChecked) {
                                temp.push(role);
                            }
                            setUpdatedRoles(temp);
                            console.log("New Roles", temp)
                        },
                        [setUpdatedRoles, updatedRoles]
                    );
                    const [open, setOpen] = React.useState<boolean>(false);
                    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
                    return <Flex>
                        {user?.roles?.includes("OWNER") && <Portal
                            open={open}
                            onOutsideClick={() => setOpen(false)}
                            trigger={
                                <EditIcon outline style={{ paddingRight: 7, paddingTop: 5 }} size="small" onClick={() => setOpen(true)} />
                            }>
                            <Box
                                style={{
                                    position: 'fixed',
                                    left: '40%',
                                    top: '30%',
                                    zIndex: 1000,
                                    backgroundColor: '#fff',
                                    padding: '15px',
                                    boxShadow: 'rgb(187, 187, 187) 0px 2px 8px',
                                    border: '1px solid rgba(34,36,38,.15)',
                                }}
                            >
                                <Header as="h3">{getFormattedName(user.firstName)}'s Roles</Header>
                                <Flex column>
                                    <Tooltip trigger={<Checkbox label={"Owner"} checked={user.roles?.includes("OWNER")} />} content={"You can update ownership role only from Units view."} />
                                    <Checkbox label="Board Member" data-id={"BOARD_MEMBER"} defaultChecked={user.roles?.includes("BOARD_MEMBER")} onChange={onInputChange} />
                                    <br />
                                    <Flex>
                                        <Button primary disabled={showSpinner} content={showSpinner ? <Loader /> : "Ok"} onClick={async () => {
                                            let newRoles = [...updatedRoles];
                                            let oldRoles = [...user.roles];

                                            if (!isEqual(oldRoles.sort(), newRoles.sort())) {
                                                console.log("roled have changed.old and new", user.roles, newRoles)
                                                setShowSpinner(true);
                                                await updateUserMutation({
                                                    variables: {
                                                        input: {
                                                            id: user.id,
                                                            roles: newRoles
                                                        }
                                                    },
                                                    refetchQueries: [
                                                        {
                                                            query: usersQuery,
                                                            variables: {
                                                                communityId: currentCommunityId,
                                                            },
                                                        },
                                                    ],
                                                });
                                                setShowSpinner(false);
                                            }

                                            setOpen(false);

                                        }} />

                                        <Button content="Cancel" onClick={() => setOpen(false)} disabled={showSpinner} />
                                    </Flex>
                                </Flex>
                            </Box>
                        </Portal>}
                        {user.roles?.map((role: any) => (
                            <Label content={startCase(camelCase(role ?? ""))} style={{ marginRight: 3 }} />
                        ))}
                    </Flex >
                },
            }
        ],
        [usersLoginDetails]
    );
    if (isEmpty(units) || isEmpty(users)) {
        return null;
    }
    return (
        <>
            <FluentReactAdminTable columns={columns} users={users} />
        </>
    );
};


const FluentReactAdminTable = ({
    columns,
    users,
}: {
    columns: Column<User>[]; // TODO: try to avoid this!!
    users: User[];
}) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable<User>({
        columns: columns,
        data: users,
    });

    const getRowCells = (row: Row<User>) => {
        return row.cells.map((cell, index) => {
            let content = cell.render("Cell");
            return {
                key: index,
                content,
            };
        });
    };
    const headers = {
        items: headerGroups[0].headers.map((column) => (
            <Header as="h3">{column.render("Header")}</Header>
        )),
    };

    const tableRows = rows.map((row, i) => {
        prepareRow(row);

        return {
            key: i,
            items: getRowCells(row),
        };
    });


    // Render the UI for your table
    return <Table header={headers} rows={tableRows} aria-label="Users table" />;
};


interface IActionMenuProps extends FlexItemStyleProp {
    user?: User;
    loginAccountStatus?: string
}

const ActionMenu: React.FC<IActionMenuProps> = ({
    user,
    loginAccountStatus,
    ...rest
}: IActionMenuProps) => {
    const [enableLogin] = useEnableLoginMutation()
    const [disableLogin] = useDisableLoginMutation()
    const [resetLoginSetup] = useResetLoginSetupMutation()

    const { currentCommunityId = "" } = useUserContext();

    const onEnable = React.useCallback(async () => {
        const isConfirm = window.confirm(`Would you like to enable login for ${getFormattedName(user?.firstName, user?.lastName)}?`);
        if (!isConfirm) {
            return;
        }

        await enableLogin({
            variables: {
                userId: user?.id ?? "",
                elevatedAccessCode: "abc" //TODO: better manage elevatedAccessCode
            },
            refetchQueries: [
                {
                    query: usersQuery,
                    variables: {
                        communityId: currentCommunityId,
                    },
                },
                {
                    query: communityUsersLoginDetailsQuery,
                    variables: {
                        communityId: currentCommunityId,
                    }
                },
            ],
        });

    }, [enableLogin, user?.id, currentCommunityId]);


    const onDisable = React.useCallback(async () => {
        const isConfirm = window.confirm(`Would you like to disable login for ${getFormattedName(user?.firstName, user?.lastName)}?`);

        if (!isConfirm) {
            return;
        }
        const notifyUser = !!window.confirm(`Would you like to send account disable notification to ${getFormattedName(user?.firstName, user?.lastName)}?`);

        await disableLogin({
            variables: {
                userId: user?.id ?? "",
                elevatedAccessCode: "abc", //TODO: better manage elevatedAccessCode
                notifyUser
            },
            refetchQueries: [
                {
                    query: usersQuery,
                    variables: {
                        communityId: currentCommunityId,
                    },
                },
                {
                    query: communityUsersLoginDetailsQuery,
                    variables: {
                        communityId: currentCommunityId,
                    }
                },
            ],
        });

    }, [disableLogin, user?.id, currentCommunityId]);


    const onResetLoginSetup = React.useCallback(async () => {
        const isConfirm = window.confirm(`Would you like to reset login setup for ${getFormattedName(user?.firstName, user?.lastName)}?`);
        if (!isConfirm) {
            return;
        }

        await resetLoginSetup({
            variables: {
                userId: user?.id ?? "",
                elevatedAccessCode: "abc" //TODO: better manage elevatedAccessCode
            },
            refetchQueries: [
                {
                    query: usersQuery,
                    variables: {
                        communityId: currentCommunityId,
                    },
                },
                {
                    query: communityUsersLoginDetailsQuery,
                    variables: {
                        communityId: currentCommunityId,
                    }
                },
            ],
        });

    }, [resetLoginSetup, user?.id, currentCommunityId]);



    let menu: any = [
        {
            key: "enable",
            content: (
                <Button
                    text
                    size="small"
                    content="Enable"
                    icon={<AcceptIcon />}
                    onClick={onEnable}
                />
            ),
        },
        {
            key: "reenable",
            content: (
                <Button
                    text
                    size="small"
                    content="Re-Enable"
                    icon={<ApprovalsAppbarIcon />}
                    onClick={onEnable}
                />
            ),
        },
        {
            key: "resetPassword",
            content: (
                <Button
                    text
                    size="small"
                    content="Reset Password"
                    icon={<SyncIcon />}
                    onClick={() => { window.alert("Sorry, currently reset password is not supported") }} //TODO: support reset user login setup

                />
            ),
        },
        {
            key: "disable",
            content: (
                <Button
                    text
                    size="small"
                    content="Disable"
                    icon={<CloseIcon />}
                    onClick={onDisable}
                />
            ),
        },
        {
            key: "resetLoginSetup",
            content: (
                <Button
                    text
                    size="small"
                    content="Reset Login Setup"
                    icon={<SyncIcon />}
                    onClick={onResetLoginSetup}
                />
            ),
        },

    ];

    switch (loginAccountStatus) {
        case LoginAccountStatusEnum[LoginAccountStatusEnum.NotSetup]:
            pullAllBy(menu, [{ key: "disable" }, { key: "resetPassword" }, { key: "reenable" }, { key: "resetLoginSetup" }], "key");

            break;
        case LoginAccountStatusEnum[LoginAccountStatusEnum.Enabled]:
            pullAllBy(menu, [{ key: "enable" }, { key: "reenable" }, { key: "resetLoginSetup" }], "key");

            break;
        case LoginAccountStatusEnum[LoginAccountStatusEnum.Disabled]:
            pullAllBy(menu, [{ key: "enable" }, { key: "disable" }, { key: "resetPassword" }, { key: "resetLoginSetup" }], "key");

            break;
        case LoginAccountStatusEnum[LoginAccountStatusEnum.SetupInProgress]:
            pullAllBy(menu, [{ key: "enable" }, { key: "reenable" }, { key: "resetLoginSetup" }], "key");
            break;
        case LoginAccountStatusEnum[LoginAccountStatusEnum.SetupExpired]:
            pullAllBy(menu, [{ key: "enable" }, { key: "reenable" }, { key: "resetPassword" }], "key");
            break;
        default:
            menu = [];
            break;
    }

    return (
        <MenuButton
            {...rest}
            trigger={<Button icon={<MoreIcon />} text />}
            menu={menu}
            style={{ padding: 10 }}
        />
    );
};

const isSetupExpired = (setupExpiryDate: string) => {
    return Date.parse(setupExpiryDate) < Date.now();
}

const getLoginAccountStatus = (user: User, loginDetails?: UserLoginDetails): string => {
    if (user.loginEnabled === undefined || user.loginEnabled === null || (!user.loginEnabled && !loginDetails)) {
        return LoginAccountStatusEnum[LoginAccountStatusEnum.NotSetup];
    }
    if (!user.loginEnabled) {
        return LoginAccountStatusEnum[LoginAccountStatusEnum.Disabled];
    }
    if (user.loginEnabled === true && loginDetails?.setup && isSetupExpired(loginDetails?.setup.expiresAt ?? "")) {
        return LoginAccountStatusEnum[LoginAccountStatusEnum.SetupExpired];
    }

    if (user.loginEnabled === true && loginDetails?.setup && !isSetupExpired(loginDetails?.setup.expiresAt ?? "")) {
        return LoginAccountStatusEnum[LoginAccountStatusEnum.SetupInProgress];
    }
    return LoginAccountStatusEnum[LoginAccountStatusEnum.Enabled];
}

const getAccountStatusIcon = (loginAccountStatus: string): any => {
    switch (loginAccountStatus) {
        case LoginAccountStatusEnum[LoginAccountStatusEnum.NotSetup]:
            return <CloseIcon />

        case LoginAccountStatusEnum[LoginAccountStatusEnum.Enabled]:
            return <AcceptIcon />

        case LoginAccountStatusEnum[LoginAccountStatusEnum.Disabled]:
            return <BanIcon />

        case LoginAccountStatusEnum[LoginAccountStatusEnum.SetupInProgress]:
            return <SyncIcon />

        case LoginAccountStatusEnum[LoginAccountStatusEnum.SetupExpired]:
            return <RedbangIcon />
        default:
            return null;
    }
}