import gql from "graphql-tag";
import { address, contactDetails } from ".";

export default gql`
  fragment UnitBasicDetails on UnitBasicDetails {
    id
    name
    occupancyType
  }
`;
