import {
  navigate,
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from "@reach/router";
import {
  Header,
  List,
  Button,
  TenantPersonalIcon,
  Text,
  Box,
  CalendarIcon,
  AudienceIcon,
  TeamCreateIcon,
  SpeakerPersonIcon,
  ToDoListIcon,
  Image,
  Divider,
  Flex,
  GeofenceLeavesIcon,
  QnaIcon,
} from "@fluentui/react-northstar";
import React from "react";
import { pullAllBy, some } from "lodash";
import NavLink from "./NavLink";
import { IUserContext, useUserContext } from "../UserContext";
import { ApolloClient, useApolloClient } from "@apollo/client";
import { signoutAndClearContext } from "../app-helper";
const noSideBarPaths: Array<string> = ["/", "/communities"];
const noSideBarPrefixPaths: Array<string> = ["/login/setup"];

const navLinkStyle = {
  marginBottom: 10,
  fontSize: "1.3rem",
  float: "left"
};
// const buttonStyle = {
//   color:"white",
// };
export const Sidebar = ({
  communityName,
  userFirstName,
  setReload,
  reload,
}: RouteComponentProps & {
  communityName: string;
  userFirstName: string;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
}) => {
  const [showSidebar, setShowSidebar] = React.useState<boolean>(false);
  const location = useLocation();
  const userContext = useUserContext();
  const client = useApolloClient();
  // const {
  //   appSettings: { showCommunityName },
  //   currentCommunity: { name: communityName } = { name: "" },
  //   currentUser,
  // } = userContext;
  const shouldShowSidebar = (location: WindowLocation) => {
    let isNoSideBarPath = some(
      noSideBarPaths,
      (item: string) => item === location.pathname
    );

    if (isNoSideBarPath) return false;

    // check if the path has a noSideBarPathPrefix
    isNoSideBarPath = some(noSideBarPrefixPaths, (item: string) =>
      location.pathname.toLowerCase().startsWith(item)
    );

    return !isNoSideBarPath;
  };

  React.useEffect(() => {
    setShowSidebar(shouldShowSidebar(location));
  }, [location]);

  const navLinkListItems = [
    {
      key: "units",
      content: (
        <NavLinkListItem
          displayText="Units"
          path="units"
          icon={<TenantPersonalIcon size="small" />}
        />
      ),
      style: navLinkStyle,
    },
    {
      key: "residents",
      content: (
        <NavLinkListItem
          displayText="Residents"
          path="residents"
          icon={<AudienceIcon size="small" />}
        />
      ),
      style: navLinkStyle,
    },
    {
      key: "tasks",
      content: (
        <NavLinkListItem
          displayText="Tasks"
          path="tasks"
          icon={<ToDoListIcon size="small" />}
        />
      ),
      style: navLinkStyle,
    },
    // {
    //   key: "vendors",
    //   content: (
    //     <NavLinkListItem
    //       displayText="Vendors"
    //       path="vendors"
    //       icon={<AudienceIcon size="small" />}
    //     />
    //   ),
    //   style: navLinkStyle,
    // },
    {
      key: "board",
      content: (
        <NavLinkListItem
          displayText="Board"
          path="board"
          icon={<TeamCreateIcon size="small" />}
        />
      ),
      style: navLinkStyle,
    },
    // {
    //   key: "meetings",
    //   content: (
    //     <NavLinkListItem
    //       displayText="Meetings"
    //       path="meetings"
    //       icon={<CalendarIcon size="small" />}
    //     />
    //   ),
    //   style: navLinkStyle,
    // },
    {
      key: "admin",
      content: (
        <NavLinkListItem
          displayText="Admin"
          path="admin"
          icon={<SpeakerPersonIcon size="small" />}
        />
      ),
      style: navLinkStyle,
    },
    {
      key: "feedback",
      content: (
        <NavLinkListItem
          displayText="Feedback"
          path="feedback"
          icon={<QnaIcon size="small" />}
        />
      ),
      style: navLinkStyle,
    },
    {
      key: "logout",
      content: (
        // <NavLinkListItem
        //   displayText="Logout"
        //   path="logout"
        //   icon={<SpeakerPersonIcon size="small" />}
        // />
        <Button
          icon={<GeofenceLeavesIcon outline size="medium" />}
          size={"small"}
          content={<strong>{"Sign out"}</strong>}
          onClick={async () => {
            await signoutAndClearContext(userContext, client, setReload, reload);
          }}
          secondary
          iconPosition="before"
          text
          fluid
        />
      ),
      style: navLinkStyle,
    },
  ];

  const headerDisplayName = communityName;

  if (userFirstName && userFirstName.toLowerCase() !== "shruthi") {
    pullAllBy(navLinkListItems, [{ key: "admin" }], "key");
  }

  return showSidebar ? (
    <Box
      style={{
        backgroundColor: "#8a8db614",
        height: "100%",
        width: 200,
        paddingTop: 20,
        position: "fixed",
        overflowY: "scroll",
        overflowX: "hidden"
      }}
    >
      <Flex column>
        <Box style={{ textAlign: "center", paddingTop: 5 }}>
          <Text
            content={` Welcome ${userFirstName}!`}
            style={{
              marginRight: 3,
              fontSize: 10,
              color: "#484644",
              fontFamily: "system-ui",
            }}
            weight="semilight"
            timestamp
          />
        </Box>

        <Flex hAlign="center" vAlign="center">
          <Image src={"/HOA_Logo.png"} width="35" />
          <Header
            as="h3"
            content={headerDisplayName}
            style={{ paddingLeft: 10 }}
          />
        </Flex>
        <Text size="smallest" temporary content="Beta Version" align="end" weight="semibold" style={{ paddingRight: 10, color: "grey", fontSize: 10 }} />
        <Divider content="" style={{ width: "100%", marginLeft: 25 }} />
      </Flex>
      <List
        style={{ listStyleType: "none", paddingLeft: 10, textAlign: "left" }}
        defaultSelectedIndex={0}
        items={navLinkListItems}
      ></List>
      {/* <h3>Secondary Nav</h3>
    {props.children} */}
    </Box>
  ) : null;
};

const NavLinkListItem = ({
  displayText,
  path,
  icon,
}: {
  displayText: string;
  path: string;
  icon: any; //TODO: need to fix type
}) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(`/${path}`);
  return (
    <>
      <NavLink to={path}>
        <Button
          icon={icon}
          size={"small"}
          content={<strong>{displayText}</strong>}
          // secondary
          iconPosition="before"
          style={{
            color: isActive ? "#6264a7" : "#484644",
          }}
          text
          fluid
        />{" "}
      </NavLink>
    </>
  );
};


