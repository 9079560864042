import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  accessToken?: Maybe<AccessTokenResult>;
  communities?: Maybe<Array<Maybe<Community>>>;
  community?: Maybe<Community>;
  communityTasks?: Maybe<Array<Maybe<Task>>>;
  communityUnits?: Maybe<Array<Maybe<Unit>>>;
  communityUsers?: Maybe<Array<Maybe<User>>>;
  communityUsersLoginDetails?: Maybe<Array<Maybe<UserLoginDetails>>>;
  currentCommunity?: Maybe<Community>;
  loginSetupStatus?: Maybe<LoginSetupStatusResult>;
  taskComments?: Maybe<Array<Maybe<TaskComment>>>;
  taskDetails?: Maybe<Task>;
  taskDetailsByCommunityTaskId?: Maybe<Task>;
  tenantUnit?: Maybe<Unit>;
  unitDetails?: Maybe<UnitDetails>;
  userDetails?: Maybe<UserDetails>;
};


export type QueryAccessTokenArgs = {
  loginId: Scalars['String'];
  password: Scalars['String'];
};


export type QueryCommunityArgs = {
  communityId: Scalars['ID'];
};


export type QueryCommunityTasksArgs = {
  communityId: Scalars['ID'];
};


export type QueryCommunityUnitsArgs = {
  communityId: Scalars['ID'];
};


export type QueryCommunityUsersArgs = {
  communityId: Scalars['ID'];
};


export type QueryCommunityUsersLoginDetailsArgs = {
  communityId: Scalars['ID'];
};


export type QueryLoginSetupStatusArgs = {
  userId: Scalars['ID'];
};


export type QueryTaskCommentsArgs = {
  taskId: Scalars['ID'];
};


export type QueryTaskDetailsArgs = {
  taskId: Scalars['ID'];
};


export type QueryTaskDetailsByCommunityTaskIdArgs = {
  communityId: Scalars['ID'];
  communityTaskId: Scalars['Int'];
};


export type QueryTenantUnitArgs = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryUnitDetailsArgs = {
  unitId: Scalars['ID'];
};


export type QueryUserDetailsArgs = {
  userId: Scalars['ID'];
};

export type LoginSetupStatusResult = {
  __typename?: 'LoginSetupStatusResult';
  status: LoginSetupStatus;
};


export type LoginSetupStatusResultStatusArgs = {
  type?: Maybe<LoginSetupStatus>;
};

export enum LoginSetupStatus {
  NotAvailable = 'NOT_AVAILABLE',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED'
}

export type AccessTokenResult = {
  __typename?: 'AccessTokenResult';
  statusCode: Scalars['Int'];
  statusText: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
};

export type Community = {
  __typename?: 'Community';
  id: Scalars['ID'];
  name: Scalars['String'];
  contactDetails: ContactDetails;
  primaryAddress: Address;
  isActive: Scalars['Boolean'];
};

export type ContactDetails = {
  __typename?: 'ContactDetails';
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
};

export type UnitDetails = {
  __typename?: 'UnitDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  community: Scalars['ID'];
  address: Address;
  owners?: Maybe<Array<Maybe<UserBasicDetails>>>;
  tenants?: Maybe<Array<Maybe<UserBasicDetails>>>;
  occupancyType: OccupancyType;
  isActive: Scalars['Boolean'];
};


export type UnitDetailsOccupancyTypeArgs = {
  type?: Maybe<OccupancyType>;
};

export type UserBasicDetails = {
  __typename?: 'UserBasicDetails';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  contactDetails: ContactDetails;
  boardMemberDetails?: Maybe<BoardMemberDetails>;
  address?: Maybe<Address>;
  loginEnabled?: Maybe<Scalars['Boolean']>;
};

export type BoardMemberDetails = {
  __typename?: 'BoardMemberDetails';
  title?: Maybe<Scalars['String']>;
};

export enum OccupancyType {
  None = 'NONE',
  Owner = 'OWNER',
  Tenant = 'TENANT'
}

export type UserDetails = {
  __typename?: 'UserDetails';
  id: Scalars['ID'];
  community: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  contactDetails: ContactDetails;
  address: Address;
  roles: Array<Maybe<Scalars['String']>>;
  boardMemberDetails?: Maybe<BoardMemberDetails>;
  unitsOwned: Array<Maybe<UnitBasicDetails>>;
  isActive: Scalars['Boolean'];
  loginEnabled?: Maybe<Scalars['Boolean']>;
};


export type UserDetailsRolesArgs = {
  role?: Maybe<Role>;
};

export enum Role {
  BoardMember = 'BOARD_MEMBER',
  Owner = 'OWNER',
  Tenant = 'TENANT',
  Admin = 'ADMIN'
}

export type UnitBasicDetails = {
  __typename?: 'UnitBasicDetails';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  occupancyType?: Maybe<OccupancyType>;
};

export type Unit = {
  __typename?: 'Unit';
  id: Scalars['ID'];
  name: Scalars['String'];
  community: Scalars['ID'];
  address: Address;
  owners?: Maybe<Array<Scalars['ID']>>;
  tenants?: Maybe<Array<Scalars['ID']>>;
  occupancyType: OccupancyType;
  isActive: Scalars['Boolean'];
};


export type UnitOccupancyTypeArgs = {
  type?: Maybe<OccupancyType>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  community: Scalars['ID'];
  roles: Array<Maybe<Scalars['String']>>;
  unitsOwned: Array<Maybe<Unit>>;
  contactDetails: ContactDetails;
  address?: Maybe<Address>;
  boardMemberDetails?: Maybe<BoardMemberDetails>;
  isActive: Scalars['Boolean'];
  loginEnabled?: Maybe<Scalars['Boolean']>;
};


export type UserRolesArgs = {
  role?: Maybe<Role>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  communityTaskId: Scalars['Int'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  community: Scalars['ID'];
  forUnits?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assignedTo?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
  createdAt: Scalars['String'];
  closedAt?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  createdBy: Scalars['ID'];
  closedBy?: Maybe<Scalars['ID']>;
  attachments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserLoginDetails = {
  __typename?: 'UserLoginDetails';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  setup?: Maybe<LoginSetupDetails>;
  recoveryEmail?: Maybe<Scalars['String']>;
};

export type LoginSetupDetails = {
  __typename?: 'LoginSetupDetails';
  verificationCode?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type TaskComment = {
  __typename?: 'TaskComment';
  id: Scalars['ID'];
  taskId: Scalars['ID'];
  comment: Scalars['String'];
  commentedBy: Scalars['ID'];
  updatedAt: Scalars['String'];
  createdAt: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCommunity?: Maybe<Community>;
  createUnit?: Maybe<Unit>;
  createUser?: Maybe<User>;
  createTask?: Maybe<Task>;
  addTaskComment?: Maybe<TaskComment>;
  updateUnit?: Maybe<UnitDetails>;
  updateUser?: Maybe<User>;
  updateOwners?: Maybe<UnitDetails>;
  updateTenants?: Maybe<UnitDetails>;
  addNewUnitTenant?: Maybe<UnitDetails>;
  addNewUnitOwner?: Maybe<UnitDetails>;
  updateTask?: Maybe<Task>;
  updateTaskComment?: Maybe<TaskComment>;
  deleteTask?: Maybe<Result>;
  deleteTaskComment?: Maybe<Result>;
  taskAttachment: File;
  submitFeedback?: Maybe<Result>;
  enableLogin?: Maybe<LoginSetupDetails>;
  disableLogin?: Maybe<MessageResult>;
  resetLoginSetup?: Maybe<LoginSetupDetails>;
  loginSetup?: Maybe<LoginSetupResult>;
};


export type MutationCreateCommunityArgs = {
  input?: Maybe<CommunityInput>;
};


export type MutationCreateUnitArgs = {
  input?: Maybe<UnitInput>;
};


export type MutationCreateUserArgs = {
  input?: Maybe<UserInput>;
};


export type MutationCreateTaskArgs = {
  input?: Maybe<NewTaskInput>;
};


export type MutationAddTaskCommentArgs = {
  input?: Maybe<NewTaskCommentInput>;
};


export type MutationUpdateUnitArgs = {
  unitId?: Maybe<Scalars['String']>;
  input?: Maybe<UpdateUnitInput>;
};


export type MutationUpdateUserArgs = {
  input?: Maybe<UpdateUserInput>;
};


export type MutationUpdateOwnersArgs = {
  unitId?: Maybe<Scalars['String']>;
  newOwnerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateTenantsArgs = {
  unitId?: Maybe<Scalars['String']>;
  newTenantIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationAddNewUnitTenantArgs = {
  unitId?: Maybe<Scalars['String']>;
  input?: Maybe<UserInput>;
};


export type MutationAddNewUnitOwnerArgs = {
  unitId?: Maybe<Scalars['String']>;
  input?: Maybe<UserInput>;
};


export type MutationUpdateTaskArgs = {
  input?: Maybe<UpdateTaskInput>;
};


export type MutationUpdateTaskCommentArgs = {
  input?: Maybe<UpdateTaskCommentInput>;
};


export type MutationDeleteTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationDeleteTaskCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationTaskAttachmentArgs = {
  file: Scalars['Upload'];
  taskId: Scalars['String'];
};


export type MutationSubmitFeedbackArgs = {
  input?: Maybe<FeedbackInput>;
};


export type MutationEnableLoginArgs = {
  userId: Scalars['ID'];
  elevatedAccessCode: Scalars['String'];
};


export type MutationDisableLoginArgs = {
  userId: Scalars['ID'];
  elevatedAccessCode: Scalars['String'];
  notifyUser: Scalars['Boolean'];
};


export type MutationResetLoginSetupArgs = {
  userId: Scalars['ID'];
  elevatedAccessCode?: Maybe<Scalars['String']>;
};


export type MutationLoginSetupArgs = {
  userId: Scalars['ID'];
  verificationCode: Scalars['String'];
  recoveryEmail: Scalars['String'];
  password: Scalars['String'];
};

export type CommunityInput = {
  name: Scalars['String'];
  contactDetails: ContactDetailsInput;
  primaryAddress: AddressInput;
  isActive: Scalars['Boolean'];
};

export type ContactDetailsInput = {
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type AddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
};

export type UnitInput = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  community: Scalars['ID'];
  owners?: Maybe<Array<Maybe<UserInput>>>;
  tenants?: Maybe<Array<Maybe<UserInput>>>;
  occupancyType?: Maybe<OccupancyType>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  community: Scalars['ID'];
  contactDetails: ContactDetailsInput;
  address: AddressInput;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitsOwned?: Maybe<Array<Maybe<UnitInput>>>;
  boardMemberDetails?: Maybe<BoardMemberDetailsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type BoardMemberDetailsInput = {
  title?: Maybe<Scalars['String']>;
};

export type NewTaskInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  community: Scalars['ID'];
  forUnits?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assignedTo?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdBy: Scalars['ID'];
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewTaskCommentInput = {
  taskId: Scalars['ID'];
  comment: Scalars['String'];
  commentedBy: Scalars['ID'];
};

export type UpdateUnitInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  community?: Maybe<Scalars['ID']>;
  owners?: Maybe<Array<Maybe<UserInput>>>;
  tenants?: Maybe<Array<Maybe<UserInput>>>;
  occupancyType?: Maybe<OccupancyType>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['ID']>;
  contactDetails?: Maybe<ContactDetailsInput>;
  address?: Maybe<AddressInput>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  boardMemberDetails?: Maybe<BoardMemberDetailsInput>;
  unitsOwned?: Maybe<Array<Maybe<UnitInput>>>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UpdateTaskInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  forUnits?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assignedTo?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  dateClosed?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  closedBy?: Maybe<Scalars['ID']>;
  attachments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  closedAt?: Maybe<Scalars['String']>;
};

export type UpdateTaskCommentInput = {
  id: Scalars['ID'];
  taskId: Scalars['ID'];
  comment: Scalars['String'];
};

export type Result = {
  __typename?: 'Result';
  value?: Maybe<Scalars['Boolean']>;
};


export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type FeedbackInput = {
  notes: Scalars['String'];
  reportedUser: Scalars['ID'];
};

export type MessageResult = {
  __typename?: 'MessageResult';
  message?: Maybe<Scalars['String']>;
};

export type LoginSetupResult = {
  __typename?: 'LoginSetupResult';
  errorMessage?: Maybe<Scalars['String']>;
  isSuccess: Scalars['Boolean'];
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  notes: Scalars['String'];
  reportedUser: Scalars['ID'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type AccessTokenResultFragment = (
  { __typename?: 'AccessTokenResult' }
  & Pick<AccessTokenResult, 'statusCode' | 'statusText' | 'accessToken' | 'failureReason'>
);

export type AddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'addressLine1' | 'addressLine2' | 'zipCode' | 'city' | 'state'>
);

export type CommunityFragment = (
  { __typename?: 'Community' }
  & Pick<Community, 'id' | 'name' | 'isActive'>
  & { contactDetails: (
    { __typename?: 'ContactDetails' }
    & ContactDetailsFragment
  ) }
);

export type ContactDetailsFragment = (
  { __typename?: 'ContactDetails' }
  & Pick<ContactDetails, 'email' | 'phone'>
);

export type FeedbackFragment = (
  { __typename?: 'Feedback' }
  & Pick<Feedback, 'notes' | 'reportedUser'>
);

export type LoginSetupDetailsFragment = (
  { __typename?: 'LoginSetupDetails' }
  & Pick<LoginSetupDetails, 'verificationCode' | 'expiresAt' | 'notes'>
);

export type LoginSetupStatusResultFragment = (
  { __typename?: 'LoginSetupStatusResult' }
  & Pick<LoginSetupStatusResult, 'status'>
);

export type TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'title' | 'communityTaskId' | 'description' | 'forUnits' | 'community' | 'assignedTo' | 'status' | 'isActive' | 'createdBy' | 'closedBy' | 'createdAt' | 'closedAt' | 'labels' | 'attachments'>
);

export type TaskCommentFragment = (
  { __typename?: 'TaskComment' }
  & Pick<TaskComment, 'id' | 'taskId' | 'comment' | 'commentedBy' | 'createdAt' | 'updatedAt'>
);

export type UnitFragment = (
  { __typename?: 'Unit' }
  & Pick<Unit, 'id' | 'name' | 'owners' | 'tenants' | 'occupancyType' | 'isActive'>
  & { address: (
    { __typename?: 'Address' }
    & AddressFragment
  ) }
);

export type UnitBasicDetailsFragment = (
  { __typename?: 'UnitBasicDetails' }
  & Pick<UnitBasicDetails, 'id' | 'name' | 'occupancyType'>
);

export type UnitDetailsFragment = (
  { __typename?: 'UnitDetails' }
  & Pick<UnitDetails, 'id' | 'name' | 'community' | 'occupancyType' | 'isActive'>
  & { address: (
    { __typename?: 'Address' }
    & AddressFragment
  ), owners?: Maybe<Array<Maybe<(
    { __typename?: 'UserBasicDetails' }
    & UserBasicDetailsFragment
  )>>>, tenants?: Maybe<Array<Maybe<(
    { __typename?: 'UserBasicDetails' }
    & UserBasicDetailsFragment
  )>>> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'roles' | 'isActive' | 'loginEnabled'>
  & { unitsOwned: Array<Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, 'id' | 'name'>
  )>>, contactDetails: (
    { __typename?: 'ContactDetails' }
    & ContactDetailsFragment
  ), address?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )>, boardMemberDetails?: Maybe<(
    { __typename?: 'BoardMemberDetails' }
    & Pick<BoardMemberDetails, 'title'>
  )> }
);

export type UserBasicDetailsFragment = (
  { __typename?: 'UserBasicDetails' }
  & Pick<UserBasicDetails, 'id' | 'firstName' | 'lastName' | 'loginEnabled'>
  & { contactDetails: (
    { __typename?: 'ContactDetails' }
    & ContactDetailsFragment
  ), address?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )>, boardMemberDetails?: Maybe<(
    { __typename?: 'BoardMemberDetails' }
    & Pick<BoardMemberDetails, 'title'>
  )> }
);

export type UserDetailsFragment = (
  { __typename?: 'UserDetails' }
  & Pick<UserDetails, 'id' | 'community' | 'firstName' | 'lastName' | 'roles' | 'isActive' | 'loginEnabled'>
  & { contactDetails: (
    { __typename?: 'ContactDetails' }
    & ContactDetailsFragment
  ), address: (
    { __typename?: 'Address' }
    & AddressFragment
  ), unitsOwned: Array<Maybe<(
    { __typename?: 'UnitBasicDetails' }
    & Pick<UnitBasicDetails, 'id' | 'name' | 'occupancyType'>
  )>> }
);

export type UserLoginDetailsFragment = (
  { __typename?: 'UserLoginDetails' }
  & Pick<UserLoginDetails, 'id' | 'userId' | 'recoveryEmail'>
  & { setup?: Maybe<(
    { __typename?: 'LoginSetupDetails' }
    & Pick<LoginSetupDetails, 'verificationCode' | 'expiresAt' | 'notes'>
  )> }
);

export type AddNewUnitOwnerMutationVariables = Exact<{
  unitId: Scalars['String'];
  input?: Maybe<UserInput>;
}>;


export type AddNewUnitOwnerMutation = (
  { __typename?: 'Mutation' }
  & { addNewUnitOwner?: Maybe<(
    { __typename?: 'UnitDetails' }
    & UnitDetailsFragment
  )> }
);

export type AddNewUnitTenantMutationVariables = Exact<{
  unitId: Scalars['String'];
  input?: Maybe<UserInput>;
}>;


export type AddNewUnitTenantMutation = (
  { __typename?: 'Mutation' }
  & { addNewUnitTenant?: Maybe<(
    { __typename?: 'UnitDetails' }
    & UnitDetailsFragment
  )> }
);

export type AddTaskCommentMutationVariables = Exact<{
  input?: Maybe<NewTaskCommentInput>;
}>;


export type AddTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { addTaskComment?: Maybe<(
    { __typename?: 'TaskComment' }
    & TaskCommentFragment
  )> }
);

export type CreateTaskMutationVariables = Exact<{
  input?: Maybe<NewTaskInput>;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type CreateUnitMutationVariables = Exact<{
  input?: Maybe<UnitInput>;
}>;


export type CreateUnitMutation = (
  { __typename?: 'Mutation' }
  & { createUnit?: Maybe<(
    { __typename?: 'Unit' }
    & UnitFragment
  )> }
);

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask?: Maybe<(
    { __typename?: 'Result' }
    & Pick<Result, 'value'>
  )> }
);

export type DisableLoginMutationVariables = Exact<{
  userId: Scalars['ID'];
  elevatedAccessCode: Scalars['String'];
  notifyUser: Scalars['Boolean'];
}>;


export type DisableLoginMutation = (
  { __typename?: 'Mutation' }
  & { disableLogin?: Maybe<(
    { __typename?: 'MessageResult' }
    & Pick<MessageResult, 'message'>
  )> }
);

export type EnableLoginMutationVariables = Exact<{
  userId: Scalars['ID'];
  elevatedAccessCode: Scalars['String'];
}>;


export type EnableLoginMutation = (
  { __typename?: 'Mutation' }
  & { enableLogin?: Maybe<(
    { __typename?: 'LoginSetupDetails' }
    & LoginSetupDetailsFragment
  )> }
);

export type LoginSetupMutationVariables = Exact<{
  userId: Scalars['ID'];
  verificationCode: Scalars['String'];
  recoveryEmail: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginSetupMutation = (
  { __typename?: 'Mutation' }
  & { loginSetup?: Maybe<(
    { __typename?: 'LoginSetupResult' }
    & Pick<LoginSetupResult, 'errorMessage' | 'isSuccess'>
  )> }
);

export type RemoveAllTenantsMutationVariables = Exact<{
  unitId: Scalars['String'];
}>;


export type RemoveAllTenantsMutation = (
  { __typename?: 'Mutation' }
  & { updateTenants?: Maybe<(
    { __typename?: 'UnitDetails' }
    & UnitDetailsFragment
  )> }
);

export type ResetLoginSetupMutationVariables = Exact<{
  userId: Scalars['ID'];
  elevatedAccessCode?: Maybe<Scalars['String']>;
}>;


export type ResetLoginSetupMutation = (
  { __typename?: 'Mutation' }
  & { resetLoginSetup?: Maybe<(
    { __typename?: 'LoginSetupDetails' }
    & LoginSetupDetailsFragment
  )> }
);

export type SubmitFeedbackMutationVariables = Exact<{
  input?: Maybe<FeedbackInput>;
}>;


export type SubmitFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { submitFeedback?: Maybe<(
    { __typename?: 'Result' }
    & Pick<Result, 'value'>
  )> }
);

export type UpdateOwnersMutationVariables = Exact<{
  unitId: Scalars['String'];
  newOwnerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type UpdateOwnersMutation = (
  { __typename?: 'Mutation' }
  & { updateOwners?: Maybe<(
    { __typename?: 'UnitDetails' }
    & UnitDetailsFragment
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  input?: Maybe<UpdateTaskInput>;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type UpdateTenantsMutationVariables = Exact<{
  unitId: Scalars['String'];
  newTenantIds?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type UpdateTenantsMutation = (
  { __typename?: 'Mutation' }
  & { updateTenants?: Maybe<(
    { __typename?: 'UnitDetails' }
    & UnitDetailsFragment
  )> }
);

export type UpdateUnitMutationVariables = Exact<{
  input?: Maybe<UpdateUnitInput>;
}>;


export type UpdateUnitMutation = (
  { __typename?: 'Mutation' }
  & { updateUnit?: Maybe<(
    { __typename?: 'UnitDetails' }
    & UnitDetailsFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input?: Maybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type AccessTokenQueryVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type AccessTokenQuery = (
  { __typename?: 'Query' }
  & { accessToken?: Maybe<(
    { __typename?: 'AccessTokenResult' }
    & AccessTokenResultFragment
  )> }
);

export type CommunitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommunitiesQuery = (
  { __typename?: 'Query' }
  & { communities?: Maybe<Array<Maybe<(
    { __typename?: 'Community' }
    & CommunityFragment
  )>>> }
);

export type CommunityQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type CommunityQuery = (
  { __typename?: 'Query' }
  & { community?: Maybe<(
    { __typename?: 'Community' }
    & CommunityFragment
  )> }
);

export type CommunityUsersLoginDetailsQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type CommunityUsersLoginDetailsQuery = (
  { __typename?: 'Query' }
  & { communityUsersLoginDetails?: Maybe<Array<Maybe<(
    { __typename?: 'UserLoginDetails' }
    & UserLoginDetailsFragment
  )>>> }
);

export type CurrentCommunityQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentCommunityQuery = (
  { __typename?: 'Query' }
  & { currentCommunity?: Maybe<(
    { __typename?: 'Community' }
    & CommunityFragment
  )> }
);

export type LoginSetupStatusQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type LoginSetupStatusQuery = (
  { __typename?: 'Query' }
  & { loginSetupStatus?: Maybe<(
    { __typename?: 'LoginSetupStatusResult' }
    & LoginSetupStatusResultFragment
  )> }
);

export type TaskCommentsQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type TaskCommentsQuery = (
  { __typename?: 'Query' }
  & { taskComments?: Maybe<Array<Maybe<(
    { __typename?: 'TaskComment' }
    & TaskCommentFragment
  )>>> }
);

export type TaskDetailsByCommunityTaskIdQueryVariables = Exact<{
  communityId: Scalars['ID'];
  communityTaskId: Scalars['Int'];
}>;


export type TaskDetailsByCommunityTaskIdQuery = (
  { __typename?: 'Query' }
  & { taskDetailsByCommunityTaskId?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type TasksQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type TasksQuery = (
  { __typename?: 'Query' }
  & { communityTasks?: Maybe<Array<Maybe<(
    { __typename?: 'Task' }
    & TaskFragment
  )>>> }
);

export type TenantUnitQueryVariables = Exact<{
  communityId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type TenantUnitQuery = (
  { __typename?: 'Query' }
  & { tenantUnit?: Maybe<(
    { __typename?: 'Unit' }
    & UnitFragment
  )> }
);

export type UnitDetailsQueryVariables = Exact<{
  unitId: Scalars['ID'];
}>;


export type UnitDetailsQuery = (
  { __typename?: 'Query' }
  & { unitDetails?: Maybe<(
    { __typename?: 'UnitDetails' }
    & UnitDetailsFragment
  )> }
);

export type UnitsQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type UnitsQuery = (
  { __typename?: 'Query' }
  & { communityUnits?: Maybe<Array<Maybe<(
    { __typename?: 'Unit' }
    & UnitFragment
  )>>> }
);

export type UserDetailsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserDetailsQuery = (
  { __typename?: 'Query' }
  & { userDetails?: Maybe<(
    { __typename?: 'UserDetails' }
    & UserDetailsFragment
  )> }
);

export type UsersQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { communityUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )>>> }
);

export const AccessTokenResultFragmentDoc = gql`
    fragment AccessTokenResult on AccessTokenResult {
  statusCode
  statusText
  accessToken
  failureReason
}
    `;
export const ContactDetailsFragmentDoc = gql`
    fragment ContactDetails on ContactDetails {
  email
  phone
}
    `;
export const CommunityFragmentDoc = gql`
    fragment Community on Community {
  id
  name
  isActive
  contactDetails {
    ...ContactDetails
  }
}
    ${ContactDetailsFragmentDoc}`;
export const FeedbackFragmentDoc = gql`
    fragment Feedback on Feedback {
  notes
  reportedUser
}
    `;
export const LoginSetupDetailsFragmentDoc = gql`
    fragment LoginSetupDetails on LoginSetupDetails {
  verificationCode
  expiresAt
  notes
}
    `;
export const LoginSetupStatusResultFragmentDoc = gql`
    fragment LoginSetupStatusResult on LoginSetupStatusResult {
  status
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on Task {
  id
  title
  communityTaskId
  description
  forUnits
  community
  assignedTo
  status
  isActive
  createdBy
  closedBy
  createdAt
  closedAt
  labels
  attachments
}
    `;
export const TaskCommentFragmentDoc = gql`
    fragment TaskComment on TaskComment {
  id
  taskId
  comment
  commentedBy
  createdAt
  updatedAt
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  addressLine1
  addressLine2
  zipCode
  city
  state
}
    `;
export const UnitFragmentDoc = gql`
    fragment Unit on Unit {
  id
  name
  address {
    ...Address
  }
  owners
  tenants
  occupancyType
  isActive
}
    ${AddressFragmentDoc}`;
export const UnitBasicDetailsFragmentDoc = gql`
    fragment UnitBasicDetails on UnitBasicDetails {
  id
  name
  occupancyType
}
    `;
export const UserBasicDetailsFragmentDoc = gql`
    fragment UserBasicDetails on UserBasicDetails {
  id
  firstName
  lastName
  contactDetails {
    ...ContactDetails
  }
  address {
    ...Address
  }
  boardMemberDetails {
    title
  }
  loginEnabled
}
    ${ContactDetailsFragmentDoc}
${AddressFragmentDoc}`;
export const UnitDetailsFragmentDoc = gql`
    fragment UnitDetails on UnitDetails {
  id
  name
  community
  address {
    ...Address
  }
  owners {
    ...UserBasicDetails
  }
  tenants {
    ...UserBasicDetails
  }
  occupancyType
  isActive
}
    ${AddressFragmentDoc}
${UserBasicDetailsFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName
  lastName
  unitsOwned {
    id
    name
  }
  contactDetails {
    ...ContactDetails
  }
  address {
    ...Address
  }
  boardMemberDetails {
    title
  }
  roles
  isActive
  loginEnabled
}
    ${ContactDetailsFragmentDoc}
${AddressFragmentDoc}`;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on UserDetails {
  id
  community
  firstName
  lastName
  contactDetails {
    ...ContactDetails
  }
  address {
    ...Address
  }
  unitsOwned {
    id
    name
    occupancyType
  }
  roles
  isActive
  loginEnabled
}
    ${ContactDetailsFragmentDoc}
${AddressFragmentDoc}`;
export const UserLoginDetailsFragmentDoc = gql`
    fragment UserLoginDetails on UserLoginDetails {
  id
  userId
  recoveryEmail
  setup {
    verificationCode
    expiresAt
    notes
  }
}
    `;
export const AddNewUnitOwnerDocument = gql`
    mutation addNewUnitOwner($unitId: String!, $input: UserInput) {
  addNewUnitOwner(unitId: $unitId, input: $input) {
    ...UnitDetails
  }
}
    ${UnitDetailsFragmentDoc}`;
export type AddNewUnitOwnerMutationFn = Apollo.MutationFunction<AddNewUnitOwnerMutation, AddNewUnitOwnerMutationVariables>;

/**
 * __useAddNewUnitOwnerMutation__
 *
 * To run a mutation, you first call `useAddNewUnitOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewUnitOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewUnitOwnerMutation, { data, loading, error }] = useAddNewUnitOwnerMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNewUnitOwnerMutation(baseOptions?: Apollo.MutationHookOptions<AddNewUnitOwnerMutation, AddNewUnitOwnerMutationVariables>) {
        return Apollo.useMutation<AddNewUnitOwnerMutation, AddNewUnitOwnerMutationVariables>(AddNewUnitOwnerDocument, baseOptions);
      }
export type AddNewUnitOwnerMutationHookResult = ReturnType<typeof useAddNewUnitOwnerMutation>;
export type AddNewUnitOwnerMutationResult = Apollo.MutationResult<AddNewUnitOwnerMutation>;
export type AddNewUnitOwnerMutationOptions = Apollo.BaseMutationOptions<AddNewUnitOwnerMutation, AddNewUnitOwnerMutationVariables>;
export const AddNewUnitTenantDocument = gql`
    mutation addNewUnitTenant($unitId: String!, $input: UserInput) {
  addNewUnitTenant(unitId: $unitId, input: $input) {
    ...UnitDetails
  }
}
    ${UnitDetailsFragmentDoc}`;
export type AddNewUnitTenantMutationFn = Apollo.MutationFunction<AddNewUnitTenantMutation, AddNewUnitTenantMutationVariables>;

/**
 * __useAddNewUnitTenantMutation__
 *
 * To run a mutation, you first call `useAddNewUnitTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewUnitTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewUnitTenantMutation, { data, loading, error }] = useAddNewUnitTenantMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNewUnitTenantMutation(baseOptions?: Apollo.MutationHookOptions<AddNewUnitTenantMutation, AddNewUnitTenantMutationVariables>) {
        return Apollo.useMutation<AddNewUnitTenantMutation, AddNewUnitTenantMutationVariables>(AddNewUnitTenantDocument, baseOptions);
      }
export type AddNewUnitTenantMutationHookResult = ReturnType<typeof useAddNewUnitTenantMutation>;
export type AddNewUnitTenantMutationResult = Apollo.MutationResult<AddNewUnitTenantMutation>;
export type AddNewUnitTenantMutationOptions = Apollo.BaseMutationOptions<AddNewUnitTenantMutation, AddNewUnitTenantMutationVariables>;
export const AddTaskCommentDocument = gql`
    mutation addTaskComment($input: NewTaskCommentInput) {
  addTaskComment(input: $input) {
    ...TaskComment
  }
}
    ${TaskCommentFragmentDoc}`;
export type AddTaskCommentMutationFn = Apollo.MutationFunction<AddTaskCommentMutation, AddTaskCommentMutationVariables>;

/**
 * __useAddTaskCommentMutation__
 *
 * To run a mutation, you first call `useAddTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskCommentMutation, { data, loading, error }] = useAddTaskCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTaskCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddTaskCommentMutation, AddTaskCommentMutationVariables>) {
        return Apollo.useMutation<AddTaskCommentMutation, AddTaskCommentMutationVariables>(AddTaskCommentDocument, baseOptions);
      }
export type AddTaskCommentMutationHookResult = ReturnType<typeof useAddTaskCommentMutation>;
export type AddTaskCommentMutationResult = Apollo.MutationResult<AddTaskCommentMutation>;
export type AddTaskCommentMutationOptions = Apollo.BaseMutationOptions<AddTaskCommentMutation, AddTaskCommentMutationVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($input: NewTaskInput) {
  createTask(input: $input) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, baseOptions);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const CreateUnitDocument = gql`
    mutation createUnit($input: UnitInput) {
  createUnit(input: $input) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type CreateUnitMutationFn = Apollo.MutationFunction<CreateUnitMutation, CreateUnitMutationVariables>;

/**
 * __useCreateUnitMutation__
 *
 * To run a mutation, you first call `useCreateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitMutation, { data, loading, error }] = useCreateUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitMutation, CreateUnitMutationVariables>) {
        return Apollo.useMutation<CreateUnitMutation, CreateUnitMutationVariables>(CreateUnitDocument, baseOptions);
      }
export type CreateUnitMutationHookResult = ReturnType<typeof useCreateUnitMutation>;
export type CreateUnitMutationResult = Apollo.MutationResult<CreateUnitMutation>;
export type CreateUnitMutationOptions = Apollo.BaseMutationOptions<CreateUnitMutation, CreateUnitMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($taskId: ID!) {
  deleteTask(taskId: $taskId) {
    value
  }
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, baseOptions);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const DisableLoginDocument = gql`
    mutation disableLogin($userId: ID!, $elevatedAccessCode: String!, $notifyUser: Boolean!) {
  disableLogin(userId: $userId, elevatedAccessCode: $elevatedAccessCode, notifyUser: $notifyUser) {
    message
  }
}
    `;
export type DisableLoginMutationFn = Apollo.MutationFunction<DisableLoginMutation, DisableLoginMutationVariables>;

/**
 * __useDisableLoginMutation__
 *
 * To run a mutation, you first call `useDisableLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableLoginMutation, { data, loading, error }] = useDisableLoginMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      elevatedAccessCode: // value for 'elevatedAccessCode'
 *      notifyUser: // value for 'notifyUser'
 *   },
 * });
 */
export function useDisableLoginMutation(baseOptions?: Apollo.MutationHookOptions<DisableLoginMutation, DisableLoginMutationVariables>) {
        return Apollo.useMutation<DisableLoginMutation, DisableLoginMutationVariables>(DisableLoginDocument, baseOptions);
      }
export type DisableLoginMutationHookResult = ReturnType<typeof useDisableLoginMutation>;
export type DisableLoginMutationResult = Apollo.MutationResult<DisableLoginMutation>;
export type DisableLoginMutationOptions = Apollo.BaseMutationOptions<DisableLoginMutation, DisableLoginMutationVariables>;
export const EnableLoginDocument = gql`
    mutation enableLogin($userId: ID!, $elevatedAccessCode: String!) {
  enableLogin(userId: $userId, elevatedAccessCode: $elevatedAccessCode) {
    ...LoginSetupDetails
  }
}
    ${LoginSetupDetailsFragmentDoc}`;
export type EnableLoginMutationFn = Apollo.MutationFunction<EnableLoginMutation, EnableLoginMutationVariables>;

/**
 * __useEnableLoginMutation__
 *
 * To run a mutation, you first call `useEnableLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableLoginMutation, { data, loading, error }] = useEnableLoginMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      elevatedAccessCode: // value for 'elevatedAccessCode'
 *   },
 * });
 */
export function useEnableLoginMutation(baseOptions?: Apollo.MutationHookOptions<EnableLoginMutation, EnableLoginMutationVariables>) {
        return Apollo.useMutation<EnableLoginMutation, EnableLoginMutationVariables>(EnableLoginDocument, baseOptions);
      }
export type EnableLoginMutationHookResult = ReturnType<typeof useEnableLoginMutation>;
export type EnableLoginMutationResult = Apollo.MutationResult<EnableLoginMutation>;
export type EnableLoginMutationOptions = Apollo.BaseMutationOptions<EnableLoginMutation, EnableLoginMutationVariables>;
export const LoginSetupDocument = gql`
    mutation loginSetup($userId: ID!, $verificationCode: String!, $recoveryEmail: String!, $password: String!) {
  loginSetup(userId: $userId, verificationCode: $verificationCode, recoveryEmail: $recoveryEmail, password: $password) {
    errorMessage
    isSuccess
  }
}
    `;
export type LoginSetupMutationFn = Apollo.MutationFunction<LoginSetupMutation, LoginSetupMutationVariables>;

/**
 * __useLoginSetupMutation__
 *
 * To run a mutation, you first call `useLoginSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSetupMutation, { data, loading, error }] = useLoginSetupMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      verificationCode: // value for 'verificationCode'
 *      recoveryEmail: // value for 'recoveryEmail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginSetupMutation(baseOptions?: Apollo.MutationHookOptions<LoginSetupMutation, LoginSetupMutationVariables>) {
        return Apollo.useMutation<LoginSetupMutation, LoginSetupMutationVariables>(LoginSetupDocument, baseOptions);
      }
export type LoginSetupMutationHookResult = ReturnType<typeof useLoginSetupMutation>;
export type LoginSetupMutationResult = Apollo.MutationResult<LoginSetupMutation>;
export type LoginSetupMutationOptions = Apollo.BaseMutationOptions<LoginSetupMutation, LoginSetupMutationVariables>;
export const RemoveAllTenantsDocument = gql`
    mutation removeAllTenants($unitId: String!) {
  updateTenants(unitId: $unitId, newTenantIds: []) {
    ...UnitDetails
  }
}
    ${UnitDetailsFragmentDoc}`;
export type RemoveAllTenantsMutationFn = Apollo.MutationFunction<RemoveAllTenantsMutation, RemoveAllTenantsMutationVariables>;

/**
 * __useRemoveAllTenantsMutation__
 *
 * To run a mutation, you first call `useRemoveAllTenantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllTenantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllTenantsMutation, { data, loading, error }] = useRemoveAllTenantsMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useRemoveAllTenantsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAllTenantsMutation, RemoveAllTenantsMutationVariables>) {
        return Apollo.useMutation<RemoveAllTenantsMutation, RemoveAllTenantsMutationVariables>(RemoveAllTenantsDocument, baseOptions);
      }
export type RemoveAllTenantsMutationHookResult = ReturnType<typeof useRemoveAllTenantsMutation>;
export type RemoveAllTenantsMutationResult = Apollo.MutationResult<RemoveAllTenantsMutation>;
export type RemoveAllTenantsMutationOptions = Apollo.BaseMutationOptions<RemoveAllTenantsMutation, RemoveAllTenantsMutationVariables>;
export const ResetLoginSetupDocument = gql`
    mutation resetLoginSetup($userId: ID!, $elevatedAccessCode: String) {
  resetLoginSetup(userId: $userId, elevatedAccessCode: $elevatedAccessCode) {
    ...LoginSetupDetails
  }
}
    ${LoginSetupDetailsFragmentDoc}`;
export type ResetLoginSetupMutationFn = Apollo.MutationFunction<ResetLoginSetupMutation, ResetLoginSetupMutationVariables>;

/**
 * __useResetLoginSetupMutation__
 *
 * To run a mutation, you first call `useResetLoginSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetLoginSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetLoginSetupMutation, { data, loading, error }] = useResetLoginSetupMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      elevatedAccessCode: // value for 'elevatedAccessCode'
 *   },
 * });
 */
export function useResetLoginSetupMutation(baseOptions?: Apollo.MutationHookOptions<ResetLoginSetupMutation, ResetLoginSetupMutationVariables>) {
        return Apollo.useMutation<ResetLoginSetupMutation, ResetLoginSetupMutationVariables>(ResetLoginSetupDocument, baseOptions);
      }
export type ResetLoginSetupMutationHookResult = ReturnType<typeof useResetLoginSetupMutation>;
export type ResetLoginSetupMutationResult = Apollo.MutationResult<ResetLoginSetupMutation>;
export type ResetLoginSetupMutationOptions = Apollo.BaseMutationOptions<ResetLoginSetupMutation, ResetLoginSetupMutationVariables>;
export const SubmitFeedbackDocument = gql`
    mutation submitFeedback($input: FeedbackInput) {
  submitFeedback(input: $input) {
    value
  }
}
    `;
export type SubmitFeedbackMutationFn = Apollo.MutationFunction<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>) {
        return Apollo.useMutation<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(SubmitFeedbackDocument, baseOptions);
      }
export type SubmitFeedbackMutationHookResult = ReturnType<typeof useSubmitFeedbackMutation>;
export type SubmitFeedbackMutationResult = Apollo.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions = Apollo.BaseMutationOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;
export const UpdateOwnersDocument = gql`
    mutation updateOwners($unitId: String!, $newOwnerIds: [String]) {
  updateOwners(unitId: $unitId, newOwnerIds: $newOwnerIds) {
    ...UnitDetails
  }
}
    ${UnitDetailsFragmentDoc}`;
export type UpdateOwnersMutationFn = Apollo.MutationFunction<UpdateOwnersMutation, UpdateOwnersMutationVariables>;

/**
 * __useUpdateOwnersMutation__
 *
 * To run a mutation, you first call `useUpdateOwnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnersMutation, { data, loading, error }] = useUpdateOwnersMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      newOwnerIds: // value for 'newOwnerIds'
 *   },
 * });
 */
export function useUpdateOwnersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnersMutation, UpdateOwnersMutationVariables>) {
        return Apollo.useMutation<UpdateOwnersMutation, UpdateOwnersMutationVariables>(UpdateOwnersDocument, baseOptions);
      }
export type UpdateOwnersMutationHookResult = ReturnType<typeof useUpdateOwnersMutation>;
export type UpdateOwnersMutationResult = Apollo.MutationResult<UpdateOwnersMutation>;
export type UpdateOwnersMutationOptions = Apollo.BaseMutationOptions<UpdateOwnersMutation, UpdateOwnersMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($input: UpdateTaskInput) {
  updateTask(input: $input) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, baseOptions);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const UpdateTenantsDocument = gql`
    mutation updateTenants($unitId: String!, $newTenantIds: [String]) {
  updateTenants(unitId: $unitId, newTenantIds: $newTenantIds) {
    ...UnitDetails
  }
}
    ${UnitDetailsFragmentDoc}`;
export type UpdateTenantsMutationFn = Apollo.MutationFunction<UpdateTenantsMutation, UpdateTenantsMutationVariables>;

/**
 * __useUpdateTenantsMutation__
 *
 * To run a mutation, you first call `useUpdateTenantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantsMutation, { data, loading, error }] = useUpdateTenantsMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      newTenantIds: // value for 'newTenantIds'
 *   },
 * });
 */
export function useUpdateTenantsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantsMutation, UpdateTenantsMutationVariables>) {
        return Apollo.useMutation<UpdateTenantsMutation, UpdateTenantsMutationVariables>(UpdateTenantsDocument, baseOptions);
      }
export type UpdateTenantsMutationHookResult = ReturnType<typeof useUpdateTenantsMutation>;
export type UpdateTenantsMutationResult = Apollo.MutationResult<UpdateTenantsMutation>;
export type UpdateTenantsMutationOptions = Apollo.BaseMutationOptions<UpdateTenantsMutation, UpdateTenantsMutationVariables>;
export const UpdateUnitDocument = gql`
    mutation updateUnit($input: UpdateUnitInput) {
  updateUnit(input: $input) {
    ...UnitDetails
  }
}
    ${UnitDetailsFragmentDoc}`;
export type UpdateUnitMutationFn = Apollo.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitMutation, UpdateUnitMutationVariables>) {
        return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UpdateUnitDocument, baseOptions);
      }
export type UpdateUnitMutationHookResult = ReturnType<typeof useUpdateUnitMutation>;
export type UpdateUnitMutationResult = Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput) {
  updateUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AccessTokenDocument = gql`
    query AccessToken($loginId: String!, $password: String!) {
  accessToken(loginId: $loginId, password: $password) {
    ...AccessTokenResult
  }
}
    ${AccessTokenResultFragmentDoc}`;

/**
 * __useAccessTokenQuery__
 *
 * To run a query within a React component, call `useAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokenQuery({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAccessTokenQuery(baseOptions?: Apollo.QueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
        return Apollo.useQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, baseOptions);
      }
export function useAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
          return Apollo.useLazyQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, baseOptions);
        }
export type AccessTokenQueryHookResult = ReturnType<typeof useAccessTokenQuery>;
export type AccessTokenLazyQueryHookResult = ReturnType<typeof useAccessTokenLazyQuery>;
export type AccessTokenQueryResult = Apollo.QueryResult<AccessTokenQuery, AccessTokenQueryVariables>;
export const CommunitiesDocument = gql`
    query Communities {
  communities {
    ...Community
  }
}
    ${CommunityFragmentDoc}`;

/**
 * __useCommunitiesQuery__
 *
 * To run a query within a React component, call `useCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>) {
        return Apollo.useQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, baseOptions);
      }
export function useCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>) {
          return Apollo.useLazyQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, baseOptions);
        }
export type CommunitiesQueryHookResult = ReturnType<typeof useCommunitiesQuery>;
export type CommunitiesLazyQueryHookResult = ReturnType<typeof useCommunitiesLazyQuery>;
export type CommunitiesQueryResult = Apollo.QueryResult<CommunitiesQuery, CommunitiesQueryVariables>;
export const CommunityDocument = gql`
    query community($communityId: ID!) {
  community(communityId: $communityId) {
    ...Community
  }
}
    ${CommunityFragmentDoc}`;

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useCommunityQuery(baseOptions?: Apollo.QueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
        return Apollo.useQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, baseOptions);
      }
export function useCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
          return Apollo.useLazyQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, baseOptions);
        }
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>;
export type CommunityLazyQueryHookResult = ReturnType<typeof useCommunityLazyQuery>;
export type CommunityQueryResult = Apollo.QueryResult<CommunityQuery, CommunityQueryVariables>;
export const CommunityUsersLoginDetailsDocument = gql`
    query communityUsersLoginDetails($communityId: ID!) {
  communityUsersLoginDetails(communityId: $communityId) {
    ...UserLoginDetails
  }
}
    ${UserLoginDetailsFragmentDoc}`;

/**
 * __useCommunityUsersLoginDetailsQuery__
 *
 * To run a query within a React component, call `useCommunityUsersLoginDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityUsersLoginDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityUsersLoginDetailsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useCommunityUsersLoginDetailsQuery(baseOptions?: Apollo.QueryHookOptions<CommunityUsersLoginDetailsQuery, CommunityUsersLoginDetailsQueryVariables>) {
        return Apollo.useQuery<CommunityUsersLoginDetailsQuery, CommunityUsersLoginDetailsQueryVariables>(CommunityUsersLoginDetailsDocument, baseOptions);
      }
export function useCommunityUsersLoginDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityUsersLoginDetailsQuery, CommunityUsersLoginDetailsQueryVariables>) {
          return Apollo.useLazyQuery<CommunityUsersLoginDetailsQuery, CommunityUsersLoginDetailsQueryVariables>(CommunityUsersLoginDetailsDocument, baseOptions);
        }
export type CommunityUsersLoginDetailsQueryHookResult = ReturnType<typeof useCommunityUsersLoginDetailsQuery>;
export type CommunityUsersLoginDetailsLazyQueryHookResult = ReturnType<typeof useCommunityUsersLoginDetailsLazyQuery>;
export type CommunityUsersLoginDetailsQueryResult = Apollo.QueryResult<CommunityUsersLoginDetailsQuery, CommunityUsersLoginDetailsQueryVariables>;
export const CurrentCommunityDocument = gql`
    query CurrentCommunity {
  currentCommunity @client {
    ...Community
  }
}
    ${CommunityFragmentDoc}`;

/**
 * __useCurrentCommunityQuery__
 *
 * To run a query within a React component, call `useCurrentCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCommunityQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCommunityQuery(baseOptions?: Apollo.QueryHookOptions<CurrentCommunityQuery, CurrentCommunityQueryVariables>) {
        return Apollo.useQuery<CurrentCommunityQuery, CurrentCommunityQueryVariables>(CurrentCommunityDocument, baseOptions);
      }
export function useCurrentCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentCommunityQuery, CurrentCommunityQueryVariables>) {
          return Apollo.useLazyQuery<CurrentCommunityQuery, CurrentCommunityQueryVariables>(CurrentCommunityDocument, baseOptions);
        }
export type CurrentCommunityQueryHookResult = ReturnType<typeof useCurrentCommunityQuery>;
export type CurrentCommunityLazyQueryHookResult = ReturnType<typeof useCurrentCommunityLazyQuery>;
export type CurrentCommunityQueryResult = Apollo.QueryResult<CurrentCommunityQuery, CurrentCommunityQueryVariables>;
export const LoginSetupStatusDocument = gql`
    query LoginSetupStatus($userId: ID!) {
  loginSetupStatus(userId: $userId) {
    ...LoginSetupStatusResult
  }
}
    ${LoginSetupStatusResultFragmentDoc}`;

/**
 * __useLoginSetupStatusQuery__
 *
 * To run a query within a React component, call `useLoginSetupStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginSetupStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginSetupStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLoginSetupStatusQuery(baseOptions?: Apollo.QueryHookOptions<LoginSetupStatusQuery, LoginSetupStatusQueryVariables>) {
        return Apollo.useQuery<LoginSetupStatusQuery, LoginSetupStatusQueryVariables>(LoginSetupStatusDocument, baseOptions);
      }
export function useLoginSetupStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginSetupStatusQuery, LoginSetupStatusQueryVariables>) {
          return Apollo.useLazyQuery<LoginSetupStatusQuery, LoginSetupStatusQueryVariables>(LoginSetupStatusDocument, baseOptions);
        }
export type LoginSetupStatusQueryHookResult = ReturnType<typeof useLoginSetupStatusQuery>;
export type LoginSetupStatusLazyQueryHookResult = ReturnType<typeof useLoginSetupStatusLazyQuery>;
export type LoginSetupStatusQueryResult = Apollo.QueryResult<LoginSetupStatusQuery, LoginSetupStatusQueryVariables>;
export const TaskCommentsDocument = gql`
    query taskComments($taskId: ID!) {
  taskComments(taskId: $taskId) {
    ...TaskComment
  }
}
    ${TaskCommentFragmentDoc}`;

/**
 * __useTaskCommentsQuery__
 *
 * To run a query within a React component, call `useTaskCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCommentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskCommentsQuery(baseOptions?: Apollo.QueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>) {
        return Apollo.useQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(TaskCommentsDocument, baseOptions);
      }
export function useTaskCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>) {
          return Apollo.useLazyQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(TaskCommentsDocument, baseOptions);
        }
export type TaskCommentsQueryHookResult = ReturnType<typeof useTaskCommentsQuery>;
export type TaskCommentsLazyQueryHookResult = ReturnType<typeof useTaskCommentsLazyQuery>;
export type TaskCommentsQueryResult = Apollo.QueryResult<TaskCommentsQuery, TaskCommentsQueryVariables>;
export const TaskDetailsByCommunityTaskIdDocument = gql`
    query taskDetailsByCommunityTaskId($communityId: ID!, $communityTaskId: Int!) {
  taskDetailsByCommunityTaskId(communityId: $communityId, communityTaskId: $communityTaskId) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

/**
 * __useTaskDetailsByCommunityTaskIdQuery__
 *
 * To run a query within a React component, call `useTaskDetailsByCommunityTaskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailsByCommunityTaskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailsByCommunityTaskIdQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      communityTaskId: // value for 'communityTaskId'
 *   },
 * });
 */
export function useTaskDetailsByCommunityTaskIdQuery(baseOptions?: Apollo.QueryHookOptions<TaskDetailsByCommunityTaskIdQuery, TaskDetailsByCommunityTaskIdQueryVariables>) {
        return Apollo.useQuery<TaskDetailsByCommunityTaskIdQuery, TaskDetailsByCommunityTaskIdQueryVariables>(TaskDetailsByCommunityTaskIdDocument, baseOptions);
      }
export function useTaskDetailsByCommunityTaskIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskDetailsByCommunityTaskIdQuery, TaskDetailsByCommunityTaskIdQueryVariables>) {
          return Apollo.useLazyQuery<TaskDetailsByCommunityTaskIdQuery, TaskDetailsByCommunityTaskIdQueryVariables>(TaskDetailsByCommunityTaskIdDocument, baseOptions);
        }
export type TaskDetailsByCommunityTaskIdQueryHookResult = ReturnType<typeof useTaskDetailsByCommunityTaskIdQuery>;
export type TaskDetailsByCommunityTaskIdLazyQueryHookResult = ReturnType<typeof useTaskDetailsByCommunityTaskIdLazyQuery>;
export type TaskDetailsByCommunityTaskIdQueryResult = Apollo.QueryResult<TaskDetailsByCommunityTaskIdQuery, TaskDetailsByCommunityTaskIdQueryVariables>;
export const TasksDocument = gql`
    query Tasks($communityId: ID!) {
  communityTasks(communityId: $communityId) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, baseOptions);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, baseOptions);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TenantUnitDocument = gql`
    query TenantUnit($communityId: String!, $userId: String!) {
  tenantUnit(communityId: $communityId, userId: $userId) @client {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;

/**
 * __useTenantUnitQuery__
 *
 * To run a query within a React component, call `useTenantUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantUnitQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTenantUnitQuery(baseOptions?: Apollo.QueryHookOptions<TenantUnitQuery, TenantUnitQueryVariables>) {
        return Apollo.useQuery<TenantUnitQuery, TenantUnitQueryVariables>(TenantUnitDocument, baseOptions);
      }
export function useTenantUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantUnitQuery, TenantUnitQueryVariables>) {
          return Apollo.useLazyQuery<TenantUnitQuery, TenantUnitQueryVariables>(TenantUnitDocument, baseOptions);
        }
export type TenantUnitQueryHookResult = ReturnType<typeof useTenantUnitQuery>;
export type TenantUnitLazyQueryHookResult = ReturnType<typeof useTenantUnitLazyQuery>;
export type TenantUnitQueryResult = Apollo.QueryResult<TenantUnitQuery, TenantUnitQueryVariables>;
export const UnitDetailsDocument = gql`
    query UnitDetails($unitId: ID!) {
  unitDetails(unitId: $unitId) {
    ...UnitDetails
  }
}
    ${UnitDetailsFragmentDoc}`;

/**
 * __useUnitDetailsQuery__
 *
 * To run a query within a React component, call `useUnitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitDetailsQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useUnitDetailsQuery(baseOptions?: Apollo.QueryHookOptions<UnitDetailsQuery, UnitDetailsQueryVariables>) {
        return Apollo.useQuery<UnitDetailsQuery, UnitDetailsQueryVariables>(UnitDetailsDocument, baseOptions);
      }
export function useUnitDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitDetailsQuery, UnitDetailsQueryVariables>) {
          return Apollo.useLazyQuery<UnitDetailsQuery, UnitDetailsQueryVariables>(UnitDetailsDocument, baseOptions);
        }
export type UnitDetailsQueryHookResult = ReturnType<typeof useUnitDetailsQuery>;
export type UnitDetailsLazyQueryHookResult = ReturnType<typeof useUnitDetailsLazyQuery>;
export type UnitDetailsQueryResult = Apollo.QueryResult<UnitDetailsQuery, UnitDetailsQueryVariables>;
export const UnitsDocument = gql`
    query Units($communityId: ID!) {
  communityUnits(communityId: $communityId) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;

/**
 * __useUnitsQuery__
 *
 * To run a query within a React component, call `useUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUnitsQuery(baseOptions?: Apollo.QueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
        return Apollo.useQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, baseOptions);
      }
export function useUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
          return Apollo.useLazyQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, baseOptions);
        }
export type UnitsQueryHookResult = ReturnType<typeof useUnitsQuery>;
export type UnitsLazyQueryHookResult = ReturnType<typeof useUnitsLazyQuery>;
export type UnitsQueryResult = Apollo.QueryResult<UnitsQuery, UnitsQueryVariables>;
export const UserDetailsDocument = gql`
    query userDetails($userId: ID!) {
  userDetails(userId: $userId) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useUserDetailsQuery__
 *
 * To run a query within a React component, call `useUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDetailsQuery(baseOptions?: Apollo.QueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
        return Apollo.useQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, baseOptions);
      }
export function useUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
          return Apollo.useLazyQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, baseOptions);
        }
export type UserDetailsQueryHookResult = ReturnType<typeof useUserDetailsQuery>;
export type UserDetailsLazyQueryHookResult = ReturnType<typeof useUserDetailsLazyQuery>;
export type UserDetailsQueryResult = Apollo.QueryResult<UserDetailsQuery, UserDetailsQueryVariables>;
export const UsersDocument = gql`
    query Users($communityId: ID!) {
  communityUsers(communityId: $communityId) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;