import { Button } from "@fluentui/react-northstar";
import { navigate } from "@reach/router";
import React from "react";
import { getFormattedName } from "../app-helper";
import {
  Unit,
  UnitBasicDetails
} from "../data/__generated__/client-graphql-types";

interface IUnitNavButtonProps {
  unit?: Unit | UnitBasicDetails | null;
  buttonLook?: boolean;
}
export const UnitNavButton = ({
  unit,
  buttonLook = true,
}: IUnitNavButtonProps): JSX.Element => (
  <Button
    size="small"
    text={!buttonLook}
    content={getFormattedName(unit?.name)}
    onClick={async (event: any) => {
      //TODO: optimiize
      event.stopPropagation();
      await navigate(`/units/${unit?.id}`);
    }}
  />
);
