import gql from "graphql-tag";

export default gql`
  fragment Task on Task {
    id
    title
    communityTaskId
    description
    forUnits
    community
    assignedTo
    status
    isActive
    createdBy
    closedBy
    createdAt
    closedAt
    labels
    attachments
  }
`;
