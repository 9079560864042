import gql from "graphql-tag";

export default gql`
  fragment Address on Address {
    addressLine1
    addressLine2
    zipCode
    city
    state
  }
`;