import { Flex, Text } from "@fluentui/react-northstar";
import { Link } from "@reach/router";
import React from "react";
import { getFormattedName, isValidTaskStatus } from "../app-helper";
import { Task, User } from "../data/__generated__/client-graphql-types";

export const TaskActivityDetails = ({
  task,
  user,
}: {
  task: Task;
  user: User;
}) => {
  if (!isValidTaskStatus(task.status)) return null;
  const statusText = task.status === "open" ? "Opened" : "Closed";
  const dateText =
    task.status === "open"
      ? new Date(task.createdAt).toDateString()
      : task.closedAt && new Date(task.closedAt).toDateString();
  //TODO: show status icon before display text based on the status
  return (
    <Flex
      style={{
        paddingLeft: 20,
        paddingBottom: 6,
        color: "#6e6963",
      }}
    >
      <Text
        timestamp
        style={{
          paddingRight: 3,
          paddingTop: 3,
          fontSize: 11,
        }}
        content={`#${task.communityTaskId} ${statusText} by `}
        size="small"
      />
      <Link
        to={`/residents/${user?.id}`}
        style={{
          color: "brand",
          cursor: "pointer",
        }}
      >
        <Text
          style={{
            fontSize: 11,
            paddingRight: 3,
            cursor: "pointer",
          }}
          content={`${getFormattedName(user?.firstName, user?.lastName)} `}
          size="small"
        />
      </Link>

      <Text
        timestamp
        style={{
          paddingTop: 3,
          fontSize: 11,
        }}
        content={` on ${dateText}`}
        size="small"
      />
    </Flex>
  );
};
