import React from "react";

import {
  useUpdateOwnersMutation,
  User,
  useUnitDetailsQuery,
  UnitDetails,
} from "../data/__generated__/client-graphql-types";
import { CloseIcon, Dialog, Loader } from "@fluentui/react-northstar";
import { units as unitsQuery, users as usersQuery } from "../data/queries";
import { SelectableUsersContainer } from "./SelectableUsersRenderer";
import { map } from "lodash";

interface IUpdateExistingUnitOwnerDialogProps {
  open: boolean;
  onClose: () => void;
  unitId: string;
  communityId: string;
}
export const UpdateExistingUnitOwnerDialog = ({
  open,
  onClose,
  unitId,
  communityId,
}: IUpdateExistingUnitOwnerDialogProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const onCancel = React.useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const [updateOwnersMutation] = useUpdateOwnersMutation();
  const { data, loading } = useUnitDetailsQuery({
    variables: {
      unitId,
    },
  });

  const unitDetails = data?.unitDetails as UnitDetails;
  const onOwnerSelect = React.useCallback(
    async (selectedOwnerRow: User) => {
      const owners = map(unitDetails.owners, "id");
      // TODO: use loading and error
      // disable users who don't occupy any units or tenants anymore
      await updateOwnersMutation({
        variables: {
          unitId,
          newOwnerIds: [...owners, selectedOwnerRow.id],
        },
        refetchQueries: [
          {
            query: unitsQuery,
            variables: {
              communityId,
            },
          },
          {
            query: usersQuery,
            variables: {
              communityId,
            },
          },
        ],
      });
      setIsOpen(false);
      onClose();
    },
    [setIsOpen]
  );
  // making owners tabs as default
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(1);
  const onTabSwitch = React.useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);
  const dialogContent = useDialogContent(
    onOwnerSelect,
    unitDetails,
    activeTabIndex,
    onTabSwitch
  );
  const header =
    activeTabIndex === 0
      ? "Update Ownership from Existing Residents"
      : "Update Ownership from Existing Owners";
  return loading ? (
    <Loader />
  ) : (
      <Dialog
        closeOnOutsideClick={false}
        onCancel={onCancel}
        open={isOpen}
        header={header}
        content={dialogContent}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => onCancel(),
        }}
      />
    );
};

const useDialogContent = (
  confirmDialog: (selectedOwnerRow: User) => void,
  unitDetails: UnitDetails,
  activeTabIndex: number,
  onTabSwitch: (tabIndex: number) => void
) =>
  React.useMemo(() => {
    const doNotShowUserList = map(unitDetails.owners as User[]).concat(
      map(unitDetails.tenants as User[])
    );
    return (
      <SelectableUsersContainer
        onUserSelect={confirmDialog}
        noShowUsersList={doNotShowUserList}
        onTabSwitch={onTabSwitch}
        activeTabIndex={activeTabIndex}
      />
    );
  }, [confirmDialog, unitDetails, activeTabIndex, onTabSwitch]);
