import { Flex, Label, Text } from "@fluentui/react-northstar";
import React from "react";
import { Maybe } from "../data/__generated__/client-graphql-types";

export const TaskLabels = ({
  content,
  icon,
}: {
  content?: Maybe<string>[] | null;
  icon: React.ReactElement;
}) => {
  return (
    <Flex gap="gap.smaller">
      {content?.map((label) => (
        <Label
          content={
            <Text content={label} size="smallest" style={{ fontSize: 10 }} />
          }
          icon={icon}
          circular
          iconPosition="start"
          style={{ paddingRight: 10, cursor: "pointer" }}
        />
      ))}
    </Flex>
  );
};
