import { Flex, Header, Image, Button } from "@fluentui/react-northstar";
import React from "react";

import SomethingWentWrongImage from "./images/illustration-something-went-wrong.svg";
export interface IApplicationErrorProps {
  retryHandler?: () => void;
  errorMessage?: string;
}
export const ApplicationComingSoon: React.FunctionComponent<IApplicationErrorProps> = React.memo<IApplicationErrorProps>(
  ({ retryHandler, errorMessage }) => {
    const imageProps = {
      src: SomethingWentWrongImage,
      id: "illustration",
    };
    return (
      <Flex
        column
        fill
        hAlign="center"
        vAlign="center"
        style={{
          minHeight: "480px",
          maxHeight: "480px",
          width: "auto",
          height: "auto",
          margin: "auto",
        }}
      >
        <Image {...imageProps} />
        <Header
          as="h2"
          content={errorMessage ? errorMessage : "Coming Soon"}
          variables={{
            isLargeBoldHeader: true,
            isCentered: true,
            isToolbarPopupH2: true,
          }}
        />
        {retryHandler && (
          <Button primary content={"Retry"} onClick={retryHandler} />
        )}
      </Flex>
    );
  }
);
