import {
  AcceptIcon,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  EditIcon,
  FilesUploadIcon,
  Flex,
  FlexItemProps,
  Header,
  Label,
  Loader,
  MenuButton,
  MoreIcon,
  NotesIcon,
  SurveyIcon,
  TagIcon,
  TenantPersonalIcon,
  Text,
  TextArea,
  Tooltip,
  WorkOrSchoolIcon,
} from "@fluentui/react-northstar";
import { navigate, RouteComponentProps } from "@reach/router";
import { Dictionary, isEmpty, keyBy, startCase } from "lodash";
import React from "react";
import { getFormattedDate, getFormattedName, getUnitNamesForUnitIds } from "../app-helper";
import {
  Task,
  TaskComment,
  Unit,
  useAddTaskCommentMutation,
  User,
  useTaskCommentsQuery,
  useTaskDetailsByCommunityTaskIdQuery,
  useUnitsQuery,
  useUpdateTaskMutation,
  useUsersQuery,
} from "../data/__generated__/client-graphql-types";
import { useUserContext } from "../UserContext";
import { ApplicationError } from "./ApplicationError";
import { PageBanner } from "./PageBanner";
import { taskComments as taskCommentsQuery } from "../data/queries";

import { TaskActivityDetails } from "./TaskActivityDetails";
import { OpenStatusIcon, ClosedStatusIcon } from "./TaskPreviewCardRenderer";
import tasksQuery from "../data/queries/tasks.query";
import { Tabs } from "./Tabs";
import { TaskAttachmentsContainer } from "./TaskAttachmentsContainer";
import { TaskExpensesContainer } from "./TaskExpensesContainer";
import { TaskLabels } from "./TaskLabels";

const variables = { isUnitName: true };

type FlexItemStyleProp = Pick<FlexItemProps, "styles">;
interface ITaskMenuProps extends FlexItemStyleProp {
  task: Task;
  user: User;
}
interface ITaskDetailsProps extends RouteComponentProps {
  communityTaskId?: string;
}

const TaskDetailsContainer = (props: ITaskDetailsProps) => {
  const { communityTaskId = "" } = props;
  const { currentCommunityId } = useUserContext();
  const {
    data: taskDetailsData,
    loading: taskDetailsLoading,
    error: taskDetailsError,
  } = useTaskDetailsByCommunityTaskIdQuery({
    variables: {
      communityTaskId: parseInt(communityTaskId), //TODO: error handling for invalid non-Integr Id
      communityId: currentCommunityId ?? "",
    },
  });
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useUsersQuery({
    variables: {
      communityId: currentCommunityId ?? "",
    },
  });
  const loading = taskDetailsLoading || usersLoading;
  const error = !!taskDetailsError || !!usersError;

  return loading ? (
    <Loader />
  ) : !!error ? (
    <ApplicationError errorMessage="This task no longer exists!" />
  ) : (
        <TaskDetailsRenderer
          task={taskDetailsData?.taskDetailsByCommunityTaskId as Task}
          usersDirectory={keyBy(usersData?.communityUsers as User[], "id")}
        />
      );
};

const CloseTaskIcon = React.memo(() => {
  return (
    <Tooltip
      content="Close Task"
      trigger={
        <AcceptIcon
          size="small"
          styles={{
            color: "green",
          }}
        />
      }
    />
  );
});

const ReopenTaskIcon = React.memo(() => {
  return (
    <Tooltip
      content="Reopen Task"
      trigger={
        <SurveyIcon
          size="small"
          styles={{
            color: "orange",
          }}
        />
      }
    />
  );
});

const TaskDetailsRenderer = ({
  task,
  usersDirectory,
}: {
  task: Task;
  usersDirectory: Dictionary<User>;
}) => {
  const user = !!task.closedBy
    ? usersDirectory[task.closedBy]
    : usersDirectory[task.createdBy];

  const tabsTitles: string[] = ["Conversation", "Attachments", "Expenses"];
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const onTabSwitch = React.useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);
  return (
    <>
      <PageBanner
        title={"Task Details"}
        icon={<NotesIcon size="large" style={{ padding: 10 }} />}
      />
      <TaskHeader task={task} user={user} />
      <Tabs
        defaultActiveIndex={0}
        tabTitles={tabsTitles}
        onTabSwitch={onTabSwitch}
        pointing={false}
      />
      {activeTabIndex === 0 ? (
        <TaskCommentsContainer task={task} usersDirectory={usersDirectory} />
      ) : activeTabIndex === 1 ? (
        <TaskAttachmentsContainer task={task} usersDirectory={usersDirectory} />
      ) : (
            <TaskExpensesContainer task={task} usersDirectory={usersDirectory} />
          )}
    </>
  );
};

const TaskHeader = ({ task, user }: { task: Task; user: User }) => {
  const BlankSpace = <span>&nbsp;&nbsp;</span>;
  const { currentUser, currentCommunityId = "" } = useUserContext();
  const [updateTaskMutation] = useUpdateTaskMutation();
  const {
    data,
    loading: unitsLoading,
    error: unitsError,
  } = useUnitsQuery({
    variables: {
      communityId: currentCommunityId,
    },
  });
  const units = data?.communityUnits as Unit[];
  const unitNames =
    task && !isEmpty(task.forUnits)
      ? getUnitNamesForUnitIds(task?.forUnits as string[], units)
      : [];
  const onTitleEdit = React.useCallback(async () => {
    const title = window.prompt(`Pleas Enter the title`, task.title);
    if (!title) {
      return;
    }

    await updateTaskMutation({
      variables: {
        input: {
          id: task.id,
          title
        }
      },
      refetchQueries: [
        {
          query: tasksQuery,
          variables: {
            communityId: currentCommunityId,
          },
        },
      ],
    });
  }, [task.title, updateTaskMutation]);
  return (
    <Flex style={{ marginBottom: 16, marginLeft: 20 }} column>
      <Flex>
        <Header
          as="h3"
          content={<><Text content={`${task.title} `} /><EditIcon onClick={onTitleEdit} style={{ color: "grey", paddingBottom: 5 }} /></>}
        />
        {BlankSpace}
        <Header
          as="h3"
          style={{ color: "grey" }}
          content={" #" + task.communityTaskId}
        />
        <Flex.Item push>
          <TaskMenu task={task} user={currentUser as User} />
        </Flex.Item>
      </Flex>
      <Flex>
        <Label
          content={startCase(task.status)}
          color={task.status === "open" ? "red" : "green"}
          icon={
            task.status === "open" ? (
              <OpenStatusIcon color="white" />
            ) : (
                <ClosedStatusIcon color="white" />
              )
          }
          circular
          iconPosition="start"
          style={{ padding: 10, color: "white", height: 15 }}
        />
        <Box style={{ paddingTop: 3 }}>
          <TaskActivityDetails task={task} user={user} />

        </Box>
        <Flex
          style={{
            alignItems: "right",
            justifyContent: "right",
            marginLeft: 20,
            marginTop: 8,
          }}
        >
          {unitNames &&
            unitNames.map((unitName) => (
              <Button
                as="a"
                iconOnly
                content={
                  <Label
                    content={
                      <Text
                        content={unitName}
                        size="smallest"
                        style={{ fontSize: 10 }}
                      />
                    }
                    variables={variables}
                    icon={<TenantPersonalIcon style={{ height: 10 }} />}
                    iconPosition="start"
                    style={{
                      padding: 6,
                    }}
                  />
                }
                size="smallest"
                onClick={async () =>
                  await navigate("/units/" + task.forUnits?.[0])
                }
                text
                style={{
                  cursor: "pointer",
                  paddingBottom: 10,
                  paddingRight: 6,
                }}
              />
            ))}
          <TaskLabels
            content={task.labels}
            icon={
              <TagIcon
                outline
                rotate={-110}
                size="smallest"
                style={{ height: 10 }}
              />
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const TaskMenu: React.FC<ITaskMenuProps> = ({
  task,
  user,
  ...rest
}: ITaskMenuProps) => {
  const [
    updateTask,
    { loading: updateMutationLoading },
  ] = useUpdateTaskMutation();
  const { currentCommunityId: communityId = "" } = useUserContext();

  const onTaskClose = React.useCallback(
    (task: Task) => {
      const {
        __typename,
        createdBy,
        communityTaskId,
        community,
        ...rest
      } = task;
      updateTask({
        variables: {
          input: {
            id: task.id,
            closedBy: user?.id,
            status: "closed",
            closedAt: new Date().toUTCString(),
          },
        },
        refetchQueries: [
          {
            query: tasksQuery,
            variables: {
              communityId,
            },
          },
        ],
      });
    },
    [updateTask, communityId]
  );

  const onTaskReopen = React.useCallback(
    (task: Task) => {
      const isConfirm = window.confirm("Would you like to reopen the task?");

      // TODO: use loading and error
      // disable users who don't occupy any units or tenants anymore
      if (!isConfirm) {
        return;
      }
      const {
        __typename,
        createdBy,
        communityTaskId,
        community,
        ...rest
      } = task;
      updateTask({
        variables: {
          input: {
            id: task.id,
            closedBy: null,
            status: "open",
            closedAt: null,
          },
        },
        refetchQueries: [
          {
            query: tasksQuery,
            variables: {
              communityId,
            },
          },
        ],
      });
    },
    [updateTask, communityId]
  );

  const taskStatusActionItem =
    task.status === "open"
      ? {
        key: "0",
        content: (
          <Button
            text
            size="small"
            content="Mark Done"
            icon={
              <AcceptIcon
                styles={{
                  color: "green",
                }}
              />
            }
            onClick={() => onTaskClose(task)}
          />
        ),
      }
      : {
        key: "0",
        content: (
          <Button
            text
            size="small"
            content="Re-Open"
            icon={
              <SurveyIcon
                styles={{
                  color: "orange",
                }}
              />
            }
            onClick={() => onTaskReopen(task)}
          />
        ),
      };
  return (
    <MenuButton
      {...rest}
      trigger={<Button icon={<MoreIcon />} title="Task Menu" />}
      style={{ padding: 10 }}
      menu={[
        taskStatusActionItem,
        {
          key: "1",
          content: (
            <Button
              text
              size="small"
              content="Upload Attachment"
              icon={<FilesUploadIcon />}
            // onClick={() => launchCreateNewUnitDialog()}
            />
          ),
        },
        {
          key: "1",
          content: (
            <Button
              text
              size="small"
              content="Add Expense"
              icon={<WorkOrSchoolIcon />}
            // onClick={() => launchCreateNewUnitDialog()}
            />
          ),
        },
      ]}
    />
  );
};
const TaskCommentsContainer = ({
  task,
  usersDirectory,
}: {
  task: Task;
  usersDirectory: Dictionary<User>;
}) => {
  const { data, loading, error } = useTaskCommentsQuery({
    variables: {
      taskId: task.id,
    },
  });
  const taskComments = data?.taskComments as TaskComment[];

  return loading ? (
    <Loader />
  ) : !!error ? (
    <ApplicationError errorMessage="Unable to load comment on this task" />
  ) : (
        <>
          <TaskDescriptionRenderer
            taskId={task.id}
            taskDescription={task.description ?? ""}
          />
          <TastCommentsRenderer
            taskComments={taskComments}
            usersDirectory={usersDirectory}
          />
          <PostNewComment taskId={task.id} />
        </>
      );
};

const PostNewComment = ({ taskId }: { taskId: string }) => {
  const [
    addTaskComment,
    { loading: updateMutationLoading },
  ] = useAddTaskCommentMutation();

  const [
    actionButtonVisibility,
    setActionButtonVisibility,
  ] = React.useState<boolean>(false);

  const [
    actionButtonGroupVisibility,
    setActionButtonGroupVisibility,
  ] = React.useState<boolean>(false);

  const [taskComment, setTaskComment] = React.useState<string>("");
  const { currentUser } = useUserContext();
  const currentUserId = currentUser?.id ?? "";

  const onPostTaskComment = React.useCallback(async () => {
    await addTaskComment({
      variables: {
        input: {
          commentedBy: currentUserId, //TODO: IMP use login user id
          comment: taskComment,
          taskId,
        },
      },
      refetchQueries: [
        {
          query: taskCommentsQuery,
          variables: {
            taskId,
          },
        },
      ],
    });

    setTaskComment("");
    setActionButtonVisibility(false);
  }, [taskComment, taskId]);

  const onReset = React.useCallback(() => {
    setTaskComment("");
    setActionButtonVisibility(false);
  }, []);

  const onInputChange = React.useCallback(
    (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setTaskComment(input.value);
      if (!actionButtonGroupVisibility) {
        setActionButtonGroupVisibility(true);
      }
      if (isEmpty(input.value)) {
        setActionButtonVisibility(false);
      } else {
        setActionButtonVisibility(true);
      }
    },
    [setTaskComment]
  );

  return (
    <>
      <Divider content="Post New Comment" />
      <Flex column>
        <TextArea
          placeholder="Enter your comment here..."
          fluid
          resize="vertical"
          style={{ height: 75, marginBottom: 10 }}
          value={taskComment}
          onChange={onInputChange}
        />
        <Flex hAlign="end">
          {actionButtonGroupVisibility && (
            <CommentActionButtons
              onPostTaskComment={onPostTaskComment}
              onReset={onReset}
              visibility={actionButtonVisibility}
              isPosting={updateMutationLoading}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

const TaskCommentRenderer = ({
  taskComment,
  user,
  index,
}: {
  taskComment: TaskComment;
  user?: User;
  index: number;
}) => {
  const { currentUser } = useUserContext();
  const name = getFormattedName(user?.firstName, user?.lastName);
  const bgColor = index % 2 !== 0 ? "white" : "#8a8db614";
  return (
    <Card
      compact
      expandable
      fluid
      size="large"
      style={{ background: bgColor, marginTop: 12 }}
    >
      {currentUser?.id === taskComment.commentedBy && (
        <Card.TopControls>
          {/* <Button icon={<EditIcon />} iconOnly text title="Edit" /> */}
        </Card.TopControls>
      )}

      <CardHeader style={{ paddingTop: 10, display: "block" }}>
        <Flex gap="gap.small">
          <Avatar name={`${name}`} style={{ padding: 4 }} size="smallest" />
          <Flex column>
            <Text content={`${name}`} color="brand" weight="semibold" />
            <Text
              style={{
                fontSize: 8,
              }}
              content={`Posted on ${getFormattedDate(
                new Date(taskComment.createdAt)
              )}`}
              timestamp
              size="small"
            />{" "}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Box style={{ paddingLeft: 24 }}>{taskComment.comment}</Box>
      </CardBody>
    </Card>
  );
};

export const TastCommentsRenderer = ({
  taskComments,
  usersDirectory,
}: {
  taskComments: TaskComment[];
  usersDirectory: Dictionary<User>;
}) => {
  return isEmpty(taskComments) ? null : (
    <Flex column>
      <Divider content="Comments" />
      {taskComments.map((taskComment, index) => (
        <TaskCommentRenderer
          index={index}
          taskComment={taskComment}
          user={usersDirectory[taskComment.commentedBy]}
        />
      ))}
    </Flex>
  );
};

const DescriptionActionButtons = ({
  onSaveDescription,
  isSaving,
  onDiscardDescription,
}: {
  onSaveDescription: () => void;
  onDiscardDescription: () => void;
  isSaving: boolean;
}) => (
  <Button.Group
    buttons={[
      {
        key: "save",
        primary: true,
        title: isSaving ? "Saving" : "Save",
        content: isSaving ? "Saving" : "Save",
        onClick: onSaveDescription,
      },
      {
        key: "discard",
        title: "Discard Changes",
        content: "Discard",
        onClick: onDiscardDescription,
      },
    ]}
  />
);

const CommentActionButtons = ({
  onPostTaskComment,
  visibility,
  onReset,
  isPosting,
}: {
  onPostTaskComment: () => void;
  onReset: () => void;
  visibility: boolean;
  isPosting: boolean;
}) => (
  <Button.Group
    buttons={[
      {
        key: "post",
        primary: true,
        title: isPosting ? "Posting" : "Post",
        content: isPosting ? "Posting" : "Post",
        onClick: onPostTaskComment,
        disabled: !visibility,
      },
      {
        key: "clear",
        title: "Clear",
        content: "Clear",
        disabled: !visibility,
        onClick: onReset,
      },
    ]}
  />
);
export const TaskDetails = TaskDetailsContainer;

function TaskDescriptionRenderer({
  taskId,
  taskDescription,
}: {
  taskId: string;
  taskDescription: string;
}) {
  const [updateTask, { loading: mutationLoading }] = useUpdateTaskMutation();
  const [
    updatedTaskDescription,
    setUpdatedTaskDescription,
  ] = React.useState<string>(taskDescription ?? "");
  const [
    actionButtonGroupVisibility,
    setActionButtonGroupVisibility,
  ] = React.useState<boolean>(false);

  const onSaveDescription = React.useCallback(async () => {
    await updateTask({
      variables: {
        input: {
          id: taskId,
          description: updatedTaskDescription,
        },
      },
    });
    setActionButtonGroupVisibility(false);
  }, [updatedTaskDescription]);

  const onDiscardDescription = React.useCallback(() => {
    setUpdatedTaskDescription(taskDescription ?? "");
  }, [updatedTaskDescription]);
  const onInputChange = React.useCallback(
    (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setUpdatedTaskDescription(input.value);
      if (updatedTaskDescription !== input.value) {
        setActionButtonGroupVisibility(true);
      } else {
        setActionButtonGroupVisibility(false);
      }
    },
    [setUpdatedTaskDescription, setActionButtonGroupVisibility]
  );
  return (
    <>
      {" "}
      <Divider content="Description" />
      <Flex column>
        <TextArea
          placeholder="Enter task description here..."
          fluid
          resize="vertical"
          style={{
            height: 75,
            marginBottom: 10,
          }}
          value={updatedTaskDescription}
          onChange={onInputChange}
        />
        <Flex hAlign="end">
          {actionButtonGroupVisibility && (
            <DescriptionActionButtons
              onSaveDescription={onSaveDescription}
              onDiscardDescription={onDiscardDescription}
              isSaving={mutationLoading}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}
