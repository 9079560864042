import {
  Button,
  Card,
  CloseIcon,
  Divider,
  EmailIcon,
  Flex,
  Tooltip,
  UserPhoneIcon,
} from "@fluentui/react-northstar";
import React from "react";

import {
  Maybe,
  UserBasicDetails,
} from "../data/__generated__/client-graphql-types";
import { Email } from "./Email";
import { PhoneNumber } from "./PhoneNumber";
import { ResidentNavButton } from "./ResidentNavButton";

interface IContactDetailsProps {
  title?: string;
  users?: UserBasicDetails[];
  removeButtonTootTipText: string;
  onRemove: (userId?: string) => void;
}

export const UserDetailsPreviewCardRenderer = ({
  title,
  users,
  removeButtonTootTipText,
  onRemove,
}: IContactDetailsProps) => {
  return (
    <>
      <Card aria-roledescription="contact details card" elevated fluid>
        <Card.Header>
          <Flex column hAlign="center">
            {/* {title && <Text content={title} size="medium" weight="bold" />} */}
            {title && (
              <Divider content={title} size={2} important color="black" />
            )}
          </Flex>
        </Card.Header>
        <Card.Body>
          <Flex>
            {users?.map((user, index) => (
              <>
                <Flex gap="gap.small" column>
                  <UserNameWithCloseButton
                    user={user}
                    tooltipText={removeButtonTootTipText}
                    onCloseButtonClick={onRemove}
                  />
                  <Flex gap="gap.small">
                    <UserPhoneIcon outline />
                    <PhoneNumber
                      number={user.contactDetails.phone}
                      isLinked={true}
                    />
                  </Flex>
                  <Flex gap="gap.small">
                    <EmailIcon outline />
                    <Email
                      address={user.contactDetails.email}
                      isLinked={true}
                    />
                  </Flex>
                </Flex>
                {index + 1 !== users?.length && (
                  <Divider vertical color={"black"} style={{ padding: 10 }} />
                )}
              </>
            ))}
          </Flex>
        </Card.Body>
      </Card>
    </>
  );
};

export const UserNameWithCloseButton = ({
  user,
  tooltipText,
  onCloseButtonClick,
}: {
  user: Maybe<UserBasicDetails>;
  tooltipText: string;
  onCloseButtonClick: (userId?: string) => void;
}): JSX.Element => {
  return (
    <Button
      fluid
      text
      content={<ResidentNavButton user={user} />}
      iconPosition="after"
      color="white"
      icon={
        <Tooltip
          trigger={
            <Button
              size="small"
              icon={<CloseIcon />}
              text
              iconOnly
              title="Close"
              onClick={() => onCloseButtonClick(user?.id)}
            />
          }
          content={tooltipText}
        />
      }
    />
  );
};
