import {
  ComponentSlotStylesPrepared,
  ICSSInJSStyle,
  TeamsThemeStylesProps,
} from "@fluentui/react-northstar";

export const TableCellStyles: ComponentSlotStylesPrepared<
  NonNullable<TeamsThemeStylesProps["TableCell"]>,
  any
> = {
  root: ({
    props: {},
    variables: { isFullRowCell },
    theme: {
      siteVariables: { colors, borderWidth },
    },
  }) => {
    return {
      ...(isFullRowCell && {
        width: "100%",
      }),
    };
  },
  content: ({ variables: { isFullRowCell } }: ICSSInJSStyle) => {
    return {
      ...(isFullRowCell && {
        width: "100%",
      }),
    };
  },
};
