import gql from "graphql-tag";
import { address, contactDetails } from ".";

export default gql`
  fragment User on User {
    id
    firstName
    lastName
    unitsOwned {
      id
      name
    }
    contactDetails {
      ...ContactDetails
    }
    address {
      ...Address
    }
    boardMemberDetails{
      title
    }
    roles
    isActive
    loginEnabled
  }
  ${contactDetails}
  ${address}
`;
