import React from "react";
import { Formik } from "formik";

import {
  UnitDetails,
  useAddNewUnitTenantMutation,
  useUnitDetailsQuery,
} from "../data/__generated__/client-graphql-types";
import { CloseIcon, Dialog, Loader } from "@fluentui/react-northstar";
import { units as unitsQuery, users as usersQuery } from "../data/queries";
import {
  getUserInputFromFormValues,
  IFormUser,
  residentFormValidation,
} from "./modals-helper";
import { NewResidentForm } from "./NewResidentForm";

interface ICreateNewTenantDialogProps {
  open: boolean;
  onClose: () => void;
  unitId: string;
  communityId: string;
}

const initialFormValues: IFormUser = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

const initialFormErrors: IFormUser = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

export const CreateNewTenantDialog = ({
  open,
  onClose,
  unitId,
  communityId,
}: ICreateNewTenantDialogProps) => {
  console.log("DEBUG CommunityId", communityId);
  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const onCancel = React.useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const onConfirm = React.useCallback(async () => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const dialogContent = useDialogContent(unitId, onConfirm, onCancel);
  return (
    <Dialog
      closeOnOutsideClick={false}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={isOpen}
      header="New Tenant"
      content={dialogContent}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => onCancel(),
      }}
    />
  );
};

const useDialogContent = (
  unitId: string,
  confirmDialog: () => void,
  cancelDialog: () => void
) => {
  const [
    createNewTenant,
    { loading: mutationLoading },
  ] = useAddNewUnitTenantMutation();
  const { data, loading } = useUnitDetailsQuery({
    variables: {
      unitId,
    },
  });

  const unitDetails = data?.unitDetails as UnitDetails;
  //TODO: address error case for both query and mutation error
  return loading || mutationLoading ? (
    <Loader />
  ) : (
      <Formik
        initialValues={initialFormValues}
        initialErrors={initialFormErrors}
        validate={residentFormValidation}
        onSubmit={(values, { setSubmitting }) => {
          // TODO: use loading and error
          createNewTenant({
            variables: {
              unitId,
              input: getUserInputFromFormValues(values, unitDetails),
            },
            refetchQueries: [
              {
                query: unitsQuery,
                variables: {
                  communityId: unitDetails.community,
                },
              },
              {
                query: usersQuery,
                variables: {
                  communityId: unitDetails.community,
                },
              },
            ],
          }).then(() => {
            console.log("DEBUG: done adding tenant");
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
            //TODO: add user mutation
            // update tenant mutation
            confirmDialog();
          });
        }}
        render={({
          handleSubmit,
          handleChange,
          errors,
          handleBlur,
          touched,
          values,
          isSubmitting,
        }) => {
          return isSubmitting ? (
            <Loader />
          ) : (
              <NewResidentForm
                handleSubmit={handleSubmit}
                cancelDialog={cancelDialog}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                values={values}
              />
            );
        }}
      />
    );
};
