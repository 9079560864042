import gql from "graphql-tag";
import { address } from ".";

export default gql`
  fragment Unit on Unit {
    id
    name
    address {
      ...Address
    }
    owners 
    tenants
    occupancyType
    isActive
  }
  ${address}
`;
