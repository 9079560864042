import { isNil } from "lodash";
import React from "react";
import { Menu } from "@fluentui/react-northstar";

export interface ITabsProps {
  tabTitles: string[];
  onTabSwitch: (tabIndex: number) => void;
  defaultActiveIndex?: number;
  underlined?: boolean;
  pointing: boolean;
}

export const Tabs = React.memo(
  ({
    tabTitles,
    onTabSwitch,
    defaultActiveIndex,
    underlined,
    pointing,
  }: ITabsProps) => {
    const [activeIndex, setActiveIndex] = React.useState(
      isNil(defaultActiveIndex) ? 0 : defaultActiveIndex
    );

    if (isNil(underlined)) {
      underlined = true;
    }

    const items = tabTitles.map((title, index) => ({
      key: title,
      content: title,
      onClick: () => {
        setActiveIndex(index);
        onTabSwitch(index);
        console.log("asdasdasdas");
      },
    }));

    return (
      <Menu
        defaultActiveIndex={defaultActiveIndex}
        activeIndex={activeIndex}
        items={items}
        pointing={pointing}
        underlined={underlined}
        primary
      />
    );
  }
);
