import { ButtonStyles } from "./fluentui/buttonStyles";
import { FlexStyles } from "./fluentui/flexStyles";
import { TextStyles } from "./fluentui/textStyles";
import { BoxStyles } from "./fluentui/boxStyles";
import { TableCellStyles } from "./fluentui/tableCellStyles";

const themeOverrides = {
  componentStyles: {
    Button: ButtonStyles,
    Flex: FlexStyles,
    Text: TextStyles,
    Box: BoxStyles,
    TableCell: TableCellStyles,
  },
};

export default themeOverrides;
