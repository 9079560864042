import gql from 'graphql-tag';
import * as fragments from '../fragments';

export default gql`
  query Units($communityId: ID!) {
    communityUnits(communityId: $communityId) {
      ...Unit
    }
  }
  ${fragments.unit}
`;