import { navigate, useLocation } from "@reach/router";
import React from "react";
import { isEmpty } from "lodash";
import { useUserContext } from "../UserContext";
import { useApolloClient } from "@apollo/client";
import {
  CommunityQuery,
  CommunityQueryVariables,
  CommunityDocument,
  Community,
  useUnitsQuery,
  UnitsDocument,
  UnitsQuery,
  UnitsQueryVariables,
  UsersDocument,
  UsersQuery,
  UsersQueryVariables,
} from "../data/__generated__/client-graphql-types";
import { signoutAndClearContext } from "../app-helper";
export const useSelectCommunityEffect = (
  setReload: React.Dispatch<React.SetStateAction<boolean>>,
  reload: boolean
) => {
  const userContext = useUserContext();
  const { currentCommunityId, currentUser } = useUserContext();
  const client = useApolloClient();
  const location = window.location;

  React.useEffect(() => {
    const navigateToPath = async (path: string) => {
      await navigate(path);
    };

    const fetchUnits = async (communityId: string) => {
      await client.query<UnitsQuery, UnitsQueryVariables>({
        query: UnitsDocument,
        variables: {
          communityId,
        },
      });
    };

    const fetchUsers = async (communityId: string) => {
      await client.query<UsersQuery, UsersQueryVariables>({
        query: UsersDocument,
        variables: {
          communityId,
        },
      });
    };

    const getCommunityAndUpdateContext = async (communityId: string) => {
      const { data } = await client.query<
        CommunityQuery,
        CommunityQueryVariables
      >({
        query: CommunityDocument,
        variables: {
          communityId,
        },
      });
      userContext.currentCommunity = data?.community as Community;
    };

    if (!isUserContextApplicablePath()) {
      return;
    }

    console.log("Location", location);

    if (isEmpty(currentUser)) {
      navigateToPath("/");
    } else if (isEmpty(userContext.currentCommunity)) {

      Promise.all([
        getCommunityAndUpdateContext(currentCommunityId ?? ""),
        fetchUsers(currentCommunityId ?? ""),
        fetchUnits(currentCommunityId ?? ""),
      ]).then(() => {
        setReload(!reload);
      }).catch(async (err) => {
        console.log(err); // some coding error in handling happened
        await signoutAndClearContext(userContext, client, setReload, reload);
      });
    }
  }, [setReload, reload]);
};

/**
 * This method returns true if user context is applicable to current locaiton. Current following user context doesn't apply for current paths:
 * /login/signin
 */
const isUserContextApplicablePath = () => {
  const parser = document.createElement("a");
  parser.href = window.location.href;
  if (window.location.pathname.toLowerCase().startsWith("/login/setup")) {
    return false;
  }
  return true;
};
