import { Box, Button, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { UsePaginationInstanceProps } from "react-table";
import { User } from "../data/__generated__/client-graphql-types";

const defaultPageSizes = [10, 20, 30, 40, 50];
export const TablePaginationRenderer = <TItem extends {}>({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
  pageSizeOptions,
}: UsePaginationInstanceProps<TItem> & {
  pageIndex: number;
  pageSize: number;
  pageSizeOptions?: number[];
}) => {
  return (
    <Flex hAlign="center">
      <Box style={{ paddingTop: 6 }}>
        <Button text onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        <Button text onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </Button>{" "}
        <Button text onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </Button>{" "}
        <Button
          text
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>{" "}
      </Box>
      <Box style={{ paddingTop: 10 }}>
        <Text
          content={`Page: ${pageIndex + 1} of ${pageOptions.length}`}
          style={{ paddingRight: 5 }}
        />
        {/* 
      // TODO: Should we support entering page number
      <span>
        | Go to page:{" "}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "100px" }}
        />
      </span>{" "} */}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {(pageSizeOptions ?? defaultPageSizes).map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </Box>
    </Flex>
  );
};
