import gql from "graphql-tag";
import * as fragments from "../fragments";

export default gql`
  query community($communityId: ID!) {
    community(communityId: $communityId) {
      ...Community
    }
  }
  ${fragments.community}
`;
