import { ApolloCache, Resolvers } from "@apollo/client";
import gql from "graphql-tag";
import { includes, isEmpty } from "lodash";
import { units } from "./queries";
import { Unit, UnitsQuery } from "./__generated__/client-graphql-types";

// export const typeDefs = gql`
//   query GetUsers {
//     user {
//       _id
//       __typename
//       name
//       surname
//       email
//       phone
//     }
//   }
// `;

type ResolverFn = (
  parent: any,
  args: any,
  { cache }: { cache: ApolloCache<any> }
) => any;

interface ResolverMap {
  [field: string]: ResolverFn;
}

interface AppResolvers extends Resolvers {
  Query: ResolverMap;
}

export const resolvers: AppResolvers = {
  Query: {
    tenantUnit: (
      _,
      { communityId, userId }: { communityId: string; userId: string },
      { cache }
    ): Unit | null => {
      const queryResult = cache.readQuery<UnitsQuery["communityUnits"]>({
        query: units,
      });
      if (!isEmpty(queryResult)) {
        const queryUnits = queryResult ?? [];
        for (const unit of queryUnits) {
          if (!isEmpty(unit?.tenants) && includes(unit?.tenants, userId)) {
            return unit as Unit; // TODO: see a way to avoid force casting IMPORTANT
          }
        }
      }
      return null;
    },
  },
};
