import React from "react";
import {
  User,
  UserBasicDetails,
} from "../data/__generated__/client-graphql-types";
import { navigate } from "@reach/router";
import { Button } from "@fluentui/react-northstar";
import { getFormattedName } from "../app-helper";

interface IResidentNavButtonProps {
  user?: User | UserBasicDetails | null;
  buttonLook?: boolean;
}
export const ResidentNavButton = ({
  user,
  buttonLook = true,
}: IResidentNavButtonProps): JSX.Element => (
  <Button
    size="small"
    text={!buttonLook}
    content={getFormattedName(user?.firstName, user?.lastName)}
    onClick={async (event: any) => {
      //TODO: optimiize
      event.stopPropagation();
      await navigate(`/residents/${user?.id}`);
    }}
  />
);
