import React from "react";
import { Formik } from "formik";

import {
  useCreateTaskMutation,
  Community,
  Unit,
} from "../data/__generated__/client-graphql-types";
import { CloseIcon, Dialog, Loader } from "@fluentui/react-northstar";
import {
  getTaskInputFromFormValues,
  IFormTask,
  taskFormValidation,
} from "./modals-helper";
import { useApolloClient } from "@apollo/client";
import { NewTaskForm } from "./NewTaskForm";
import { useUserContext } from "../UserContext";
import { getUnits } from "../app-helper";
import { tasks as tasksQuery } from "../data/queries";
import { navigate } from "@reach/router";

interface ICreateNewTaskDialogProps {
  open: boolean;
  onClose: () => void;
  communityId: string;
}
export const CreateNewTaskDialog = ({
  open,
  onClose,
  communityId,
}: ICreateNewTaskDialogProps) => {
  console.log("DEBUG CommunityId", communityId);
  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const onCancel = React.useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const onConfirm = React.useCallback(async () => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const dialogContent = useDialogContent(onConfirm, onCancel);
  return (
    <Dialog
      closeOnOutsideClick={false}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={isOpen}
      header="New Task"
      content={dialogContent}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => onCancel(),
      }}
    />
  );
};

const intialFormErrors: IFormTask = {
  title: "",
  description: "",
  forUnits: [],
  assignedTo: "",
  createdBy: "",
  labels: [],
};

const intialFormValues: IFormTask = {
  title: "",
  description: "",
  forUnits: [],
  assignedTo: "",
  createdBy: "",
  labels: [],
};

const useTaskFormValidation = () => {
  // convert to lowercase
  return React.useCallback(
    (formTask: IFormTask) => taskFormValidation(formTask),
    []
  );
};
const useDialogContent = (
  confirmDialog: () => void,
  cancelDialog: () => void
) => {
  const [createTask, { loading: mutationLoading }] = useCreateTaskMutation();
  const client = useApolloClient();
  const {
    currentCommunityId,
    currentCommunity,
    currentUser,
  } = useUserContext();
  const currentUserId = currentUser?.id;
  const units: Array<Unit> = getUnits(client, currentCommunityId);

  const formValidation = useTaskFormValidation();

  //TODO: avoid forecasting
  //TODO: address error case for mutation error
  return mutationLoading ? (
    <Loader />
  ) : (
      <Formik<IFormTask>
        initialValues={intialFormValues}
        initialErrors={intialFormErrors as any}
        validate={formValidation}
        onSubmit={(values, { setSubmitting }) => {
          values.createdBy = currentUserId;
          // TODO: use loading and error
          createTask({
            variables: {
              input: getTaskInputFromFormValues(
                values,
                currentCommunity as Community,
                units
              ),
            },
            refetchQueries: [
              {
                query: tasksQuery,
                variables: {
                  communityId: currentCommunityId ?? "",
                },
              },
            ],
          }).then(async ({ data }: any) => {
            const {
              createTask: { communityTaskId },
            } = data;
            await navigate("/tasks/" + communityTaskId);
            setSubmitting(false);
            confirmDialog();
          });
        }}
        render={({
          handleSubmit,
          handleChange,
          errors,
          handleBlur,
          touched,
          values,
          isSubmitting,
          setTouched,
          setFieldValue,
        }) => {
          return isSubmitting ? (
            <Loader />
          ) : (
              <NewTaskForm
                handleSubmit={handleSubmit}
                cancelDialog={cancelDialog}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                values={values}
                setTouched={setTouched}
                setFieldValue={setFieldValue}
              />
            );
        }}
      />
    );
};
