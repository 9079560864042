import { omit, isNumber, filter, isEmpty, some } from "lodash";
import {
  getFormattedName,
  getUnitIdsForUnitNames,
  isValidEmail,
  isValidZipCode,
} from "../app-helper";
import { unit, unitDetails } from "../data/fragments";
import {
  UnitDetails,
  AddressInput,
  UserInput,
  UnitInput,
  Community,
  NewTaskInput,
  Unit,
} from "../data/__generated__/client-graphql-types";

/**
 * Interface for unit input form
 */
export interface IFormUnit {
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  zipCode?: string;
  city?: string;
  state?: string;
}

/**
 * Interface for user input form
 */
export interface IFormUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

/**
 * Interface for task input form
 */
export interface IFormTask {
  title?: string;
  description?: string;
  forUnits?: string[];
  assignedTo?: string;
  createdBy?: string;
  labels?: string[];
}

/**
 *
 * @param formUser This method does validation for the Resident Form
 */
export const residentFormValidation = (formUser: IFormUser) => {
  const errors: IFormUser = {};
  if (!formUser.firstName) {
    errors.firstName = "Required";
  }
  if (formUser.firstName && formUser.firstName.length < 2) {
    errors.firstName = "Too small";
  }

  if (!formUser.lastName) {
    errors.lastName = "Required";
  }
  if (formUser.lastName && formUser.lastName.length < 2) {
    errors.lastName = "Too small";
  }

  if (!formUser.email) {
    errors.email = "Required";
  }
  if (formUser.email && formUser.email.length < 4) {
    errors.email = "Too small";
  }
  if (formUser.email && !isValidEmail(formUser.email)) {
    errors.email = "Invalid Email";
  }
  console.log("Form validate issue", errors);
  return errors;
};

/**
 *
 * @param formTask This method does validation for the Task Form
 */
export const taskFormValidation = (formTask: IFormTask) => {
  const errors: IFormTask = {};
  if (!formTask.title) {
    errors.title = "Required";
  }
  if (formTask.title && formTask.title.length < 5) {
    errors.title = "Too small";
  }

  console.log("Form validate issue", errors);
  return errors;
};

/**
 *
 * @param formUser This method does validation for the Resident Form
 */
export const unitFormValidation = (
  formUnit: IFormUnit,
  existingUnitNames: string[]
) => {
  const errors: IFormUnit = {};
  if (isEmpty(formUnit.name)) {
    errors.name = "Required";
  }

  //TODO: check if the unit name already exists
  if (formUnit.name && (isNumber(formUnit.name) || formUnit.name.length < 2)) {
    errors.name = "Invalid Name. Ex: Unit#1 or Apt 1";
  } else if (
    formUnit.name &&
    isUnitNameAlreadyExists(existingUnitNames, formUnit)
  ) {
    errors.name = "Unit Name Already Exists";
  }

  if (isEmpty(formUnit.addressLine1)) {
    errors.addressLine1 = "Required";
  }

  if (formUnit.addressLine1 && formUnit.addressLine1.length < 5) {
    errors.addressLine1 = "Too small";
  }

  if (isEmpty(formUnit.city)) {
    errors.city = "Required";
  }

  if (formUnit.city && formUnit.city.length < 3) {
    errors.city = "Too small";
  }

  if (isEmpty(formUnit.state)) {
    errors.state = "Required";
  }

  if (!formUnit.zipCode) {
    errors.zipCode = "Required";
  }
  if (formUnit.zipCode && formUnit.zipCode.length < 4) {
    errors.zipCode = "Too small";
  }
  if (formUnit.zipCode && !isValidZipCode(formUnit.zipCode)) {
    errors.zipCode = "Invalid ZipCode. Ex: 98008 or 98008-1234";
  }
  console.log("Form validate issue", errors);
  return errors;
};

export const getUserInputFromFormValues = (
  formUser: IFormUser,
  unitDetails: UnitDetails
): UserInput => {
  const { firstName = "", lastName = "", email = "", phone = "" } = formUser;
  const addressInput: AddressInput = omit(unitDetails.address, "__typename");
  const userInput: UserInput = {
    firstName: getFormattedName(firstName),
    lastName: getFormattedName(lastName),
    contactDetails: {
      phone,
      email,
    },

    community: unitDetails.community,
    address: addressInput,
  };
  return userInput;
};

export const getTaskInputFromFormValues = (
  formTask: IFormTask,
  community: Community,
  units: Unit[]
): NewTaskInput => {
  const {
    title = "",
    description = "",
    forUnits: unitNames = [],
    assignedTo = null,
    createdBy = "",
    labels = [],
  } = formTask;

  const newTaskInput: NewTaskInput = {
    title,
    description,
    forUnits: isEmpty(unitNames)
      ? []
      : getUnitIdsForUnitNames(unitNames, units),
    labels,
    createdBy,
    assignedTo: null,
    community: community.id,
    isActive: true,
  };
  return newTaskInput;
};

export const getUnitInputFromFormValues = (
  formUnit: IFormUnit,
  community: Community
): UnitInput => {
  const {
    name = "",
    addressLine1 = "",
    addressLine2 = "",
    zipCode = "",
    city = "",
    state = "",
  } = formUnit;
  const addressInput: AddressInput = {
    addressLine1,
    addressLine2,
    city,
    zipCode,
    state,
  };
  const unitInput: UnitInput = {
    name: getFormattedName(name),
    community: community.id,
    address: addressInput,
  };
  return unitInput;
};
export const isUnitNameAlreadyExists = (
  existingUnitNames: string[],
  formUnit: IFormUnit
): boolean =>
  some(
    existingUnitNames,
    (existingUnitName) =>
      existingUnitName === formUnit.name?.toLocaleLowerCase()
  );
