import React from "react";
import {
  CurrentCommunityQuery,
  OccupancyType,
  Unit,
  User,
  useUnitsQuery,
  useUsersQuery,
} from "../data/__generated__/client-graphql-types";
import { RouteComponentProps, navigate } from "@reach/router";
import { camelCase, Dictionary, keyBy, startCase, countBy, filter, includes, debounce, some } from "lodash";
import { Column, Row, useTable } from "react-table";
import {
  Box,
  Button,
  Flex,
  FlexItem,
  FlexItemProps,
  Header,
  Label,
  MenuButton,
  MoreIcon,
  Table,
  TenantPersonalIcon,
  Text,
  CardHeader,
  CardBody, Card,
  Accordion,
  Loader,
  Input,
  Divider,
  SearchIcon
} from "@fluentui/react-northstar";
import { ResidentNavButton } from "./ResidentNavButton";
import { CreateNewUnitDialog } from "../modals/CreateNewUnitDialog";
import { useUserContext } from "../UserContext";
import { PageBanner } from "./PageBanner";
import { Tabs } from "./Tabs";
import { users } from "../data/queries";
import { ImportsNotUsedAsValues } from "typescript";
import { getFormattedName } from "../app-helper";

type OwnerUnit = {
  owners?: User[];
} & Omit<Unit, "owners" | "tenants">;

const useCreateNewUnitDialog = () => {
  const [
    showCreateNewUnitDialog,
    setShowCreateNewUnitDialog,
  ] = React.useState<boolean>(false);

  const launchCreateNewUnitDialog = React.useCallback(() => {
    setShowCreateNewUnitDialog(true);
  }, [setShowCreateNewUnitDialog]);

  const onCreateNewUnitDialogClose = React.useCallback(() => {
    setShowCreateNewUnitDialog(false);
  }, [setShowCreateNewUnitDialog]);
  return {
    launchCreateNewUnitDialog,
    onCreateNewUnitDialogClose,
    showCreateNewUnitDialog,
  };
};
const ALL_UNITS = 0;
const OWNER_TYPE = 1;
const TENANT_TYPE = 2;
const UNOCCUPIED_TYPE = 3;

const UnitsTableContainer = (_: RouteComponentProps) => {
  let data: CurrentCommunityQuery | null;
  const { currentCommunityId: communityId = "" } = useUserContext();
  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useUnitsQuery({
    variables: {
      communityId,
    },
  });
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useUsersQuery({
    variables: {
      communityId,
    },
  });

  const {
    launchCreateNewUnitDialog,
    onCreateNewUnitDialogClose,
    showCreateNewUnitDialog,
  } = useCreateNewUnitDialog();
  const units = unitsData?.communityUnits as Unit[];
  const users = usersData?.communityUsers as User[];
  const { OWNER, TENANT, NONE } = countBy(units, "occupancyType");
  return (
    <>
      <PageBanner
        title="Units"
        icon={<TenantPersonalIcon size="large" style={{ padding: 10 }} />}
      />

      { unitsLoading || usersLoading ? <Loader /> : (<Flex space="between" column>
        <Flex>
          <FlexItem grow>
            <OccupancyStats ownerOccupancy={OWNER} tenantOccupancy={TENANT} noneOccupancy={NONE} />
          </FlexItem>
          <FlexItem push>
            <UnitsMenu launchCreateNewUnitDialog={launchCreateNewUnitDialog} />
          </FlexItem>
        </Flex>
        <Flex hAlign="center">
          <Flex column style={{ width: "100%" }} space="between">
            {communityId ? (
              <UnitsTableRenderer
                units={units} // TODO: Remove forecasting if possible
                users={users}
              />
            ) : null}
          </Flex>
        </Flex>
      </Flex>)}
      {showCreateNewUnitDialog && (
        <CreateNewUnitDialog
          open={true}
          onClose={onCreateNewUnitDialogClose}
          communityId={communityId}
        ></CreateNewUnitDialog>
      )}
    </>
  );
};

const FluentReactUnitsTable = ({
  columns,
  ownerUnits,
}: {
  columns: Column<OwnerUnit>[]; // TODO: try to avoid this!!
  ownerUnits: OwnerUnit[];
}) => {
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [displayUnits, setDisplayUnits] = React.useState<OwnerUnit[]>(ownerUnits);
  const onInputChange = React.useCallback(
    (_ev: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
      setSearchQuery(newValue.value);
      if (!newValue.value || newValue.value.trim().length === 0) {
        setDisplayUnits(ownerUnits);
      } else {
        const trimmedLowerCasedValue = newValue.value.toLowerCase().trim();
        const filteredDisplayUnits = filter(ownerUnits, unit => isUnitNameMatched(unit, trimmedLowerCasedValue) ||
          isOwnersNameMatched(unit, trimmedLowerCasedValue)
          || isUnitOccupancyMatched(unit, trimmedLowerCasedValue));
        setDisplayUnits(filteredDisplayUnits);
      }
    }
    , [searchQuery, setSearchQuery, setDisplayUnits, ownerUnits]
  );
  React.useEffect(() => {
    if (!searchQuery || searchQuery.trim().length === 0) {
      setDisplayUnits(ownerUnits);
    } else {
      const trimmedLowerCasedValue = searchQuery.toLowerCase().trim();
      const filteredDisplayUnits = filter(ownerUnits, unit => isUnitNameMatched(unit, trimmedLowerCasedValue) ||
        isOwnersNameMatched(unit, trimmedLowerCasedValue)
        || isUnitOccupancyMatched(unit, trimmedLowerCasedValue));
      setDisplayUnits(filteredDisplayUnits);
    }
  }, [ownerUnits]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<OwnerUnit>({
    columns: columns,
    data: displayUnits,
  });

  const getRowCells = (row: Row<OwnerUnit>) => {
    return row.cells.map((cell, index) => {
      let content = cell.render("Cell");
      return {
        key: index,
        content,
      };
    });
  };
  const headers = {
    items: headerGroups[0].headers.map((column) => (
      <Header as="h3">{column.render("Header")}</Header>
    )),
  };

  // TODO: optimize
  const tableRows = rows.map((row, i) => {
    prepareRow(row);

    return {
      key: i,
      items: getRowCells(row),
    };
  });

  // Render the UI for your table
  return <>
    <br />
    <Flex>
      <FlexItem push>
        <Input icon={<SearchIcon />} clearable placeholder="Search..." style={{ paddingRight: 10 }} value={searchQuery}
          onChange={onInputChange as any} />
      </FlexItem>
    </Flex>
    <Table header={headers} rows={tableRows} aria-label="Static table" />
  </>;
};
const tabsTitles: string[] = ["All", "Owner Occupied", "Rental", "Unoccupied"];

export const UnitsTableRenderer = ({
  units,
  users,
}: {
  units: Unit[];
  users: User[];
}) => {
  //TODO: scope to optimize. Write to cache instead of always computing this userIds map
  const userIdsMap = keyBy(users, "id");
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const onTabSwitch = React.useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);
  const ownerUnits: OwnerUnit[] = units?.map((unit) => ({
    ...unit,
    owners: getOwnersByUnit(unit, userIdsMap),
  }));

  const columns = React.useMemo(
    (): Column<OwnerUnit>[] => [
      {
        Header: "Unit",
        accessor: "name",
        Cell: ({ cell }) => (
          <Button
            primary
            fluid
            onClick={async (event: any) => {
              //TODO: optimiize
              event.stopPropagation();
              await navigate(`/units/${cell.row.original.id}`);
            }}
          >
            {startCase(camelCase(cell.value))}{" "}
          </Button>
        ),
      },
      {
        Header: "Occupancy",
        accessor: "occupancyType",
        Cell: ({ cell: { value: occupancyType } }) => (
          <Label content={startCase(camelCase(occupancyType))} />
        ),
      },
      {
        Header: "Owner",
        accessor: "owners",
        Cell: ({ cell: { value: owners } }) => (
          <Flex>
            {owners?.map((owner: User) => (
              <ResidentNavButton user={owner} />
            ))}
          </Flex>
        ),
      },
    ],
    []
  );
  if (!units) return null;
  const tableUnits = getUnits(ownerUnits, activeTabIndex);
  return (
    <>
      <Tabs
        defaultActiveIndex={0}
        tabTitles={tabsTitles}
        onTabSwitch={onTabSwitch}
        pointing={false}
      />
      <FluentReactUnitsTable columns={columns} ownerUnits={tableUnits} />
    </>
  );
};

const getUnits = (units: OwnerUnit[], activeTabIndex: number) => {
  let activeUnits = filter(units, unit => unit.isActive);

  switch (activeTabIndex) {
    case OWNER_TYPE:
      return filter(activeUnits, unit => unit.occupancyType === OccupancyType.Owner);

    case TENANT_TYPE:
      return filter(activeUnits, unit => unit.occupancyType === OccupancyType.Tenant);

    case ALL_UNITS:
      return activeUnits;

    case UNOCCUPIED_TYPE:
      return filter(activeUnits, unit => unit.occupancyType === OccupancyType.None);

    default:
      return units;
  }
}

const getOwnersByUnit = (
  unit: Unit,
  userIdsMap: Dictionary<User>
): User[] | undefined => unit.owners?.map((ownerId) => userIdsMap[ownerId]);

type FlexItemStyleProp = Pick<FlexItemProps, "styles">;

interface IUnitsMenuProps extends FlexItemStyleProp {
  launchCreateNewUnitDialog: () => void;
}

const UnitsMenu: React.FC<IUnitsMenuProps> = ({
  launchCreateNewUnitDialog,
  ...rest
}: IUnitsMenuProps) => {
  const menu = [
    {
      key: "0",
      content: (
        <Button
          text
          size="small"
          content="Create New Unit"
          icon={<TenantPersonalIcon />}
          onClick={() => launchCreateNewUnitDialog()}
        />
      ),
    },
  ];
  return (
    <MenuButton
      {...rest}
      trigger={<Button icon={<MoreIcon />} />}
      menu={menu}
      style={{ padding: 10 }}
    />
  );
};



const OccupancyStats = ({ ownerOccupancy = 0, tenantOccupancy = 0, noneOccupancy = 0 }: { ownerOccupancy: number, tenantOccupancy: number, noneOccupancy: number }) => {

  const totalUnits = ownerOccupancy + tenantOccupancy + noneOccupancy;
  const ownerOccupancyPercent = (ownerOccupancy * 100) / totalUnits;
  const tenantOccupancyPercent = (tenantOccupancy * 100) / totalUnits;
  const noneOccupancyPercent = (noneOccupancy * 100) / totalUnits;

  return (<Accordion style={{ width: "100%" }} defaultActiveIndex={[0]} panels={[{
    title: <Text content="Occupancy Stats" weight="bold" />,
    content: <Card fluid quiet compact>
      <CardBody>
        {<Box><Text color="brand" weight="semibold" content="Total Units&nbsp;&nbsp;:&nbsp;&nbsp;" /> <Text content={`${totalUnits} units`} /></Box>}
        <br />

        {<Box><Text color="brand" weight="semibold" content="Owners &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;" /> <Text weight="regular" content={`${ownerOccupancyPercent}%`} size="small" /> <Text content={`(${ownerOccupancy} units)`} style={{ color: "grey" }} weight="light" size="small" /></Box>}
        {<Box><Text color="brand" weight="semibold" content="Rental&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;" /> <Text weight="regular" content={`${tenantOccupancyPercent}%`} size="small" /> <Text content={`(${tenantOccupancy} units)`} style={{ color: "grey" }} weight="light" size="small" /></Box>}
        {<Box><Text color="brand" weight="semibold" content="No Ownership&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;" /><Text weight="regular" content={`${noneOccupancyPercent}%`} size="small" /> <Text content={`(${noneOccupancy} units)`} style={{ color: "grey" }} weight="light" size="small" /></Box>}
      </CardBody>
    </Card>

  }]} />)
}

export const UnitsTable = UnitsTableContainer;

function isUnitOccupancyMatched(unit: OwnerUnit, searchText: string): boolean {
  return unit.occupancyType.toLowerCase().includes(searchText);
}

function isUnitNameMatched(unit: OwnerUnit, searchText: string): boolean {
  return unit.name.toLowerCase().trim().includes(searchText);
}

function isOwnersNameMatched(unit: OwnerUnit, searchText: string): boolean {
  return some(unit.owners, user => getFormattedName(user.firstName, user.lastName).toLowerCase().trim().includes(searchText));
}

