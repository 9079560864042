import {
  ComponentSlotStylesPrepared,
  TeamsThemeStylesProps,
} from "@fluentui/react-northstar";

export const ButtonStyles: ComponentSlotStylesPrepared<
  NonNullable<TeamsThemeStylesProps["Button"]>,
  any
> = {
  root: ({
    props: {},
    variables: {
      isFullRowCell,
      isTaskTitleInTaskPreview,
    },
    theme: {
      siteVariables: { colors, borderWidth },
    },
  }) => ({
    ...(isTaskTitleInTaskPreview && {
      selectors: {
        ":hover": {
          cursor: "pointer",
        },
      },
    }),
    ...(isFullRowCell && {
      width: "100%",
      minWidth: "100%",
    }),
  }),
};
