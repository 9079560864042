import { Dictionary } from "lodash";
import { Task, User } from "../data/__generated__/client-graphql-types";
import { Text } from "@fluentui/react-northstar";
import React from "react";
export const TaskAttachmentsContainer = ({
  task,
  usersDirectory,
}: {
  task: Task;
  usersDirectory: Dictionary<User>;
}) => {
  return <Text content="Task Attachments...coming soon!" timestamp />;
};
