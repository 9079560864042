import { Header, Flex, ToDoListIcon } from "@fluentui/react-northstar";
import React from "react";
const PAGE_PADDING = "20px";

export const PageBanner = ({
  title,
  icon,
}: {
  title?: string;
  icon?: React.ReactNode;
}) => {
  return title ? (
    <Flex
      id="docs-sticky-header"
      style={{
        position: "sticky",
        padding: `${PAGE_PADDING} ${PAGE_PADDING} 10px ${PAGE_PADDING}`,
        top: 0,
        background: "#8a8db614",
        backdropFilter: "blur(10px)",
        zIndex: 1000,
      }}
    >
      {icon && icon}
      <Header
        as="h1"
        aria-level={2}
        content={title}
        style={{ margin: 0, fontFamily: "system-ui" }}
        variables={{ color: "black" }}
      />
      {/* <Menu
      underlined
      activeIndex={currentTabIndex}
      items={tabs}
      style={{ marginTop: '0.5rem', background: 'none', border: 'none' }}
      onItemClick={this.handleTabClick}
      accessibility={tabListBehavior}
    /> */}
    </Flex>
  ) : null;
};
