import { Redirect, RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";
import { useUserContext } from "./UserContext";
import { isEmpty } from "lodash";

const PrivateRoute = ({ as: Component, ...props }: any) => {
  const { currentUser } = useUserContext();
  return !isEmpty(currentUser) ? (
    <Component {...props} />
  ) : (
    <Redirect from="" to="/" noThrow />
  );
};
export default PrivateRoute;
