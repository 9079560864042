import gql from "graphql-tag";

export default gql`
  fragment TaskComment on TaskComment {
    id
    taskId
    comment
    commentedBy
    createdAt
    updatedAt
  }
`;
