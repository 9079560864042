import React from "react";
import { Formik } from "formik";

import {
  useCreateUnitMutation,
  Community,
  Unit,
} from "../data/__generated__/client-graphql-types";
import { CloseIcon, Dialog, Loader } from "@fluentui/react-northstar";
import { units as unitsQuery } from "../data/queries";
import {
  getUnitInputFromFormValues,
  IFormUnit,
  unitFormValidation,
} from "./modals-helper";
import { useApolloClient } from "@apollo/client";
import { NewUnitForm } from "./NewUnitForm";
import { useUserContext } from "../UserContext";
import { getUnits } from "../app-helper";

interface ICreateNewUnitDialogProps {
  open: boolean;
  onClose: () => void;
  communityId: string;
}
export const CreateNewUnitDialog = ({
  open,
  onClose,
  communityId,
}: ICreateNewUnitDialogProps) => {
  console.log("DEBUG CommunityId", communityId);
  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const onCancel = React.useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const onConfirm = React.useCallback(async () => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen]);
  const dialogContent = useDialogContent(onConfirm, onCancel);
  return (
    <Dialog
      closeOnOutsideClick={false}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={isOpen}
      header="New Unit"
      content={dialogContent}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => onCancel(),
      }}
    />
  );
};

const intialFormErrors: IFormUnit = {
  name: "",
  addressLine1: "",
  addressLine2: "",
  zipCode: "",
  city: "",
  state: "",
};

const intialFormValues: IFormUnit = {
  name: "",
  addressLine1: "",
  addressLine2: "",
  zipCode: "",
  city: "",
  state: "",
};

const useUnitFormValidation = (unit: Array<Unit>) => {
  // convert to lowercase
  const existingUnitNames = unit.map((unit) => unit.name.toLowerCase());
  return React.useCallback(
    (formUnit: IFormUnit) => unitFormValidation(formUnit, existingUnitNames),
    [existingUnitNames]
  );
};
const useDialogContent = (
  confirmDialog: () => void,
  cancelDialog: () => void
) => {
  const [createUnit, { loading: mutationLoading }] = useCreateUnitMutation();
  const client = useApolloClient();
  const { currentCommunityId, currentCommunity } = useUserContext();
  const units: Array<Unit> = getUnits(client, currentCommunityId);

  const formValidation = useUnitFormValidation(units);

  //TODO: avoid forecasting
  //TODO: address error case for mutation error
  return mutationLoading ? (
    <Loader />
  ) : (
      <Formik<IFormUnit>
        initialValues={intialFormValues}
        initialErrors={intialFormErrors}
        validate={formValidation}
        onSubmit={(values, { setSubmitting }) => {
          // TODO: use loading and error
          createUnit({
            variables: {
              input: getUnitInputFromFormValues(
                values,
                currentCommunity as Community
              ),
            },
            refetchQueries: [
              {
                query: unitsQuery,
                variables: {
                  communityId: currentCommunityId ?? "",
                },
              },
            ],
          }).then(() => {
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
            confirmDialog();
          });
        }}
        render={({
          handleSubmit,
          handleChange,
          errors,
          handleBlur,
          touched,
          values,
          isSubmitting,
          setTouched,
          setFieldValue,
        }) => {
          return isSubmitting ? (
            <Loader />
          ) : (
              <NewUnitForm
                handleSubmit={handleSubmit}
                cancelDialog={cancelDialog}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                values={values}
                setTouched={setTouched}
                setFieldValue={setFieldValue}
              />
            );
        }}
      />
    );
};




