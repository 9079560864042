import { RouteComponentProps } from "@reach/router";
import React from "react";
import { usePageBannerContext } from "../PageBannerContext";

export const Units = (
  props: RouteComponentProps & {
    children: {};
  }
) => {
  return <>{props.children}</>;
};
