import React from "react";

export interface IEmailNumberProps {
  address: string;
  isLinked: boolean;
  className?: string;
}
export const Email = (props: IEmailNumberProps) => {
  let { address, isLinked, className } = props;

  if (!address) return null;

  if (isLinked) {
    return React.createElement(
      "a",
      { href: "mailto:" + address, className: className },
      address
    );
  } else {
    return React.createElement("span", { className: className }, address);
  }
};
