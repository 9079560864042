import {
  Form,
  FormField,
  FormInput,
  FormButton,
  Flex,
  FlexItem,
  Input,
  Loader,
} from "@fluentui/react-northstar";
import { navigate } from "@reach/router";
import { FormikTouched, FormikErrors, Formik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { isValidEmail } from "../app-helper";
import { useLoginSetupMutation } from "../data/__generated__/client-graphql-types";
import { Action, ActionType } from "./LoginSetup";

/**
 * Interface for user input form
 */
export interface ILoginSetupForm {
  newPassword?: string;
  recoveryEmail?: string;
  verificationCode?: string;
}

const initialFormValues: ILoginSetupForm = {
  newPassword: "",
  recoveryEmail: "",
  verificationCode: "",
};
const initialFormErrors: ILoginSetupForm = {
  newPassword: "",
  recoveryEmail: "",
  verificationCode: "",
};

interface ILoginSetupFormProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  touched: FormikTouched<ILoginSetupForm>;
  errors: FormikErrors<ILoginSetupForm>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  values: ILoginSetupForm;
}

export const LoginSetupForm = ({ userId, verificationCode, dispatch }: { userId: string; verificationCode: string; dispatch: React.Dispatch<Action> }) => {

  const [loginSetup] = useLoginSetupMutation();

  return (
    <Formik
      initialValues={initialFormValues}
      initialErrors={initialFormErrors}
      validate={formValidation}
      onSubmit={async (values, { setSubmitting }) => {
        console.log("submitting new password");

        const { recoveryEmail = "", newPassword: password = "" } = values;
        const { data: loginSetupResult } = await loginSetup({
          variables: {
            userId, recoveryEmail, verificationCode, password
          }
        })

        if (loginSetupResult && loginSetupResult.loginSetup?.isSuccess) {
          window.alert(
            "Login setup was successfull. Please login to proceed further."
          );
          await navigate("/");
        }
        else if (loginSetupResult && loginSetupResult.loginSetup?.isSuccess === false) {
          if (loginSetupResult?.loginSetup?.errorMessage?.toLowerCase().includes("verification code mismatched")) {
            dispatch({ type: ActionType.Verfication });
          } else if (loginSetupResult?.loginSetup?.errorMessage?.toLowerCase().includes("your account has been disabled")) {
            await navigate("/");
          }
          window.alert(loginSetupResult?.loginSetup?.errorMessage)

        }
        else {
          console.error(loginSetupResult?.loginSetup?.errorMessage);
          window.alert("Something went wrong while login setup. Please contact administrator.")
        }
      }}
      render={({
        handleSubmit,
        handleChange,
        errors,
        handleBlur,
        touched,
        values,
        isSubmitting,
      }) => {
        return isSubmitting ? (
          <Loader />
        ) : (
            <LoginSetupFormContent
              handleSubmit={handleSubmit}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              values={values}
            />
          );
      }}
    />
  );
};

const LoginSetupFormContent = ({
  handleSubmit,
  handleChange,
  errors,
  handleBlur,
  touched,
  values,
}: ILoginSetupFormProps) => {
  return (
    <Form onSubmit={handleSubmit as any}>
      <FormField
        errorMessage={touched.newPassword && errors.newPassword}
        label="Password"
        name="newPassword"
        id="newPassword"
        onBlur={handleBlur}
        onChange={handleChange}
        control={{
          as: Input,
          fluid: true,
          type: "password",
          showSuccessIndicator: Boolean(
            values.newPassword && values.newPassword?.length >= 3
          ),
        }}
      />

      <FormInput
        errorMessage={touched.recoveryEmail && errors.recoveryEmail}
        label="Recovery Email"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={isValidEmail(values.recoveryEmail)}
        name="recoveryEmail"
        id="recoveryEmail"
        required
        placeholder="sam@gmail.com"
        fluid
      />

      <Flex gap="gap.small">
        <FlexItem push>
          <FormButton content="Submit" disabled={!isEmpty(errors)} />
        </FlexItem>
      </Flex>
    </Form>
  );
};

/**
 *
 * @param formUser This method does validation for the Resident Form
 */
export const formValidation = (formUser: ILoginSetupForm) => {
  const errors: ILoginSetupForm = {};
  //TODO: IMP validate and send
  // if (!formUser.firstName) {
  //   errors.firstName = "Required";
  // }
  // if (formUser.firstName && formUser.firstName.length < 3) {
  //   errors.firstName = "Too small";
  // }

  // if (!formUser.lastName) {
  //   errors.lastName = "Required";
  // }
  // if (formUser.lastName && formUser.lastName.length < 3) {
  //   errors.lastName = "Too small";
  // }

  // if (!formUser.email) {
  //   errors.email = "Required";
  // }
  // if (formUser.email && formUser.email.length < 4) {
  //   errors.email = "Too small";
  // }
  // if (formUser.email && !isValidEmail(formUser.email)) {
  //   errors.email = "Invalid Email";
  // }
  console.log("Form validate issue", errors);
  return errors;
};
