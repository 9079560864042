import { Form, FormInput, FormButton, FormField, Button, Flex, FlexItem } from "@fluentui/react-northstar";
import { FormikTouched, FormikErrors } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { isValidEmail } from "../app-helper";
import { IFormUser } from "./modals-helper";

interface INewResidentFormProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  touched: FormikTouched<IFormUser>;
  errors: FormikErrors<IFormUser>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  values: IFormUser;
  cancelDialog: () => void;
}

export const NewResidentForm = ({
  handleSubmit,
  handleChange,
  errors,
  handleBlur,
  touched,
  values,
  cancelDialog,
}: INewResidentFormProps) => {
  return (
    <Form onSubmit={handleSubmit as any}>
      <FormInput
        errorMessage={touched.firstName && errors.firstName}
        label="First Name"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(
          values.firstName && values.firstName?.length >= 3
        )}
        name="firstName"
        id="firstName"
        required
        fluid
      />
      <FormInput
        errorMessage={touched.lastName && errors.lastName}
        label="Last Name"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(
          values.lastName && values.lastName?.length >= 3
        )}
        name="lastName"
        id="lastName"
        required
        fluid
      />

      <FormInput
        errorMessage={touched.phone && errors.phone}
        label="Phone Number"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={Boolean(values.phone && values.phone.length >= 4)}
        name="phone"
        id="phone"
        required
        placeholder="(888) 888-8888"
        fluid
      />

      <FormInput
        errorMessage={touched.email && errors.email}
        label="Email"
        onBlur={handleBlur}
        onChange={handleChange}
        showSuccessIndicator={isValidEmail(values.email)}
        name="email"
        id="email"
        required
        placeholder="sam@gmail.com"
        fluid
      />

      <Flex gap="gap.small">
        <FlexItem push>
          <FormButton content="Submit" disabled={!isEmpty(errors)} />
        </FlexItem>
        <FormField
          control={{
            as: Button,
            content: "Cancel",
            onClick: () => cancelDialog(),
          }}
        />
      </Flex>
    </Form>
  );
};
