import gql from "graphql-tag";

export default gql`
  fragment AccessTokenResult on AccessTokenResult {
    statusCode
    statusText
    accessToken
    failureReason
  }
`;
