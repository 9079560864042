import gql from "graphql-tag";
import { contactDetails } from ".";

export default gql`
  fragment Community on Community {
    id
    name
    isActive
    contactDetails {
      ...ContactDetails
    }
  }
  ${contactDetails}
`;
