import { navigate } from "@reach/router";
import React from "react";
import {
  Unit,
  User,
  useUnitsQuery,
  useUpdateUserMutation,
  useUsersQuery,
} from "../data/__generated__/client-graphql-types";
import {
  AudienceIcon,
  Button,
  Flex,
  FlexItem,
  Header,
  Input,
  Label,
  SearchIcon,
  Table,
} from "@fluentui/react-northstar";
import { startCase, camelCase, includes, isEmpty, filter, some } from "lodash";
import { Column, Row, useTable } from "react-table";
import { Maybe } from "graphql/jsutils/Maybe";
import { getFormattedName } from "../app-helper";
import { useUserContext } from "../UserContext";
import { PageBanner } from "./PageBanner";
import { UnitNavButton } from "./UnitNavButton";
import usersQuery from "../data/queries/users.query";
import { Tabs } from "./Tabs";
const tabsTitles: string[] = ["All", "Owners", "Tenants", "Disabled"];
const ALL_USERS = 0;
const OWNER_TYPE = 1;
const TENANT_TYPE = 2;
const DISABLED_USERS_TYPE = 3;
const ResidentsTableContainer = () => {
  const { currentCommunityId: communityId = "" } = useUserContext();

  const {
    data: units,
  } = useUnitsQuery({
    variables: {
      communityId: communityId,
    },
  });
  const {
    data: users,
  } = useUsersQuery({
    variables: {
      communityId: communityId,
    },
  });

  return !isEmpty(communityId) ? (
    <ResidentsTableRenderer
      units={units?.communityUnits as Unit[]} // TODO: Remove forecasting if possible
      users={users?.communityUsers as User[]}
    />
  ) : "Currently there are not active residents!";
};

export const ResidentsTableRenderer = ({
  units,
  users,
}: {
  units: Unit[];
  users: User[];
}) => {
  var tenantUnits: any = {};
  const [updateUserMutation] = useUpdateUserMutation();
  const { currentCommunityId: communityId = "" } = useUserContext();
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const onTabSwitch = React.useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);
  const columns = React.useMemo(
    (): Column<User>[] => [
      {
        Header: "Resident",
        id: "name",
        accessor: (user: User) =>
          getFormattedName(user?.firstName, user?.lastName),
        Cell: ({ cell }) => (
          <Button
            primary
            fluid
            onClick={async (event: any) => {
              //TODO: optimiize
              event.stopPropagation();
              await navigate(`/residents/${cell.row.original.id}`);
            }}
          >
            {startCase(camelCase(cell.value))}
          </Button>
        ),
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: ({ cell: { value: roles } }) => (
          <Flex>
            {roles?.map((role: any) => (
              <Label content={startCase(camelCase(role ?? ""))} />
            ))}
          </Flex>
        ),
      },
      {
        Header: "Units",
        id: "units",
        accessor: (user: User) => {
          const units: Maybe<Unit>[] = [];
          const { roles } = user;
          if (includes(roles, "OWNER")) {
            units.push(...user.unitsOwned);
          }

          if (includes(roles, "TENANT") && tenantUnits) {
            const tenantUnit = tenantUnits[user.id];
            if (tenantUnit) {
              units.push(...tenantUnit);
            }
          }
          return units;
        },
        Cell: ({ cell: { value } }: any) => (
          <Flex>
            {value?.map((unit: Unit) => (
              <UnitNavButton unit={unit} />
            ))}
          </Flex>
        ),
      },
      {
        Header: "Action",
        id: "action",
        Cell: ({ cell }) => {
          const user = cell.row.original;
          const onDisable = React.useCallback(async () => {
            const isConfirm = window.confirm(`Would you like to enable ${getFormattedName(user.firstName, user.lastName)}?`);
            if (!isConfirm) {
              return;
            }

            await updateUserMutation({
              variables: {
                input: {
                  id: user.id,
                  isActive: !user.isActive
                }
              },
              refetchQueries: [
                {
                  query: usersQuery,
                  variables: {
                    communityId,
                  },
                },
              ],
            });

          }, [user]);

          return <Button content={user.isActive ? "Disable" : "Enable"} onClick={onDisable} />
        },
      },
    ],
    [tenantUnits]
  );

  if (!units) return null;
  //TODO: scope to optimize. Write to cache instead of always computing this userIds map
  for (let i = 0; i < units.length; i++) {
    const unit = units[i];
    if (unit.occupancyType !== "TENANT") continue;

    unit.tenants?.map((tenant) => {
      tenantUnits[tenant] = tenantUnits[tenant] || [];
      tenantUnits[tenant].push(unit);
      return null;
    });
  }
  if (isEmpty(units) || isEmpty(users)) {
    return null;
  }

  return (
    <>
      <PageBanner
        title="Residents"
        icon={<AudienceIcon size="large" style={{ padding: 10 }} />}
      />
      <Tabs
        defaultActiveIndex={0}
        tabTitles={tabsTitles}
        onTabSwitch={onTabSwitch}
        pointing={false}
      />
      <FluentReactResidentsTable columns={columns} users={getUsers(users, activeTabIndex)} />
    </>
  );
};


const getUsers = (users: User[], activeTabIndex: number) => {
  let activeUsers = filter(users, user => user.isActive)
  switch (activeTabIndex) {
    case OWNER_TYPE:
      return filter(activeUsers, user => includes(user.roles, "OWNER"));

    case TENANT_TYPE:
      return filter(activeUsers, user => includes(user.roles, "TENANT"));

    case ALL_USERS:
      return activeUsers;

    case DISABLED_USERS_TYPE:
      return filter(users, user => !user.isActive)
    default:
      return users;
  }
}
const FluentReactResidentsTable = ({
  columns,
  users,
}: {
  columns: Column<User>[]; // TODO: try to avoid this!!
  users: User[];
}) => {
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [displayUsers, setDisplayUsers] = React.useState<User[]>(users);
  const onInputChange = React.useCallback(
    (_ev: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
      setSearchQuery(newValue.value);
      if (!newValue.value || newValue.value.trim().length === 0) {
        setDisplayUsers(users);
      } else {
        const trimmedLowerCasedValue = newValue.value.toLowerCase().trim();
        const filteredDisplayUsers = filter(users, user => getFormattedName(user.firstName, user.lastName).toLowerCase().trim().includes(trimmedLowerCasedValue) || isAnyRoleMatched(user, trimmedLowerCasedValue) || isAnyUnitMatched(user, trimmedLowerCasedValue));
        setDisplayUsers(filteredDisplayUsers);
      }

    }
    , [searchQuery, setSearchQuery, setDisplayUsers, displayUsers]
  );

  React.useEffect(() => {
    if (!searchQuery || searchQuery.trim().length === 0) {
      setDisplayUsers(users);
    } else {
      const trimmedLowerCasedValue = searchQuery.toLowerCase().trim();
      const filteredDisplayUsers = filter(users, user => getFormattedName(user.firstName, user.lastName).toLowerCase().trim().includes(trimmedLowerCasedValue));
      setDisplayUsers(filteredDisplayUsers);
    }
  }, [users]);
  // Use the state and functions returned from useTable to build your UI
  const {
    headerGroups,
    rows,
    prepareRow,
  } = useTable<User>({
    columns: columns,
    data: displayUsers,
  });

  const getRowCells = (row: Row<User>) => {
    return row.cells.map((cell, index) => {
      let content = cell.render("Cell");
      return {
        key: index,
        content,
      };
    });
  };
  const headers = {
    items: headerGroups[0].headers.map((column) => (
      <Header as="h3">{column.render("Header")}</Header>
    )),
  };

  const tableRows = rows.map((row, i) => {
    prepareRow(row);
    return {
      key: i,
      items: getRowCells(row),
    };
  });

  // Render the UI for your table
  return <>
    <br />
    <Flex>
      <FlexItem push>
        <Input icon={<SearchIcon />} clearable placeholder="Search..." style={{ paddingRight: 10 }} value={searchQuery}
          onChange={onInputChange as any} />
      </FlexItem>
    </Flex><Table header={headers} rows={tableRows} aria-label="Useers table" /></>;
};

export const ResidentsTable = ResidentsTableContainer;

function isAnyRoleMatched(user: User, searchText: string): boolean {
  return some(user.roles, role => role && role.toLowerCase().trim().includes(searchText));
}

function isAnyUnitMatched(user: User, searchText: string): boolean {
  //TODO(vaboda): BUG IMPORTANT 
  return some(user.unitsOwned, unit => unit && unit.name.toLowerCase().trim().includes(searchText));
}

