import React from "react";

export interface IPhoneNumberProps {
  number: string;
  isLinked: boolean;
  className?: string;
}
export const PhoneNumber = (props: IPhoneNumberProps) => {
  let { number, isLinked, className } = props;

  if (!number) return null;

  number = number
    .toString()
    .replace(/[^0-9\+]+/g, "")
    .replace(/\s+/, "");

  var formatted_number = number;

  if (number.length == 8) {
    formatted_number = number.replace(
      /([0-9]{2})([0-9]{3})([0-9]{3})/,
      "$1 $2 $3"
    );
  } else if (number.length == 10) {
    formatted_number = number.replace(
      /([0-9]{3})([0-9]{3})([0-9]{4})/,
      "$1 $2 $3"
    );
  } else if (number.indexOf("+") == 0) {
    formatted_number = number.replace(
      /([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{3})/,
      "$1 $2 $3 $4"
    );
  }

  if (isLinked) {
    return React.createElement(
      "a",
      { href: "tel:" + number, className: className },
      formatted_number
    );
  } else {
    return React.createElement(
      "span",
      { className: className },
      formatted_number
    );
  }
};
