import { useApolloClient } from "@apollo/client";
import {
  Alert,
  Button,
  Flex,
  Form,
  GeofenceArrivesIcon,
  Header,
  Input,
  Text,
} from "@fluentui/react-northstar";
import { navigate, RouteComponentProps } from "@reach/router";
import React, { useReducer } from "react";
import { LoginSetupForm } from "./LoginSetupForm";
import {
  LoginSetupStatusDocument,
  LoginSetupStatusQuery,
  LoginSetupStatusQueryVariables,
  LoginSetupStatus,
} from "../data/__generated__/client-graphql-types";
interface ILoginSetupProps extends RouteComponentProps {
  setupId?: string;
}

const getSetupId = (setupId: string) => {
  // try {
  //   return atob(setupId);
  // } catch {
  //   return setupId;
  // }
  return setupId;
};

const isSetUpAlreadyFinished = () => {
  // IMP TODO: ask service if the setup link is completed??
  return false;
};

const isSetUpCodeExpired = () => {
  // IMP TODO: ask service if the setup link is completed??
  return false;
};

/**
 * Task Module States
 */
export type Action = {
  type: ActionType,
  verificationCode?: string
}

export enum ActionType {
  ResetVerification,
  Verfication,
  LoginSetup,
}

type LoginSetupState = {
  showResetVerification: boolean;
  showVerificationForm: boolean;
  showLoginSetupForm: boolean;
  verificationCode?: string;
};

//DisplayStateNames
// | IDisplayResetVerfication
// | IDisplayLoginSetup
// | IDisplayVerfication;

const reducer = (state: LoginSetupState, action: Action) => {
  switch (action.type) {
    case ActionType.ResetVerification:
      return {
        showResetVerification: true,
        showVerificationForm: false,
        showLoginSetupForm: false,
        verificationCode: undefined
      };

    case ActionType.Verfication:
      return {
        showResetVerification: false,
        showVerificationForm: true,
        showLoginSetupForm: false,
        verificationCode: state.verificationCode
      };

    case ActionType.LoginSetup:
      return {
        showResetVerification: false,
        showVerificationForm: false,
        showLoginSetupForm: true,
        verificationCode: action.verificationCode
      };
    default:
      throw new Error();
  }
};

export const LoginSetup = (props: ILoginSetupProps) => {
  const [state, dispatch] = useReducer(reducer, {
    showResetVerification: false,
    showVerificationForm: false,
    showLoginSetupForm: false,
    verificationCode: undefined
  });

  const {
    showResetVerification,
    showVerificationForm,
    showLoginSetupForm,
  } = state;

  const [displayMessage, setDisplayMessage] = React.useState<string | null>();
  const client = useApolloClient();

  React.useEffect(() => {
    const getLoginSetupStatus = async () => {
      const { data } = await client.query<
        LoginSetupStatusQuery,
        LoginSetupStatusQueryVariables
      >({
        query: LoginSetupStatusDocument,
        variables: {
          userId: props.setupId ?? "",
        },
        fetchPolicy: "no-cache",
      });
      return data?.loginSetupStatus?.status as LoginSetupStatus;
    };

    const navigateToPath = async (path: string) => {
      await navigate(path);
    };

    getLoginSetupStatus().then(async (status) => {
      if (status === LoginSetupStatus.NotAvailable) {
        setDisplayMessage("Invalid setup link.");
        // IMP TODO: if setup is completed show the message and redirect the page
        setTimeout(() => navigateToPath("/"), 3000);
      } else if (status === LoginSetupStatus.Completed) {
        setDisplayMessage("Registration already completed.");
        // IMP TODO: if setup is completed show the message and redirect the page
        setTimeout(() => navigateToPath("/"), 3000);
      } else if (status === LoginSetupStatus.Expired) {
        dispatch({ type: ActionType.ResetVerification });
      } else {
        dispatch({ type: ActionType.Verfication });
      }
      return () => { };
    });
  }, []);

  // React.useEffect(() => {
  //   const navigateToPath = async (path: string) => {
  //     await navigate(path);
  //   };
  //   if (isSetUpAlreadyFinished()) {
  //     setDisplayMessage(
  //       "Setup was completed already. Redirecting you to login page..."
  //     );
  //     // IMP TODO: if setup is completed show the message and redirect the page
  //     setTimeout(() => navigateToPath("/"), 3000);
  //   } else if (isSetUpCodeExpired()) {
  //     dispatch(Action.ResetVerification);
  //   } else {
  //     dispatch(Action.Verfication);
  //   }

  //   return () => {};
  // }, []);
  return (
    <>
      <Header content="Login Setup" />
      {displayMessage && (
        <Alert
          content="Redirecting to home page..."
          fitted
          header={displayMessage}
          visible
          warning
        />
      )}

      {showResetVerification && <ResetVerificationRenderer />}
      {showVerificationForm && <VerificationFormRenderer dispatch={dispatch} />}
      {showLoginSetupForm && <LoginSetupForm userId={props.setupId ?? ""} verificationCode={state.verificationCode ?? ""} dispatch={dispatch} />}
    </>
  );
};

const VerificationFormRenderer = ({
  dispatch,
}: {
  dispatch: React.Dispatch<Action>;
}) => {
  const headerDisplayName = "Verification Code";
  const [verificationCodeInput, setVerificationCodeInput] = React.useState<string | undefined>();
  return (
    <>
      <Header
        content={
          <Flex column>
            <Flex vAlign="center">
              <GeofenceArrivesIcon
                size="larger"
                rotate={-90}
                styles={{
                  color: "#6264a7",
                }}
              />
              <Text
                color="brand"
                content={headerDisplayName}
                // size="larger"
                // weight="semibold"
                style={{ paddingTop: 10, paddingLeft: 10 }}
              />
            </Flex>
          </Flex>
        }
        style={{ marginTop: 100 }}
      />

      <Flex
        style={{
          alignItems: "flex-start",
          width: "50%",
          padding: 25,
          border: "solid 1px #d7d7d7",
          borderRadius: 1,
        }}
        column
      >
        <Input fluid value={verificationCodeInput} required onChange={(e: React.SyntheticEvent) => {
          const input = e.target as HTMLInputElement;
          setVerificationCodeInput(input.value);

        }} />
        <br />
        <Button
          content="Next"
          onClick={() => {
            //TODO: Validate
            dispatch({ type: ActionType.LoginSetup, verificationCode: verificationCodeInput });
          }}
        />
      </Flex>
    </>
  );
};

const ResetVerificationRenderer = () => {
  const headerDisplayName = "Reset Verification";
  return (
    <>
      <Header
        content={
          <Flex column>
            <Flex vAlign="center">
              <GeofenceArrivesIcon
                size="larger"
                rotate={-90}
                styles={{
                  color: "#6264a7",
                }}
              />
              <Text
                color="brand"
                content={headerDisplayName}
                size="larger"
                weight="semibold"
                style={{ paddingTop: 10, paddingLeft: 10 }}
              />
            </Flex>
          </Flex>
        }
        style={{ marginTop: 100 }}
      />

      <Flex
        style={{
          minHeight: "100vh",
          alignItems: "flex-start",
          width: "70%",
        }}
        column
      >
        <Text
          style={{
            padding: 25,
            border: "solid 1px #d7d7d7",
            borderRadius: 1,
          }}
          content={
            "Your setup code has expired. Would you like to reset the code?"
          }
        ></Text>
        <Flex>
          <Button content="Yes" />
          <Button content="No" />
        </Flex>
      </Flex>
    </>
  );
};
