import { useApolloClient } from "@apollo/client";
import {
  AddIcon,
  Box, Button, Divider,
  EditIcon,
  Flex,
  FlexItem, Header,
  Loader,
  MenuButton,
  MoreIcon,
  ParticipantAddIcon, TenantPersonalIcon, Text,
  TrashCanIcon
} from "@fluentui/react-northstar";
import { RouteComponentProps } from "@reach/router";
import { isEmpty, map, size, some, without } from "lodash";
import React from "react";
import {
  getFormattedAddress,
  getFormattedName,
  isTenantOccupancy
} from "../app-helper";
import { FlexItemStyleProp } from "../app.interface";
import { units as unitsQuery, users as usersQuery } from "../data/queries";
import {
  UnitDetails,
  UnitDetailsDocument, UnitDetailsQuery,
  UnitDetailsQueryVariables, useUnitDetailsQuery,
  useUnitsQuery,
  useUpdateOwnersMutation,
  useUpdateTenantsMutation,
  useUpdateUnitMutation
} from "../data/__generated__/client-graphql-types";
import {
  CreateNewOwnerDialog, CreateNewTenantDialog,
  RemoveAllTenantsConfirmDialog,
  UpdateExistingUnitOwnerDialog
} from "../modals";
import { useUserContext } from "../UserContext";
import { PageBanner } from "./PageBanner";
import { UserDetailsPreviewCardRenderer } from "./UserDetailsPreviewCardRenderer";


interface IUnitProps extends RouteComponentProps {
  unitId?: string;
}
const UnitContainer = (props: IUnitProps) => {
  const { unitId = "" } = props;

  const { data, loading, error } = useUnitDetailsQuery({
    variables: {
      unitId,
    },
  });


  if (loading) {
    return <Loader size="medium" label="Loading..." labelPosition="below" />;
  }
  if (!loading && error) {
    console.error(
      `Unable to find the unit details for the unit id: ${unitId}`,
      error
    );
    return <Text>{"Unable to find the unit"}</Text>;
  }

  return <UnitRenderer unitDetails={data?.unitDetails as UnitDetails} />;
};

interface IUnitRendererProps {
  unitDetails: UnitDetails;
}

const useRemoveAllTenantsDialog = () => {
  const [
    showRemoveAllTenantsDialog,
    setShowRemoveAllTenantsDialog,
  ] = React.useState<boolean>(false);

  const launchRemoveAllTenantsDialog = React.useCallback(() => {
    setShowRemoveAllTenantsDialog(true);
  }, [setShowRemoveAllTenantsDialog]);

  const onRemoveAllTenantsDialogClose = React.useCallback(() => {
    setShowRemoveAllTenantsDialog(false);
  }, [setShowRemoveAllTenantsDialog]);
  return {
    launchRemoveAllTenantsDialog,
    onRemoveAllTenantsDialogClose,
    showRemoveAllTenantsDialog,
  };
};

const useCreateNewOwnerDialog = () => {
  const [
    showCreateNewOwnerDialog,
    setShowCreateNewOwnerDialog,
  ] = React.useState<boolean>(false);

  const launchCreateNewOwnerDialog = React.useCallback(() => {
    setShowCreateNewOwnerDialog(true);
  }, [setShowCreateNewOwnerDialog]);

  const onCreateNewOwnerDialogClose = React.useCallback(() => {
    setShowCreateNewOwnerDialog(false);
  }, [setShowCreateNewOwnerDialog]);
  return {
    launchCreateNewOwnerDialog,
    onCreateNewOwnerDialogClose,
    showCreateNewOwnerDialog,
  };
};

const useUpdateExistingUnitOwnerDialog = () => {
  const [
    showUpdateExistingUnitOwnerDialog,
    setShowUpdateExistingUnitOwnerDialog,
  ] = React.useState<boolean>(false);

  const launchUpdateExistingUnitOwnerDialog = React.useCallback(() => {
    setShowUpdateExistingUnitOwnerDialog(true);
  }, [setShowUpdateExistingUnitOwnerDialog]);

  const onUpdateExistingUnitOwnerDialogClose = React.useCallback(() => {
    setShowUpdateExistingUnitOwnerDialog(false);
  }, [setShowUpdateExistingUnitOwnerDialog]);
  return {
    launchUpdateExistingUnitOwnerDialog,
    onUpdateExistingUnitOwnerDialogClose,
    showUpdateExistingUnitOwnerDialog,
  };
};

const useCreateNewTenantDialog = () => {
  const [
    showCreateNewTenantDialog,
    setShowCreateNewTenantDialog,
  ] = React.useState<boolean>(false);

  const launchCreateNewTenantDialog = React.useCallback(() => {
    setShowCreateNewTenantDialog(true);
  }, [setShowCreateNewTenantDialog]);

  const onCreateNewTenantDialogClose = React.useCallback(() => {
    setShowCreateNewTenantDialog(false);
  }, [setShowCreateNewTenantDialog]);
  return {
    launchCreateNewTenantDialog,
    onCreateNewTenantDialogClose,
    showCreateNewTenantDialog,
  };
};

const useOnUserRemoveCallback = (
  userType: "owner" | "tenant",
  unitId: string
) => {
  const [updateOwnersMutation] = useUpdateOwnersMutation();
  const [updateTenantsMutation] = useUpdateTenantsMutation();
  const client = useApolloClient();
  let updateUnit =
    userType === "owner" ? updateOwnersMutation : updateTenantsMutation;

  return React.useCallback(
    async (userId?: string) => {
      const { data } = await client.query<
        UnitDetailsQuery,
        UnitDetailsQueryVariables
      >({
        query: UnitDetailsDocument,
        variables: {
          unitId,
        },
      });
      const unitDetails = data?.unitDetails;
      const communityId = unitDetails?.community;

      const userIds =
        userType === "owner"
          ? map(unitDetails?.owners, "id")
          : map(unitDetails?.tenants, "id");
      console.log(
        userType,
        unitDetails,
        size(unitDetails?.owners),
        size(unitDetails?.tenants)
      );
      //TODO: use FluentUI dialog confirmation
      const confirmationMessage =
        userType === "owner" &&
          size(unitDetails?.owners) === 1 &&
          size(unitDetails?.tenants) > 0
          ? "Removing this owner would remove all the tenants as well. Would you like to proceed?"
          : `Would you like to remove the ${userType}?`;

      const isConfirm = window.confirm(confirmationMessage);

      const variables =
        userType === "owner"
          ? {
            unitId,
            newOwnerIds: without(userIds, userId),
          }
          : {
            unitId,
            newTenantIds: without(userIds, userId),
          };
      // TODO: use loading and error
      // disable users who don't occupy any units or tenants anymore
      if (isConfirm) {
        await updateUnit({
          variables,
          refetchQueries: [
            {
              query: unitsQuery,
              variables: {
                communityId,
              },
            },
            {
              query: usersQuery,
              variables: {
                communityId,
              },
            },
          ],
        });
      }
    },
    [userType]
  );
};

const UnitRenderer = ({ unitDetails }: IUnitRendererProps) => {
  const [updateUnitMutation] = useUpdateUnitMutation();
  const { currentCommunityId: communityId = "" } = useUserContext();

  const {
    data: units,
    loading: unitsLoading,
    error: unitsError,
  } = useUnitsQuery({
    variables: {
      communityId: communityId,
    },
  });

  const existingUnitNames = React.useMemo((): string[] => {
    return units?.communityUnits?.map(unit => unit?.name.toLowerCase() ?? "") ?? [];
  }, [units]);

  const onEdit = React.useCallback(async () => {
    const name = window.prompt(`Pleas Enter the unit name:`, unitDetails.name);
    if (!name) {
      return;
    }
    if (isUnitNameAlreadyExists(existingUnitNames ?? [], name)) {
      window.alert(`Unable to update. A unit with name ${name} already exists!`);
      return;
    }

    await updateUnitMutation({
      variables: {
        input: {
          id: unitDetails.id,
          name
        }
      },
      refetchQueries: [
        {
          query: unitsQuery,
          variables: {
            communityId: unitDetails.community,
          },
        },
      ],
    });
  }, [unitDetails, updateUnitMutation]);
  const {
    launchRemoveAllTenantsDialog,
    onRemoveAllTenantsDialogClose,
    showRemoveAllTenantsDialog,
  } = useRemoveAllTenantsDialog();

  const {
    launchUpdateExistingUnitOwnerDialog,
    onUpdateExistingUnitOwnerDialogClose,
    showUpdateExistingUnitOwnerDialog,
  } = useUpdateExistingUnitOwnerDialog();

  const {
    launchCreateNewOwnerDialog,
    onCreateNewOwnerDialogClose,
    showCreateNewOwnerDialog,
  } = useCreateNewOwnerDialog();

  const {
    launchCreateNewTenantDialog,
    onCreateNewTenantDialogClose,
    showCreateNewTenantDialog,
  } = useCreateNewTenantDialog();
  const { id } = unitDetails;
  const onOwnerRemove = useOnUserRemoveCallback("owner", id);
  const onTenantRemove = useOnUserRemoveCallback("tenant", id);

  return (
    <>
      <PageBanner
        title="Unit Details"
        icon={<TenantPersonalIcon size="large" style={{ padding: 10 }} />}
      />
      <Flex column>
        <Flex>
          <Header
            as="h2"
            content={<><Text content={`${unitDetails.name} `} /><EditIcon onClick={onEdit} style={{ color: "grey", paddingBottom: 5 }} /></>}
          />

          <FlexItem push>
            <UnitMenu
              unitDetails={unitDetails}
              launchCreateNewOwnerDialog={launchCreateNewOwnerDialog}
              launchUpdateExistingUnitOwnerDialog={
                launchUpdateExistingUnitOwnerDialog
              }
              launchRemoveAllTenantsDialog={launchRemoveAllTenantsDialog}
              launchCreateNewTenantDialog={launchCreateNewTenantDialog}
            />
          </FlexItem>
        </Flex>
        <Text
          size="small"
          content={`${getFormattedAddress(unitDetails.address)}`}
        />
      </Flex>
      <Flex space="between" column>
        <Flex hAlign="center">
          <Flex column style={{ width: "100%" }} space="between">
            <Flex column>
              <Divider content="Ownership" />
              <Box>
                <UserDetailsPreviewCardRenderer
                  users={unitDetails.owners as any} //TODO: try avaoiding force casting
                  removeButtonTootTipText="Remove Owner"
                  onRemove={onOwnerRemove}
                />
              </Box>
            </Flex>

            {isTenantOccupancy(unitDetails) && (
              <Flex column>
                <Divider content="Tenancy" />
                <Box>
                  <UserDetailsPreviewCardRenderer
                    users={unitDetails.tenants as any} //TODO: try avaoiding force casting
                    removeButtonTootTipText="Remove Tenant"
                    onRemove={onTenantRemove}
                  />
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      {showCreateNewOwnerDialog && (
        //TODO: need
        <CreateNewOwnerDialog
          open={true}
          unitId={unitDetails.id}
          onClose={onCreateNewOwnerDialogClose}
          communityId={communityId}
        ></CreateNewOwnerDialog>
      )}
      {showUpdateExistingUnitOwnerDialog && (
        <UpdateExistingUnitOwnerDialog
          open={true}
          unitId={unitDetails.id}
          onClose={onUpdateExistingUnitOwnerDialogClose}
          communityId={communityId}
        ></UpdateExistingUnitOwnerDialog>
      )}

      {showRemoveAllTenantsDialog && (
        <RemoveAllTenantsConfirmDialog
          open={true}
          unitId={unitDetails.id}
          onClose={onRemoveAllTenantsDialogClose}
          communityId={communityId}
        ></RemoveAllTenantsConfirmDialog>
      )}
      {showCreateNewTenantDialog && (
        <CreateNewTenantDialog
          open={true}
          unitId={unitDetails.id}
          onClose={onCreateNewTenantDialogClose}
          communityId={communityId}
        ></CreateNewTenantDialog>
      )}
    </>
  );
};

interface IUnitMenuProps extends FlexItemStyleProp {
  launchCreateNewOwnerDialog: () => void;
  launchUpdateExistingUnitOwnerDialog: () => void;
  launchRemoveAllTenantsDialog: () => void;
  launchCreateNewTenantDialog: () => void;
  unitDetails: UnitDetails;
}

const UnitMenu: React.FC<IUnitMenuProps> = ({
  launchCreateNewOwnerDialog,
  launchUpdateExistingUnitOwnerDialog,
  launchRemoveAllTenantsDialog,
  launchCreateNewTenantDialog,
  unitDetails,
  ...rest
}: IUnitMenuProps) => {
  const ownershipSubMenu = {
    key: "0",
    content: "Ownership",
    menu: [
      {
        key: "0",
        content: (
          <Button
            text
            size="small"
            content="Create New Owner"
            icon={<ParticipantAddIcon />}
            onClick={() => launchCreateNewOwnerDialog()}
          />
        ),
      },
      {
        key: "1",
        content: (
          <Button
            text
            size="small"
            content="Update from Existing Residents"
            icon={<TenantPersonalIcon />}
            onClick={() => launchUpdateExistingUnitOwnerDialog()}
          />
        ),
      },
    ],
  };

  const tenanancySubMenu = {
    key: 1,
    content: "Tenancy",
    menu: [
      {
        key: "1",
        content: (
          <Button
            disabled={isEmpty(unitDetails.owners)}
            text
            size="small"
            content="Create New Tenant"
            icon={<AddIcon outline />}
            onClick={() => launchCreateNewTenantDialog()}
          />
        ),
      },
      {
        key: "2",
        content: (
          <Button
            disabled={isEmpty(unitDetails.tenants)}
            text
            size="small"
            content="Remove All Tenants"
            icon={<TrashCanIcon outline />}
            onClick={() => launchRemoveAllTenantsDialog()}
          />
        ),
      },
    ],
  };
  const menu = isEmpty(unitDetails.owners)
    ? [ownershipSubMenu]
    : [ownershipSubMenu, tenanancySubMenu];
  return (
    <MenuButton
      {...rest}
      trigger={<Button icon={<MoreIcon />} />}
      menu={menu}
      style={{ padding: 10 }}
    />
  );
};

export const isUnitNameAlreadyExists = (
  existingUnitNames: string[],
  newUnitName: string): boolean =>
  some(
    existingUnitNames,
    (existingUnitName) =>
      existingUnitName === newUnitName.toLocaleLowerCase()
  );

export const Unit = UnitContainer;
