import {
  AddIcon,
  Box,
  Button,
  Dropdown,
  Flex,
  FlexItem,
  Header,
  Input,
  Loader,
  SearchIcon,
  Table,
  Text,
  ToDoListIcon,
} from "@fluentui/react-northstar";
import { RouteComponentProps } from "@reach/router";
import { filter, isEmpty, keyBy } from "lodash";
import React from "react";
import { Column, Row, usePagination, useTable } from "react-table";
import { tasks as tasksQuery } from "../data/queries";
import {
  Task,
  Unit,
  useDeleteTaskMutation,
  User,
  useTasksQuery,
  useUnitsQuery,
  useUsersQuery,
} from "../data/__generated__/client-graphql-types";
import { CreateNewTaskDialog } from "../modals/CreateNewTaskDialog";
import { useUserContext } from "../UserContext";
import { ApplicationError } from "./ApplicationError";
import { PageBanner } from "./PageBanner";
import { TablePaginationRenderer } from "./TablePaginationRenderer";
import { Tabs } from "./Tabs";
import { TaskPreviewCardRenderer } from "./TaskPreviewCardRenderer";

const pageSizeOptions = [10, 20, 50];
const OPEN_TAB_INDEX = 0;
const CLOSED_TAB_INDEX = 1;
const useCreateNewTaskDialog = () => {
  const [
    showCreateNewTaskDialog,
    setShowCreateNewTaskDialog,
  ] = React.useState<boolean>(false);

  const launchCreateNewTaskDialog = React.useCallback(() => {
    setShowCreateNewTaskDialog(true);
  }, [setShowCreateNewTaskDialog]);

  const onCreateNewTaskDialogClose = React.useCallback(() => {
    setShowCreateNewTaskDialog(false);
  }, [setShowCreateNewTaskDialog]);
  return {
    launchCreateNewTaskDialog,
    onCreateNewTaskDialogClose,
    showCreateNewTaskDialog,
  };
};

export const Tasks = (
  props: RouteComponentProps & {
    children: {};
  }
) => <>{props.children}</>;

export const TaskDashboard = (props: RouteComponentProps) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const { currentCommunityId: communityId = "" } = useUserContext();
  const {
    data: tasksData,
    loading: tasksLoading,
    error: tasksError,
  } = useTasksQuery({
    variables: {
      communityId,
    },
  });
  const tasks = tasksData?.communityTasks as Task[];

  const {
    launchCreateNewTaskDialog,
    onCreateNewTaskDialogClose,
    showCreateNewTaskDialog,
  } = useCreateNewTaskDialog();
  const {
    data: units,
    loading: unitsLoading,
    error: unitsError,
  } = useUnitsQuery({
    variables: {
      communityId,
    },
  });

  const {
    data: users,
    loading: usersLoading,
    error: usersError,
  } = useUsersQuery({
    variables: {
      communityId,
    },
  });
  const loading = tasksLoading || unitsLoading || usersLoading;
  const error = !!tasksError || !!unitsError || !!usersError;
  return loading ? (
    <Loader />
  ) : error ? (
    <ApplicationError />
  ) : (
    <>
      <PageBanner
        title={"Tasks Dashboard"}
        icon={<ToDoListIcon size="large" style={{ padding: 10 }} />}
      />
      <br />
      <Flex style={{ marginBottom: 16 }}>
        <FlexItem push>
          <Button
            icon={<AddIcon />}
            content="New Task"
            iconPosition="before"
            onClick={() => launchCreateNewTaskDialog()}
            primary
          />
        </FlexItem>
      </Flex>
      <TasksRenderer
        tasks={tasks}
        units={units?.communityUnits as Unit[]}
        users={users?.communityUsers as User[]}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {showCreateNewTaskDialog && (
        <CreateNewTaskDialog
          open={true}
          onClose={onCreateNewTaskDialogClose}
          communityId={communityId}
        ></CreateNewTaskDialog>
      )}
    </>
  );
};

const TasksSearchFilterRenderer = (props: {}) => {
  return (
    <Input
      fluid
      icon={<SearchIcon />}
      placeholder="Search tasks..."
      iconPosition="end"
    />
  );
};

/**
 * @param props Display controlls includes sortBy, filterBy etc....
 */
const SearchResultsDisplayControls = (props: {}) => {
  return null;
};

const sortByforOpenItems = ["Id", "Open Date"];
const TasksSearchResultsRenderer = ({
  tasks,
  activeTabIndex,
  setActiveTabIndex,
  units,
  users,
}: {
  tasks: Task[];
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  units: Unit[];
  users: User[];
}) => {
  const { currentCommunityId: communityId = "" } = useUserContext();

  const usersDirectory = keyBy(users, "id");
  const [
    deleteTask,
    { loading: deleteMutationLoading },
  ] = useDeleteTaskMutation();

  const onTaskDelete = React.useCallback((task: Task) => {
    const isConfirm = window.confirm("Would you like to delete the task?");

    // TODO: use loading and error
    // disable users who don't occupy any units or tenants anymore
    if (!isConfirm) {
      return;
    }
    deleteTask({
      variables: {
        taskId: task.id,
      },
      refetchQueries: [
        {
          query: tasksQuery,
          variables: {
            communityId,
          },
        },
      ],
    });
  }, []);

  const tableData = React.useMemo(() => {
    return {
      columns: [
        {
          Header: "Tasks Preview",
          id: "preview",
          minWidth: 500,
          accessor: (task: Task) => task.id,
          Cell: ({ cell }: any) => {
            const task = cell.row.original;
            const user = !!task.closedBy
              ? usersDirectory[task.closedBy]
              : usersDirectory[task.createdBy];
            return (
              <Flex gap="gap.small" column>
                <TaskPreviewCardRenderer
                  task={task}
                  units={units}
                  user={user}
                />
                {/* {task.status === "open" ? (
                  <Button
                    icon={<CloseTaskIcon />}
                    iconOnly
                    content={
                      <Tooltip
                        content="Mark Done"
                        trigger={
                          <Text
                            color="brand"
                            content="DONE"
                            size="small"
                            weight="semibold"
                          />
                        }
                      />
                    }
                    text
                    size="small"
                    style={{
                      justifyContent: "left",
                      marginLeft: 30,
                      width: 75,
                      backgroundColor: "#e8e7e7",
                    }}
                    onClick={() => onTaskClose(task)}
                  />
                ) : (
                  <Button
                    icon={<ReopenTaskIcon />}
                    iconOnly
                    content={
                      <Tooltip
                        content="Reopen Task"
                        trigger={
                          <Text
                            color="brand"
                            content="REOPEN"
                            size="small"
                            weight="semibold"
                          />
                        }
                      />
                    }
                    text
                    size="small"
                    style={{
                      justifyContent: "left",
                      marginLeft: 30,
                      width: 80,
                      backgroundColor: "#e8e7e7",
                    }}
                    onClick={() => onTaskReopen(task)}
                  />
                )} */}
                {/* <Button
                icon={<RemoveTaskIcon />}
                iconOnly
                title="Delete"
                onClick={() => onTaskDelete(cell.row.original)}
              /> */}
              </Flex>
            );
          },
        },
      ],
      tasks,
    };
  }, [tasks]);

  const onTabSwitch = React.useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);
  const { columns, tasks: tableDataTasks } = tableData;

  if (!tasks) return null;
  const mutationLoading = deleteMutationLoading;
  return mutationLoading ? (
    <Loader />
  ) : isEmpty(tasks) ? (
    <Header as="h3" content="Currently there are no tasks created!" />
  ) : (
    <Flex column style={{ padding: 20 }}>
      <Flex space="between">
        <Tabs
          tabTitles={["Open", "Closed"]}
          onTabSwitch={onTabSwitch}
          defaultActiveIndex={0}
          underlined={false}
          pointing={true}
        />
        <Box>
          <Text content="Sort By" color="brand" />
          <Dropdown
            style={{ paddingTop: 3, paddingLeft: 10 }}
            defaultActiveSelectedIndex={0}
            activeSelectedIndex={1}
            inline
            items={sortByforOpenItems}
            color="brand"
          />
        </Box>
      </Flex>
      <FluentReactSelectableTasksList
        columns={columns}
        tasks={tableDataTasks}
        activeTabIndex={activeTabIndex}
      />
    </Flex>
  );
};
const tableHooks = [usePagination];

const FluentReactSelectableTasksList = ({
  columns,
  tasks,
  activeTabIndex,
}: {
  columns: Column<Task>[]; // TODO: try to avoid this!!
  tasks: Task[];
  activeTabIndex: number;
}) => {
  const tableOptions = React.useMemo(
    () => ({
      columns: columns,
      data: filterByStatus(
        tasks,
        activeTabIndex === OPEN_TAB_INDEX ? "open" : "closed"
      ),
      initialState: { pageIndex: 0, pageSize: pageSizeOptions[0] },
    }),
    [columns, activeTabIndex]
  );
  const tableInstance = useTable(tableOptions, ...tableHooks);

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  const getRowCells = (row: Row<Task>) => {
    return row.cells.map((cell, index) => {
      let content = cell.render("Cell");
      return {
        key: index,
        content,
        style: {
          width: "100%",
        },
        variables: { isFullRowCell: true },
      };
    });
  };

  const tableRows = page.map((row, i) => {
    prepareRow(row);
    return {
      key: i,
      items: getRowCells(row),
    };
  });

  // Render the UI for your table
  return (
    <Flex style={{ paddingBottom: 10 }} column>
      <Table>
        {tableRows.map((row) => {
          return (
            <Table.Row style={{ height: "auto", width: "100%" }}>
              <Table.Cell
                content={row.items[0].content}
                variables={{ isFullRowCell: true }}
              />
            </Table.Row>
          );
        })}
      </Table>
      <TablePaginationRenderer<Task>
        {...{
          page,
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          pageSizeOptions,
        }}
      />
    </Flex>
  );
};

const TasksRenderer = ({
  tasks,
  activeTabIndex,
  setActiveTabIndex,
  units,
  users,
}: {
  tasks: Task[];
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  units: Unit[];
  users: User[];
}) => {
  return (
    <Flex column>
      <TasksSearchFilterRenderer />
      <TasksSearchResultsRenderer
        tasks={tasks}
        units={units}
        users={users}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
    </Flex>
  );
};

const filterByStatus = (tasks: Task[], status: "open" | "closed") => {
  return filter(tasks, (task) => task.status === status);
};
