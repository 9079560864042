import "./dev/wdyr"; // <--- first import DEV- ONLY. remove in production

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { RouteComponentProps, Router } from "@reach/router";
import React from "react";
import ReactDOM from "react-dom";
import { resolvers } from "./data/client-resolvers";
import * as clientSideSchema from "./data/client-graphql-schema";
import HomePage from "./components/HomePage";
import { Sidebar } from "./components/Sidebar";
import MainScreen from "./components/MainScreen";
import { Box, Debug, Flex, Header } from "@fluentui/react-northstar";
import { UnitsTable } from "./components/UnitsTable";
import { ResidentsTable } from "./components/ResidentsTable";
import { Communities } from "./components/Communities";
import { FluentUIStyleProvider } from "./FluentUIStyleProvider";
import { Residents } from "./components/Residents";
import { Resident } from "./components/Resident";
import { Units } from "./components/Units";
import { Unit } from "./components/Unit";
import { TaskDashboard, Tasks } from "./components/Tasks";
import { TaskDetails } from "./components/TaskDetails";
import { UserContextProvider, useUserContext } from "./UserContext";
import { PageBanner } from "./components/PageBanner";
import { ApplicationComingSoon } from "./components/ApplicationComingSoon";
import { useSelectCommunityEffect } from "./components/useSelectCommunityEffect";
import { LoginSetup } from "./components/LoginSetup";
import PrivateRoute from "./PrivateRoute";
import { Feedback } from "./Feedback";
import { Admin } from "./components/Admin";
import { Board } from "./components/Board";
// Set up our apollo-client to point at the server we created
// this can be local or a remote endpoint
const cache = new InMemoryCache();
const ngrokHosname = "http://hoagraph.ngrok.io";
const localhostName = "http://localhost:8881";
const cloudhostName = "https://hoa-graph-sanssoucci.azurewebsites.net/";
// TODO: consume server port from env file
export const client: ApolloClient<object> = new ApolloClient({
  cache,
  connectToDevTools: true,
  uri: `${cloudhostName}/graphql`,
  headers: {
    authorization: localStorage.getItem("token") ?? "UNKNOWN",
    "client-name": "hoa-portal-client",
    "client-version": "1.0.0",
  },
  resolvers,
  typeDefs: clientSideSchema as any,
});

const NotFound = (props: RouteComponentProps) => (
  <Flex vAlign="center">Something Went Wrong</Flex>
);

const Vendors = (props: RouteComponentProps) => (
  <>
    <PageBanner title={"Vendors"} />
    <div
      style={{
        display: "block",
        margin: "auto",
        width: "40%",
      }}
    >
      <ApplicationComingSoon />
    </div>
  </>
);

const Meetings = (props: RouteComponentProps) => (
  <>
    <PageBanner title={"Meetings"} />
    <div
      style={{
        display: "block",
        margin: "auto",
        width: "40%",
      }}
    >
      <ApplicationComingSoon />
    </div>
  </>
);

const CoreApp = () => {
  const userContext = useUserContext();
  const [reload, setReload] = React.useState(false);
  useSelectCommunityEffect(setReload, reload);
  return (
    <Box className="App">
      <Flex>
        <Router
          style={{
            position: "relative",
            top: 0,
            float: "left",
            height: "100%",
          }}
        >
          <NotFound default />
          <Sidebar
            setReload={setReload}
            reload={reload}
            userFirstName={userContext.currentUser?.firstName ?? ""}
            communityName={userContext.currentCommunity?.name ?? ""}
            path="*"
          ></Sidebar>
        </Router>
      </Flex>
      <MainScreen>
        <Router style={{ overflowY: "scroll" }}>
          <NotFound default />
          <HomePage path="/" setReload={setReload} reload={reload} />
          <LoginSetup path="/login/setup/:setupId" />
          <Communities path="/communities" />
          <PrivateRoute as={Units} path="/units">
            <PrivateRoute as={UnitsTable} path="/" />
            <PrivateRoute as={Unit} path=":unitId" />
          </PrivateRoute>
          <PrivateRoute as={Residents} path="/residents">
            <PrivateRoute as={ResidentsTable} path="/" />
            <PrivateRoute as={Resident} path=":userId" />
          </PrivateRoute>
          <PrivateRoute as={Vendors} path="/vendors" />
          <PrivateRoute as={Tasks} path="/tasks">
            <PrivateRoute as={TaskDashboard} path="/" />
            <PrivateRoute as={TaskDetails} path=":communityTaskId" />
          </PrivateRoute>
          <PrivateRoute as={Meetings} path="/meetings" />
          <PrivateRoute as={Board} path="/board" />
          <PrivateRoute as={Admin} path="/admin" />
          <PrivateRoute as={Feedback} path="/feedback" />
        </Router>
      </MainScreen>
    </Box>
  );
};

const App = () => {
  return (
    <FluentUIStyleProvider>
      <ApolloProvider client={client}>
        <UserContextProvider>
          <CoreApp />
        </UserContextProvider>
      </ApolloProvider>
    </FluentUIStyleProvider>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));
