import gql from "graphql-tag";
import * as fragments from "../fragments";

export default gql`
  query communityUsersLoginDetails($communityId: ID!) {
    communityUsersLoginDetails(communityId: $communityId) {
      ...UserLoginDetails
    }
  }
  ${fragments.userLoginDetails}
`;
