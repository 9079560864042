import {
  ComponentSlotStylesPrepared,
  TeamsThemeStylesProps,
} from "@fluentui/react-northstar";

export const BoxStyles: ComponentSlotStylesPrepared<
  NonNullable<TeamsThemeStylesProps["Box"]>,
  any
> = {
  root: ({
    props: {},
    variables: { isFullRowCell },
    theme: {
      siteVariables: { colors, borderWidth },
    },
  }) => ({
    ...(isFullRowCell && {
      width: "100%",
      minWidth: "100%",
    }),
  }),
};
