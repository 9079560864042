import gql from 'graphql-tag';
import * as fragments from '../fragments';

export default gql`
  query Communities {
    communities {
      ...Community
    }
  }
  ${fragments.community}
`;
