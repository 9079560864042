import { useApolloClient } from "@apollo/client";
import { Box, Text, Button, EditIcon, EmailIcon, Flex, Header, Loader, SpeakerPersonIcon, Table, Label } from "@fluentui/react-northstar";
import { navigate, RouteComponentProps } from "@reach/router";
import { camelCase, filter, includes, isEmpty, pull, startCase } from "lodash";
import React from "react";
import { Column, Row, useTable } from "react-table";
import { getFormattedName } from "../app-helper";
import { Email } from "./Email";
import { PageBanner } from "./PageBanner";
import usersQuery from "../data/queries/users.query";
import { Maybe, Unit, User, useUnitsQuery, useUpdateUserMutation, useUsersQuery } from "../data/__generated__/client-graphql-types";
import { useUserContext } from "../UserContext";
import { UnitNavButton } from "./UnitNavButton";

export const Board = (props: RouteComponentProps) => {
    const { currentCommunityId: communityId = "" } = useUserContext();

    const {
        data: units,
        loading: unitsLoading,
        error: unitsError,
    } = useUnitsQuery({
        variables: {
            communityId: communityId,
        },
    });
    const {
        data: users,
        loading: usersLoading,
        error: usersError,
    } = useUsersQuery({
        variables: {
            communityId: communityId,
        },
    });
    const boardMembers = filter(users?.communityUsers as User[], (user: User) => { return user.roles.includes("BOARD_MEMBER") })

    return <>
        <PageBanner
            title="Board"
            icon={<SpeakerPersonIcon size="large" style={{ padding: 10 }} />}
        />
        {usersLoading || unitsLoading ? <Loader /> : isEmpty(boardMembers) ? <Text content="There are no board members currently. You can update board member role only from admin screen." /> : !isEmpty(communityId) ? (
            <BoardTableRenderer
                units={units?.communityUnits as Unit[]} // TODO: Remove forecasting if possible
                users={boardMembers}
            />
        ) : null}
    </>
}

export const BoardTableRenderer = ({
    units,
    users,
}: {
    units: Unit[];
    users: User[];
}) => {
    const [updateUserMutation] = useUpdateUserMutation();
    const { currentCommunityId = "" } = useUserContext();

    //TODO: scope to optimize. Write to cache instead of always computing this userIds map
    const client = useApolloClient();
    const columns = React.useMemo(
        (): Column<User>[] => [
            {
                Header: "Name",
                id: "name",
                accessor: (user: User, rowIndex) =>
                    getFormattedName(user?.firstName, user?.lastName),
                Cell: ({ cell }) => (
                    <Button
                        primary
                        fluid
                        onClick={async (event: any) => {
                            //TODO: optimiize
                            event.stopPropagation();
                            await navigate(`/residents/${cell.row.original.id}`);
                        }}
                    >
                        {startCase(camelCase(cell.value))}
                    </Button>
                ),
            },
            {
                Header: "Email",
                id: "email",
                Cell: ({ cell }) => (
                    <Flex gap="gap.small">
                        <EmailIcon outline />
                        <Email address={cell.row.original.contactDetails.email} isLinked={true} />
                    </Flex>
                ),
            },
            {
                Header: "Units Owned",
                id: "units",
                accessor: (user: User, rowIndex) => {
                    const units: Maybe<Unit>[] = [];
                    const { roles, unitsOwned } = user;
                    if (includes(roles, "OWNER")) {
                        units.push(...user.unitsOwned);
                    }
                    return units;
                },
                Cell: ({ cell: { value } }: any) => (
                    <Flex>
                        {value?.map((unit: Unit) => (
                            <UnitNavButton unit={unit} />
                        ))}
                    </Flex>

                ),
            },

            {
                Header: "Title",
                Cell: ({ cell }: any) => {
                    const user = cell.row.original;
                    const onEdit = React.useCallback(async () => {
                        const title = window.prompt(`Pleas Enter the title`);
                        if (!title) {
                            return;
                        }

                        await updateUserMutation({
                            variables: {
                                input: {
                                    id: user.id,
                                    boardMemberDetails: {
                                        title
                                    }
                                }
                            },
                            refetchQueries: [
                                {
                                    query: usersQuery,
                                    variables: {
                                        communityId: currentCommunityId,
                                    },
                                },
                            ],
                        });
                    }, [user, updateUserMutation]);
                    return <>{user.boardMemberDetails && user.boardMemberDetails.title && <Label content={user.boardMemberDetails.title} />} <EditIcon size="small" onClick={onEdit} /></>;
                },
            },
            {
                Header: "Remove",
                Cell: ({ cell }) => {
                    const user = cell.row.original;

                    const onRemove = React.useCallback(async () => {
                        const isConfirm = window.confirm(`Would you like to remove:${getFormattedName(user.firstName, user.lastName)} from the Board?`);
                        if (!isConfirm) {
                            return;
                        }
                        const newRoles = pull([...user.roles], "BOARD_MEMBER")

                        await updateUserMutation({
                            variables: {
                                input: {
                                    id: user.id,
                                    roles: newRoles,
                                    boardMemberDetails: null
                                }
                            },
                            refetchQueries: [
                                {
                                    query: usersQuery,
                                    variables: {
                                        communityId: currentCommunityId,
                                    },
                                },
                            ],
                        });
                    }, [user, updateUserMutation]);
                    return <Button content="Remove" text primary title="Remove from board" onClick={onRemove} />
                }
            },

        ],
        [users]
    );
    if (isEmpty(units) || isEmpty(users)) {
        return null;
    }
    return (
        <>
            <FluentReactBoardTable columns={columns} users={users} />
        </>
    );
};


const FluentReactBoardTable = ({
    columns,
    users,
}: {
    columns: Column<User>[]; // TODO: try to avoid this!!
    users: User[];
}) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable<User>({
        columns: columns,
        data: users,
    });

    const getRowCells = (row: Row<User>) => {
        return row.cells.map((cell, index) => {
            let content = cell.render("Cell");
            return {
                key: index,
                content,
            };
        });
    };
    const headers = {
        items: headerGroups[0].headers.map((column) => (
            <Header as="h3">{column.render("Header")}</Header>
        )),
    };

    const tableRows = rows.map((row, i) => {
        prepareRow(row);

        return {
            key: i,
            items: getRowCells(row),
        };
    });


    // Render the UI for your table
    return <Table header={headers} rows={tableRows} aria-label="board table" />;
};
