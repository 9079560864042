import gql from 'graphql-tag';
import * as fragments from '../fragments';

export default gql`
  query Users($communityId: ID!) {
    communityUsers(communityId: $communityId) {
      ...User
    }
  }
  ${fragments.user}
`;