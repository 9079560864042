import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import {
  Button,
  Form,
  FormField,
  Text,
  Input,
  Header,
  Flex,
  GeofenceArrivesIcon,
} from "@fluentui/react-northstar";
import { useUserContext } from "../UserContext";
import { useApolloClient } from "@apollo/client";
import {
  AccessTokenDocument,
  AccessTokenQuery,
  AccessTokenQueryVariables,
} from "../data/__generated__/client-graphql-types";
import { getUserFromToken } from "../app-helper";
const HomePage = React.memo(
  ({
    setReload,
    reload,
  }: RouteComponentProps & {
    setReload: React.Dispatch<React.SetStateAction<boolean>>;
    reload: boolean;
  }) => {
    const { currentUser } = useUserContext();
    React.useEffect(() => {
      const navigateToUnits = async () => {
        await navigate("/units");
      };
      if (currentUser) {
        navigateToUnits().then(() => { });
      }
    });
    return <SignInForm setReload={setReload} reload={reload} />;
  }
);

const SignInForm = ({
  setReload,
  reload,
}: {
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
}) => {
  const headerDisplayName = "Sign In";
  const client = useApolloClient();
  const userContext = useUserContext();
  const [loginIdInput, setLoginIdInput] = React.useState<string>("");
  const [passwordInput, setPasswordInput] = React.useState<string>("");

  const onLoginIdInputChange = React.useCallback(
    (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setLoginIdInput(input.value);
    },
    [setLoginIdInput]
  );

  const onPasswordInputChange = React.useCallback(
    (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setPasswordInput(input.value);
    },
    [setLoginIdInput]
  );

  return (
    <>
      <Header
        content={
          <Flex column>
            <Flex vAlign="center">
              <GeofenceArrivesIcon
                size="larger"
                rotate={-90}
                styles={{
                  color: "#6264a7",
                }}
              />
              <Text
                color="brand"
                content={headerDisplayName}
                size="larger"
                weight="semibold"
                style={{ paddingTop: 10, paddingLeft: 10 }}
              />
            </Flex>
          </Flex>
        }
        style={{ marginTop: 100 }}
      />

      <Flex
        style={{
          minHeight: "100vh",
          alignItems: "flex-start",
          width: "70%",
        }}
        column
      >
        <Form
          onSubmit={async () => {
            const { data } = await client.query<
              AccessTokenQuery,
              AccessTokenQueryVariables
            >({
              query: AccessTokenDocument,
              variables: {
                loginId: loginIdInput,
                password: passwordInput,
              },
              fetchPolicy: "no-cache",
            });
            if (data && data.accessToken?.statusCode === 200) {
              window.localStorage.token = data.accessToken.accessToken;

              userContext.currentUser = getUserFromToken(
                data.accessToken.accessToken ?? ""
              );
              userContext.currentCommunityId =
                userContext.currentUser.community;
              await navigate("/units");
              setReload(!reload);
            } else {
              window.alert(data?.accessToken?.failureReason);
            }
            // TODO display loader
          }}
          style={{
            padding: 25,
            border: "solid 1px #d7d7d7",
            borderRadius: 1,
          }}
        >
          <FormField
            label="Login Email"
            name="loginEmail"
            id="login-email"
            required
            onChange={onLoginIdInputChange}
            control={{
              as: Input,
              fluid: true,
              showSuccessIndicator: false,
            }}
          />
          <FormField
            label="Password"
            name="password"
            id="password"
            required
            onChange={onPasswordInputChange}
            control={{
              as: Input,
              fluid: true,
              type: "password",
              showSuccessIndicator: false,
            }}
          />
          <Text
            color="brand"
            content="Please contact admin@sansoucci.com to request an account."
          />
          <FormField
            control={{
              as: Button,
              content: "Login",
            }}
          />
        </Form>
      </Flex>
    </>
  );
};

export default HomePage;
