import {
  Alert,
  Button,
  EditIcon, EmailIcon,
  Flex,
  Label,
  Loader, SpotlightIcon,
  Table,
  Text,
  UserPhoneIcon
} from "@fluentui/react-northstar";
import { navigate, RouteComponentProps } from "@reach/router";
import { camelCase, startCase } from "lodash";
import React from "react";
import { getFormattedAddress, getFormattedName } from "../app-helper";
import userDetailsQuery from "../data/queries/userDetails.query";
import usersQuery from "../data/queries/users.query";
import {
  UnitBasicDetails, UserDetails,
  useUpdateUserMutation, useUserDetailsQuery
} from "../data/__generated__/client-graphql-types";
import { Email } from "./Email";
import { PageBanner } from "./PageBanner";
import { PhoneNumber } from "./PhoneNumber";
import { Tabs } from "./Tabs";


interface IUserRendererProps {
  userDetails: UserDetails;
}
interface IResidentProps extends RouteComponentProps {
  userId?: string;
}

const ResidentContainer = (props: IResidentProps) => {
  const { userId = "" } = props;
  const { data, loading, error } = useUserDetailsQuery({
    variables: {
      userId,
    },
  });

  if (loading) {
    return <Loader size="medium" label="Loading..." labelPosition="below" />;
  }

  if (!loading && error) {
    console.error(
      `Unable to find the resident details for the user id: ${userId}`,
      error
    );
    return <Text>{"Unable to find the resident"}</Text>;
  }

  return <UserRenderer userDetails={data?.userDetails as UserDetails} />;
};

const UserRenderer = ({ userDetails }: IUserRendererProps) => {
  const tabsTitles: string[] = ["Profile", "Tasks"];
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const onTabSwitch = React.useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);
  return (
    <>
      <PageBanner
        title="Resident Details"
        icon={<SpotlightIcon size="large" style={{ padding: 10 }} />}
      />

      <Tabs
        defaultActiveIndex={0}
        tabTitles={tabsTitles}
        onTabSwitch={onTabSwitch}
        pointing={false}
      />
      {activeTabIndex === 0 ? (
        <ResidentProfileDetails details={userDetails} />
      ) : (
          <ResidentTask details={userDetails} />
        )}
    </>
  );
};

const ResidentProfileDetails = ({ details }: { details: UserDetails }) => {
  const [updateUserMutation] = useUpdateUserMutation();
  const onEdit = React.useCallback(async (field, oldValue) => {

    if (field === "email" && details.loginEnabled) {
      const isConfirm = window.confirm("Updating email may break user's login. Would like to continue?");
      if (!isConfirm) {
        return;
      }
    }

    const newValue = window.prompt(`Pleas Enter the ${field}`, oldValue);
    if (!newValue) {
      return;
    }

    if (newValue === oldValue) {
      return;
    }
    let input: any = {
      id: details.id,
      [field]: newValue
    };

    if (field === "phone") {
      input = {
        id: details.id,
        contactDetails: {
          phone: newValue,
          email: details.contactDetails.email
        }
      }
    }
    else if (field === "email") {
      input = {
        id: details.id,
        contactDetails: {
          phone: details.contactDetails.phone,
          email: newValue
        }
      }
    }
    await updateUserMutation({
      variables: {
        input
      },
      refetchQueries: [
        {
          query: usersQuery,
          variables: {
            communityId: details.community,
          },
        },
        {
          query: userDetailsQuery,
          variables: {
            userId: details.id,
          },
        },
      ],
    });


    if (field === "email" && details.loginEnabled) {
      window.alert(`Please make to notify: ${getFormattedName(details.firstName, details.lastName)} that login id has changed to ${details.contactDetails.email}?`)
    }
  }, [details, updateUserMutation]);
  return (
    <Flex column>
      <Table>
        <Table.Row>
          <Table.Cell content="First Name" />
          <Table.Cell content={details.firstName} />
          <Table.Cell content={<EditIcon onClick={() => onEdit("firstName", details.firstName)} />} />
        </Table.Row>
        <Table.Row>
          <Table.Cell content="Last Name" />
          <Table.Cell content={details.lastName} />
          <Table.Cell content={<EditIcon onClick={() => onEdit("lastName", details.lastName)} />} />
        </Table.Row>
        <Table.Row>
          <Table.Cell content="Phone" />
          <Table.Cell
            content={
              <Flex gap="gap.small">
                <UserPhoneIcon outline />
                <PhoneNumber
                  number={details.contactDetails.phone}
                  isLinked={true}
                />
              </Flex>
            }
          />
          <Table.Cell content={<EditIcon onClick={() => onEdit("phone", details.contactDetails.phone)} />} />

        </Table.Row>
        <Table.Row>
          <Table.Cell content="Email" />
          <Table.Cell
            content={
              <Flex gap="gap.small">
                <EmailIcon outline />
                <Email address={details.contactDetails.email} isLinked={true} />
              </Flex>
            }
          />
          <Table.Cell content={<EditIcon onClick={() => onEdit("email", details.contactDetails.email)} />} />

        </Table.Row>
        <Table.Row>
          <Table.Cell content="Address" />
          <Table.Cell content={getFormattedAddress(details.address)} />
          <Table.Cell content={" "} />
        </Table.Row>
        {details.roles && (
          <Table.Row>
            <Table.Cell content="Roles" />
            <Table.Cell
              content={getRoleTags(
                details.roles.map((role) => getFormattedName(role))
              )}
            />
            <Table.Cell content={""} />
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell content="Units Owned" />
          <Table.Cell
            content={
              <Flex gap="gap.small">
                {getUnitButtons(details.unitsOwned as UnitBasicDetails[])}
              </Flex>
            }
          />
          <Table.Cell content={""} />

        </Table.Row>
      </Table>
    </Flex>
  );
};

const getUnitButtons = (units: UnitBasicDetails[]) =>
  units.map((unit) => (
    <Button
      primary
      fluid
      onClick={async (event: any) => {
        //TODO: optimiize
        event.stopPropagation();
        await navigate(`/units/${unit.id}`);
      }}
    >
      {startCase(camelCase(unit.name ?? ""))}
    </Button>
  ));

export const getRoleTags = (roles: string[]) => (
  <Flex gap="gap.small" vAlign="center">
    {roles.map((role) =>
      role.toLowerCase() !== "none" ? <Label content={role} /> : null
    )}
  </Flex>
);

const header = {
  items: ["Title", "Status"],
};
const rowsPlain = [
  {
    key: 1,
    items: ["Roof Leak Fix", "Closed"],
  },
  {
    key: 2,
    items: ["Bug Treatment", "Open"],
  },
];

//TODO: hook real tasks
const ResidentTask = ({ details }: { details: UserDetails }) => (
  <Flex column>
    <Alert content="This is just demo data" warning dismissible />
    <Table header={header} rows={rowsPlain} aria-label="task tables for unit" />
  </Flex>
);
export const Resident = ResidentContainer;
